@import "@style-vars";

.uif-nav-layout {
    display: flex;
    max-width: 100vw;

    &__page-container {
        max-width: calc(100% - #{$navbar-width});
        width: 100%;
        margin: $nav-header-height 0 0 $navbar-width;
        padding: 0 20px 20px;

        &.collapse-menu {
            max-width: calc(100% - #{$navbar-collapsed-width});
            margin: $nav-header-height 0 0 $navbar-collapsed-width;
        }
    }
}

@media all and (max-width: 767.98px) {
    .uif-nav-layout {
        &__page-container {
            max-width: 100%;
            margin: $nav-header-height 0 0 0;
            transition: all 0.3s ease;

            &.collapse-menu {
                max-width: 100%;
                margin: $nav-header-height 0 0 0;
            }
        }
    }
}
