.uif-btn {
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }

    &:not(:disabled) {
        cursor: pointer;
    }
}


.uif-btn-white {
    background-color: #fff;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
        background-color: $gallery;
    }

    &:hover:disabled, &:hover.disabled {
        background-color: #fff;
    }

    &:active:not(:disabled) {
        background-color: $alto;
    }

    &:disabled, &.disabled {
        cursor: not-allowed !important;
        color: $alto;
    }
}

.uif-theme-btn {
    bottom: 10px;
    right: 10px;
    position: fixed;
    color: #fff !important;
    font-size: 24px !important;
    background-color: transparent !important;
}