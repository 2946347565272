@import "@style-vars";

.table-block-link {
    display: block;
    font-size: 14px;
    margin: 0 0 8px;
    line-height: 16px;
    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: underline;
    }
}