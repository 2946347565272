@import "@style-vars";

.radio-image-area {
    width: 100%;

    &__body {
        display: flex;
        width: 100%;
        min-height: 50px;
        flex-wrap: wrap;
        align-items: center;
        border: 1px solid $heather;
        border-radius: 2px;
    }

    &__error {
        color: $danger;
    }
}

.radio-image-area.error {
    .radio-image-area__body {
        border: 1px solid $danger;
    }
}
