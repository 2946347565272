$nav-header-height: 60px;
$navbar-width: 260px;
$navbar-collapsed-width: 80px;

$curios-blue: #1d89e6;
$danger: #ff4d4f;
$space: #2A363F;
$chateau: #a7a9aa;
$heather: #B5C1CD;
$heather-lite: rgba(181, 193, 205, 0.2);
$heather-lite-black: rgba(181, 193, 205, 0.6);
$white-ice: #f9fefb;
$gallery: #EBEBEB;
$alto: #D4D4D4;
$silver: #BFBFBF;
$grass: #00be0a;