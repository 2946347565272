@import "@style-vars";

.ant-table-cell a {
    color: #1890ff;
}

.uif-ant-form-controls {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
        margin-top: 10px;
        margin-left: 10px;
    }
}

.uif-ant-form-title {
    font-size: 14px;
    text-align: center;

    span {
        font-size: 16px;
        font-weight: bold;
    }
}

.ant-collapse-content-box {
    position: relative;
}

.uif-ant-form-item {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .ant-form-item-label {
        width: 100%;
        text-align: start;
    }

    .ant-form-item-control {
        width: 100%;
    }
}

.ant-form-item-has-error {
    .react-datepicker__input-container {
        input {
            border: 1px solid $danger !important;
        }
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        outline: none !important;
        border: none !important;

        input {
            padding: 4px 11px;
            outline: none !important;
            border: 1px solid $silver;
            &::placeholder {
                color: $silver;
            }
        }
    }
}
