@import "@style-vars";

.uif-course-page {
    &__collapses {
        width: 100%;
        margin: 50px 0 0;
    }

    &__wide-number {
        max-width: 300px;
        width: 100%;

        .ant-input-number {
            width: 100%;
        }
    }
}