@import "@style-vars";

.ant-menu-sub {
    background-color: $space !important;
}

.uif-nav-sidemenu {
    position: fixed;
    width: $navbar-width;
    height: calc(100vh - #{$nav-header-height});
    top: $nav-header-height;
    left: 0;
    background-color: $space;
    overflow: auto;
    direction: rtl;
    z-index: 50;


    &.collapse {
        width: $navbar-collapsed-width;
    }

    &__body {
        direction: ltr;
    }

    &__menu {
        max-width: $navbar-width !important;
        background-color: $space !important;
    }

    &__controls {
        display: flex;
        width: 100%;
        margin: 10px 0 10px;
        justify-content: flex-end;
    }

    &__menu-item-first {
        height: auto !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        color: $white-ice;
        font-size: 16px;

        .anticon {
            font-size: 18px !important;
        }
    }

    &__menu-submenu {

        .ant-menu-submenu-title {
            height: auto !important;
            padding-top: 6px !important;
            padding-bottom: 6px !important;
            color: $white-ice;
            font-size: 16px;

            .anticon {
                font-size: 18px !important;
            }
        }
    }

    &__burger-btn {
        display: none !important;
        height: auto !important;
        padding: 8px 12px !important;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0 0 4px !important;

        .anticon {
            font-size: 22px;
        }
    }
}

.uif-burger-menu-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 0vw;
    height: 100vh;
    background-color: $space;
    opacity: 0;
    z-index: 130;
    transition: opacity .7s ease;

    &.show {
        width: 0vw;
    }
}

@media all and (max-width: 767.98px) {
    .uif-nav-sidemenu {
        max-width: 90vw;
        height: calc(100vh);
        top: 0;
        left: 0%;
        z-index: 200;
        transition: all .3s ease;

        &.collapse {
            width: $navbar-width;
            left: -100%;
        }

        &__collapse-btn {
            display: none !important;
        }

        &__burger-btn {
            display: block !important;
        }
    }

    .uif-burger-menu-bg {
        &.show {
            opacity: .7;
            width: 100vw;
        }
    }
}