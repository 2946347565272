body.dark {
    background-color: #181a1b;
    border-color: #6a6a6a;
    color: #ffffff;

    background-color: rgb(13, 14, 15);
    border-color: rgb(98, 98, 98);
    color: rgb(241, 241, 241);
    
    a {
        color: #3492ff;
    }
    table {
        border-color: #555c5f;
    }
    ::placeholder {
        color: #b4b4b4;
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background-color: #565c00 !important;
        color: #ffffff !important;
    }
    ::-webkit-scrollbar {
        background-color: #202324;
        color: #ababab;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #464b4e;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #585f63;
    }
    ::-webkit-scrollbar-thumb:active {
        background-color: #494f52;
    }
    ::-webkit-scrollbar-corner {
        background-color: #181a1b;
    }
    ::selection {
        background-color: #004eac !important;
        color: #ffffff !important;
    }
    ::-moz-selection {
        background-color: #004eac !important;
        color: #ffffff !important;
    }
    
    /* Invert Style */
    .jfk-bubble.gtx-bubble, .captcheck_answer_label > input + img, span#closed_text > img[src^="https://www.gstatic.com/images/branding/googlelogo"], span[data-href^="https://www.hcaptcha.com/"] > #icon, #bit-notification-bar-iframe, embed[type="application/pdf"] {
        filter: invert(100%) hue-rotate(180deg) brightness(101%) contrast(90%) !important;
    }
    
    /* Variables Style */
    :root {
       --darkreader-neutral-background: #131516;
       --darkreader-neutral-text: #ffffff;
       --darkreader-selection-background: #004eac;
       --darkreader-selection-text: #ffffff;
    }
    
    /* Modified CSS */
    .anticon {
        color: inherit;
    }
    .uif-header {
        background-color: rgb(20, 108, 184);
        box-shadow: rgb(20, 108, 184) 0px 0px 5px 0px;
    }
    .uif-header__logo {
        color: rgb(252, 255, 254);
    }
    .uif-header__logo:hover {
        color: rgb(210, 210, 210);
    }
    .uif-header__controls {
        color: rgb(255, 255, 255) !important;
    }
    .uif-header__burger-btn {
        background-color: transparent;
        color: rgb(252, 255, 254);
    }
    .uif-header__burger-btn:active {
        color: rgb(201, 201, 201);
    }
    .uif-header__theme-btn {
        background-color: transparent;
    }
    .uif-object-table__body {
        border-color: rgb(67, 72, 75);
        background-color: rgb(24, 26, 27);
    }
    .uif-object-table__top {
        border-bottom-color: rgb(67, 72, 75);
    }
    .uif-object-table__count-value {
        border-color: rgb(67, 72, 75);
        color: rgb(210, 210, 210);
    }
    .uif-object-table__top-control,
    .uif-object-table__top-popover {
        border-left-color: rgb(67, 72, 75) !important;
    }
    .uif-object-table__top-popover.disabled {
        color: rgb(218, 218, 218) !important;
    }
    .uif-status-form {
        background-color: rgba(24, 26, 27, 0.8);
    }
    .uif-upload-img__input:focus + .uif-upload-img__upload-btn {
        border-color: rgb(18, 95, 162);
        color: rgb(53, 156, 235);
    }
    .uif-upload-img__load-area {
        border-color: transparent;
    }
    .uif-upload-img__upload-btn {
        border-color: rgb(67, 72, 75);
        color: rgb(255, 255, 255);
    }
    .uif-upload-img__error {
        color: rgb(255, 96, 98);
    }
    .uif-upload-img.active .uif-upload-img__load-area {
        border-color: rgb(113, 113, 113);
    }
    .uif-table-image__img {
        background-color: rgb(48, 53, 55);
    }
    .ant-menu-sub {
        background-color: rgb(34, 43, 51) !important;
    }
    .uif-nav-sidemenu {
        background-color: rgb(34, 43, 51);
    }
    .uif-nav-sidemenu__menu {
        background-color: rgb(34, 43, 51) !important;
    }
    .uif-nav-sidemenu__menu-item-first {
        color: rgb(252, 255, 254);
    }
    .uif-nav-sidemenu__menu-submenu .ant-menu-submenu-title {
        color: rgb(252, 255, 254);
    }
    .uif-burger-menu-bg {
        background-color: rgb(34, 43, 51);
    }
    .uif-account-page__table {
        background-color: rgba(52, 64, 76, 0.2);
    }
    .uif-account-page__table-item-title {
        color: rgb(210, 210, 210);
    }
    .uif-account-page__table-item-row {
        border-bottom-color: rgb(73, 79, 82);
    }
    .uif-account-page__table-item-param {
        color: rgb(179, 179, 179);
    }
    .uif-auth-page {
        background-image: linear-gradient(90deg,
        rgb(79, 28, 160) 0%,
        rgb(10, 59, 167) 100%);
        background-color: initial;
    }
    .uif-auth-page__form {
        background-color: rgb(8, 47, 30);
    }
    .uif-auth-page__title {
        color: rgb(255, 255, 255);
    }
    .uif-auth-page__submit-btn {
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
    }
    .uif-auth-page__submit-btn:active {
        background-color: rgb(36, 39, 40);
    }
    .uif-auth-page__form-error {
        color: rgb(255, 96, 98);
    }
    a {
        background-color: transparent;
        text-decoration-color: initial;
        color: rgb(255, 255, 255);
    }
    abbr[title] {
        border-bottom-color: initial;
        text-decoration-color: initial;
    }
    legend {
        color: inherit;
    }
    .uif-btn {
        border-color: initial;
        outline-color: initial;
    }
    .uif-btn:focus {
        outline-color: initial;
    }
    .uif-btn-white {
        background-color: rgb(24, 26, 27);
    }
    .uif-btn-white:hover {
        background-color: rgb(35, 38, 40);
    }
    .uif-btn-white:hover:disabled,
    .uif-btn-white:hover.disabled {
        background-color: rgb(24, 26, 27);
    }
    .uif-btn-white:active:not(:disabled) {
        background-color: rgb(48, 53, 55);
    }
    .uif-btn-white:disabled,
    .uif-btn-white.disabled {
        color: rgb(218, 218, 218);
    }
    .uif-theme-btn {
        color: rgb(255, 255, 255) !important;
        background-color: transparent !important;
    }
    .ant-form-item-has-error .react-datepicker__input-container input {
        border-color: rgb(157, 0, 2) !important;
    }
    .row-dragging {
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: rgb(63, 69, 71);
    }
    .react-datepicker-wrapper .react-datepicker__input-container {
        outline-color: initial !important;
        border-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input {
        border-color: rgb(67, 73, 75);
        outline-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
        color: rgb(199, 199, 199);
    }
    .uif-ant-pulse-icon {
        color: rgb(85, 255, 94) !important;
    }
    .ant-switch-handle::before {
        background-color: rgb(41, 37, 31) !important;
    }
    .ant-switch {
        background-color: rgba(41, 37, 31, 0.3) !important;
    }
    .ant-switch.ant-switch-checked {
        background-color: rgb(0, 86, 179) !important;
    }
    input,
    textarea,
    select,
    button {
        background-color: rgb(13, 14, 15);
    }
    input,
    textarea,
    select,
    button {
        border-color: rgb(98, 98, 98);
        color: rgb(241, 241, 241);
    }
    a {
        color: rgb(67, 171, 255);
    }
    table {
        border-color: rgb(103, 103, 103);
    }
    ::placeholder {
        color: rgb(183, 183, 183);
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background-color: rgb(67, 71, 0) !important;
        color: rgb(241, 241, 241) !important;
    }
    ::-webkit-scrollbar {
        background-color: rgb(20, 22, 22);
        color: rgb(176, 176, 176);
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgb(53, 57, 59);
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(69, 74, 77);
    }
    ::-webkit-scrollbar-thumb:active {
        background-color: rgb(56, 60, 62);
    }
    ::-webkit-scrollbar-corner {
        background-color: rgb(13, 14, 15);
    }
    ::selection {
        background-color: rgb(0, 59, 139) !important;
        color: rgb(241, 241, 241) !important;
    }
    :root {
        --darkreader-neutral-background: #0a0c0e;
        --darkreader-text--darkreader-neutral-text: #e0e0e0;
        --darkreader-selection-background: #0049ad;
        --darkreader-selection-text: #f0eeeb;
    }
    .anticon {
        color: inherit;
    }
    .uif-header {
        background-color: rgb(9, 85, 150);
        box-shadow: rgb(9, 85, 150) 0px 0px 5px 0px;
    }
    .uif-header__logo {
        color: rgb(219, 255, 235);
    }
    .uif-header__logo:hover {
        color: rgb(206, 206, 206);
    }
    .uif-header__burger-btn {
        background-color: transparent;
        color: rgb(219, 255, 235);
    }
    .uif-header__burger-btn:active {
        color: rgb(199, 199, 199);
    }
    .uif-header__theme-btn {
        background-color: transparent;
        color: rgb(241, 241, 241) !important;
    }
    .ant-menu-sub {
        background-color: rgb(22, 29, 35) !important;
    }
    .uif-nav-sidemenu {
        background-color: rgb(22, 29, 35);
    }
    .uif-nav-sidemenu__menu {
        background-color: rgb(22, 29, 35) !important;
    }
    .uif-nav-sidemenu__menu-item-first {
        color: rgb(219, 255, 235);
    }
    .uif-nav-sidemenu__menu-submenu .ant-menu-submenu-title {
        color: rgb(219, 255, 235);
    }
    .uif-burger-menu-bg {
        background-color: rgb(22, 29, 35);
    }
    .uif-object-table__body {
        border-color: rgb(109, 109, 109);
        background-color: rgb(13, 14, 15);
    }
    .uif-object-table__top {
        border-bottom-color: rgb(109, 109, 109);
    }
    .uif-object-table__count-value {
        border-color: rgb(109, 109, 109);
        color: rgb(206, 206, 206);
    }
    .uif-object-table__top-control,
    .uif-object-table__top-popover {
        border-left-color: rgb(109, 109, 109) !important;
    }
    .uif-object-table__top-popover.disabled {
        color: rgb(212, 212, 212) !important;
    }
    .uif-status-form {
        background-color: rgba(13, 14, 15, 0.8);
    }
    .uif-upload-img__input:focus + .uif-upload-img__upload-btn {
        border-color: rgb(11, 109, 195);
        color: rgb(67, 169, 244);
    }
    .uif-upload-img__load-area {
        border-color: transparent;
    }
    .uif-upload-img__upload-btn {
        border-color: rgb(109, 109, 109);
        color: rgb(241, 241, 241);
    }
    .uif-upload-img__error {
        color: rgb(255, 94, 96);
    }
    .uif-upload-img.active .uif-upload-img__load-area {
        border-color: rgb(95, 95, 95);
    }
    .uif-table-image__img {
        background-color: rgb(34, 37, 38);
    }
    a {
        background-color: transparent;
        text-decoration-color: initial;
        color: rgb(241, 241, 241);
    }
    abbr[title] {
        border-bottom-color: initial;
        text-decoration-color: initial;
    }
    legend {
        color: inherit;
    }
    .uif-btn {
        border-color: initial;
        outline-color: initial;
    }
    .uif-btn:focus {
        outline-color: initial;
    }
    .uif-btn-white {
        background-color: rgb(13, 14, 15);
    }
    .uif-btn-white:hover {
        background-color: rgb(23, 25, 26);
    }
    .uif-btn-white:hover:disabled,
    .uif-btn-white:hover.disabled {
        background-color: rgb(13, 14, 15);
    }
    .uif-btn-white:active:not(:disabled) {
        background-color: rgb(34, 37, 38);
    }
    .uif-btn-white:disabled,
    .uif-btn-white.disabled {
        color: rgb(212, 212, 212);
    }
    .ant-form-item-has-error .react-datepicker__input-container input {
        border-color: rgb(210, 0, 0) !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container {
        outline-color: initial !important;
        border-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input {
        border-color: rgb(109, 109, 109);
        outline-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
        color: rgb(198, 198, 198);
    }
    input,
    textarea,
    select,
    button {
        background-color: rgb(8, 10, 11);
    }
    input,
    textarea,
    select,
    button {
        border-color: rgb(100, 100, 100);
        color: rgb(224, 224, 224);
    }
    a {
        color: rgb(77, 181, 255);
    }
    table {
        border-color: rgb(99, 99, 99);
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background-color: rgb(51, 56, 0) !important;
        color: rgb(224, 224, 224) !important;
    }
    ::-webkit-scrollbar {
        background-color: rgb(15, 16, 17);
        color: rgb(178, 178, 178);
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgb(40, 43, 45);
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(53, 58, 60);
    }
    ::-webkit-scrollbar-thumb:active {
        background-color: rgb(42, 46, 47);
    }
    ::-webkit-scrollbar-corner {
        background-color: rgb(8, 10, 11);
    }
    ::selection {
        background-color: rgb(0, 46, 111) !important;
        color: rgb(224, 224, 224) !important;
    }
    :root {
        --darkreader-neutral-background: #131516;
        --darkreader-text--darkreader-text--darkreader-neutral-text: #d4d4d4;
        --darkreader-selection-background: #004daa;
        --darkreader-selection-text: #e8e6e3;
    }
    .anticon {
        color: inherit;
    }
    .uif-header {
        background-color: rgb(6, 67, 119);
        box-shadow: rgb(6, 67, 119) 0px 0px 5px 0px;
    }
    .uif-header__logo {
        color: rgb(191, 254, 216);
    }
    .uif-header__logo:hover {
        color: rgb(199, 199, 199);
    }
    .uif-header__burger-btn {
        background-color: transparent;
        color: rgb(191, 254, 216);
    }
    .uif-header__burger-btn:active {
        color: rgb(194, 194, 194);
    }
    .ant-menu-sub {
        background-color: rgb(15, 22, 26) !important;
    }
    .uif-nav-sidemenu {
        background-color: rgb(15, 22, 26);
    }
    .uif-nav-sidemenu__menu {
        background-color: rgb(15, 22, 26) !important;
    }
    .uif-nav-sidemenu__menu-item-first {
        color: rgb(191, 254, 216);
    }
    .uif-nav-sidemenu__menu-submenu .ant-menu-submenu-title {
        color: rgb(191, 254, 216);
    }
    .uif-burger-menu-bg {
        background-color: rgb(15, 22, 26);
    }
    .uif-object-table__body {
        border-color: rgb(97, 97, 97);
        background-color: rgb(8, 10, 11);
    }
    .uif-object-table__top {
        border-bottom-color: rgb(97, 97, 97);
    }
    .uif-object-table__count-value {
        border-color: rgb(97, 97, 97);
        color: rgb(199, 199, 199);
    }
    .uif-object-table__top-control,
    .uif-object-table__top-popover {
        border-left-color: rgb(97, 97, 97) !important;
    }
    .uif-object-table__top-popover.disabled {
        color: rgb(204, 204, 204) !important;
    }
    .uif-status-form {
        background-color: rgba(8, 10, 11, 0.8);
    }
    .uif-upload-img__input:focus + .uif-upload-img__upload-btn {
        border-color: rgb(11, 105, 187);
        color: rgb(81, 178, 246);
    }
    .uif-upload-img__load-area {
        border-color: transparent;
    }
    .uif-upload-img__upload-btn {
        border-color: rgb(97, 97, 97);
        color: rgb(224, 224, 224);
    }
    .uif-upload-img__error {
        color: rgb(255, 96, 98);
    }
    .uif-upload-img.active .uif-upload-img__load-area {
        border-color: rgb(101, 101, 101);
    }
    .uif-table-image__img {
        background-color: rgb(25, 27, 28);
    }
    a {
        background-color: transparent;
        text-decoration-color: initial;
        color: rgb(224, 224, 224);
    }
    abbr[title] {
        border-bottom-color: initial;
        text-decoration-color: initial;
    }
    legend {
        color: inherit;
    }
    .uif-btn {
        border-color: initial;
        outline-color: initial;
    }
    .uif-btn:focus {
        outline-color: initial;
    }
    .uif-btn-white {
        background-color: rgb(8, 10, 11);
    }
    .uif-btn-white:hover {
        background-color: rgb(17, 18, 19);
    }
    .uif-btn-white:hover:disabled,
    .uif-btn-white:hover.disabled {
        background-color: rgb(8, 10, 11);
    }
    .uif-btn-white:active:not(:disabled) {
        background-color: rgb(25, 27, 28);
    }
    .uif-btn-white:disabled,
    .uif-btn-white.disabled {
        color: rgb(204, 204, 204);
    }
    .ant-form-item-has-error .react-datepicker__input-container input {
        border-color: rgb(192, 0, 0) !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container {
        outline-color: initial !important;
        border-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input {
        border-color: rgb(97, 97, 97);
        outline-color: initial !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
        color: rgb(194, 194, 194); 
    }
    .clear-focus:focus {
        outline-color: initial !important; box-shadow: none !important; 
    }
    [tabindex="-1"]:focus {
        outline-color: initial !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: rgba(224, 224, 224, 0.85);
    }
    abbr[title],
    abbr[data-original-title] {
        text-decoration-color: initial;
        border-bottom-color: initial;
    }
    a {
        color: rgb(71, 183, 255);
        text-decoration-color: initial;
        background-color: transparent;
        outline-color: initial;
    }
    a:hover {
        color: rgb(90, 192, 255);
    }
    a:active {
        color: rgb(96, 190, 255);
    }
    a:active,
    a:hover {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a:focus {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a[disabled] {
        color: rgba(224, 224, 224, 0.25);
    }
    caption {
        color: rgba(224, 224, 224, 0.45);
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        color: inherit;
    }
    fieldset {
        border-color: initial;
    }
    legend {
        color: inherit;
    }
    mark {
        background-color: rgb(36, 38, 0);
    }
    ::selection {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124); 
    }
    .anticon {
        color: inherit; 
    }
    html {
        --darkreader-bg--darkreader-bg--darkreader-bg--antd-wave-shadow-color: #003c7c; --scroll-bar: 0; 
    }
    [ant-click-animating-without-extra-node="true"]::after,
    .ant-click-animating-node {
        box-shadow: 0 0 0 0 var(--darkreader-bg--darkreader-bg--darkreader-bg--antd-wave-shadow-color);
    }
    .ant-alert {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-alert-success {
        background-color: rgb(18, 35, 0);
        border-color: rgb(88, 172, 22);
    }
    .ant-alert-success .ant-alert-icon {
        color: rgb(145, 244, 98);
    }
    .ant-alert-info {
        background-color: rgb(0, 23, 38);
        border-color: rgb(0, 113, 190);
    }
    .ant-alert-info .ant-alert-icon {
        color: rgb(71, 183, 255);
    }
    .ant-alert-warning {
        background-color: rgb(38, 30, 0);
        border-color: rgb(191, 144, 0);
    }
    .ant-alert-warning .ant-alert-icon {
        color: rgb(255, 199, 71);
    }
    .ant-alert-error {
        background-color: rgb(34, 0, 0);
        border-color: rgb(189, 9, 0);
    }
    .ant-alert-error .ant-alert-icon {
        color: rgb(255, 96, 98);
    }
    .ant-alert-close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-alert-close-icon .anticon-close {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-alert-close-icon .anticon-close:hover {
        color: rgba(224, 224, 224, 0.75);
    }
    .ant-alert-close-text {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-alert-close-text:hover {
        color: rgba(224, 224, 224, 0.75);
    }
    .ant-alert-with-description .ant-alert-message {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-alert-message {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-alert-banner {
        border-color: initial;
    }
    .ant-anchor {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-anchor-wrapper {
        background-color: rgb(8, 10, 11);
    }
    .ant-anchor-ink::before {
        background-color: rgb(15, 16, 17);
    }
    .ant-anchor-ink-ball {
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 96, 194);
    }
    .ant-anchor-link-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
        color: rgb(71, 183, 255);
    }
    .ant-select-auto-complete {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: rgb(194, 194, 194);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select-multiple .ant-select-selection-item {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: rgb(194, 194, 194);
        border-color: rgb(96, 96, 96);
    }
    .ant-select-multiple .ant-select-selection-item-remove {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-select-multiple .ant-select-selection-item-remove:hover {
        color: rgba(224, 224, 224, 0.75);
    }
    .ant-select {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: rgb(0, 103, 193);
    }
    .ant-select-selection-placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-select-arrow {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-clear {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-select-clear:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-select-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-select-dropdown-empty {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-item-empty {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-item {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-select-item-group {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgb(13, 14, 15);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(0, 23, 38);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
        color: rgb(71, 183, 255);
    }
    .ant-select-item-option-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-borderless .ant-select-selector {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-empty-normal {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-empty-small {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-empty-img-default-ellipse {
        fill: rgb(219, 219, 219);
    }
    .ant-empty-img-default-path-1 {
        fill: rgb(190, 190, 190);
    }
    .ant-empty-img-default-path-3 {
        fill: rgb(220, 220, 220);
    }
    .ant-empty-img-default-path-4 {
        fill: rgb(210, 210, 210);
    }
    .ant-empty-img-default-path-5 {
        fill: rgb(210, 210, 210);
    }
    .ant-empty-img-default-g {
        fill: rgb(224, 224, 224);
    }
    .ant-empty-img-simple-ellipse {
        fill: rgb(219, 219, 219);
    }
    .ant-empty-img-simple-g {
        stroke: rgb(206, 206, 206);
    }
    .ant-empty-img-simple-path {
        fill: rgb(222, 222, 222);
    }
    .ant-avatar {
        list-style-image: initial;
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(29, 31, 32);
    }
    .ant-avatar-image {
        background-image: initial;
        background-color: transparent;
    }
    .ant-avatar-group .ant-avatar {
        border-color: rgb(95, 95, 95);
    }
    .ant-popover {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-popover::after {
        background-image: initial;
        background-color: rgba(8, 10, 11, 0.01);
    }
    .ant-popover-inner {
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    @media not all,
    not all {
        .ant-popover-inner {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
            rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
            rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
        }
        .ant-popover-inner {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
            rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
            rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
        }
    }
    .ant-popover-title {
        color: rgba(224, 224, 224, 0.85);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-popover-inner-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-popover-message {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-popover-message > .anticon {
        color: rgb(255, 199, 71);
    }
    .ant-popover-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent rgb(95, 95, 95) rgb(95, 95, 95) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent transparent rgb(95, 95, 95) rgb(95, 95, 95);
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(95, 95, 95) transparent transparent rgb(95, 95, 95);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(95, 95, 95) rgb(95, 95, 95) transparent transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-back-top {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-back-top-content {
        color: rgb(224, 224, 224);
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-back-top-content:hover {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-badge {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-badge-count {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(102, 0, 0);
        box-shadow: rgb(8, 10, 11) 0px 0px 0px 1px;
    }
    .ant-badge-count a,
    .ant-badge-count a:hover {
        color: rgb(224, 224, 224);
    }
    .ant-badge-dot {
        background-image: initial;
        background-color: rgb(102, 0, 0);
        box-shadow: rgb(8, 10, 11) 0px 0px 0px 1px;
    }
    .ant-badge-status-success {
        background-color: rgb(38, 102, 6);
    }
    .ant-badge-status-processing {
        background-color: rgb(0, 60, 124);
    }
    .ant-badge-status-processing::after {
        border-color: rgb(0, 96, 194);
    }
    .ant-badge-status-default {
        background-color: rgb(24, 26, 27);
    }
    .ant-badge-status-error {
        background-color: rgb(102, 0, 0);
    }
    .ant-badge-status-warning {
        background-color: rgb(125, 81, 0);
    }
    .ant-badge-status-pink {
        background-image: initial;
        background-color: rgb(111, 3, 63);
    }
    .ant-badge-status-magenta {
        background-image: initial;
        background-color: rgb(111, 3, 63);
    }
    .ant-badge-status-red {
        background-image: initial;
        background-color: rgb(118, 0, 4);
    }
    .ant-badge-status-volcano {
        background-image: initial;
        background-color: rgb(122, 26, 0);
    }
    .ant-badge-status-orange {
        background-image: initial;
        background-color: rgb(124, 62, 0);
    }
    .ant-badge-status-yellow {
        background-image: initial;
        background-color: rgb(125, 107, 0);
    }
    .ant-badge-status-gold {
        background-image: initial;
        background-color: rgb(125, 81, 0);
    }
    .ant-badge-status-cyan {
        background-image: initial;
        background-color: rgb(2, 100, 100);
    }
    .ant-badge-status-lime {
        background-image: initial;
        background-color: rgb(81, 113, 2);
    }
    .ant-badge-status-green {
        background-image: initial;
        background-color: rgb(38, 102, 6);
    }
    .ant-badge-status-blue {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-badge-status-geekblue {
        background-image: initial;
        background-color: rgb(3, 25, 111);
    }
    .ant-badge-status-purple {
        background-image: initial;
        background-color: rgb(56, 18, 109);
    }
    .ant-badge-status-text {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-ribbon {
        list-style-image: initial;
        color: rgb(224, 224, 224);
        background-color: rgb(0, 60, 124);
    }
    .ant-ribbon-text {
        color: rgb(224, 224, 224);
    }
    .ant-ribbon-corner {
        color: currentcolor;
        border-color: initial;
    }
    .ant-ribbon-corner::after {
        color: rgba(224, 224, 224, 0.25);
        border-color: inherit;
    }
    .ant-ribbon-color-pink {
        color: rgb(250, 90, 178);
        background-image: initial;
        background-color: rgb(111, 3, 63);
    }
    .ant-ribbon-color-magenta {
        color: rgb(250, 90, 178);
        background-image: initial;
        background-color: rgb(111, 3, 63);
    }
    .ant-ribbon-color-red {
        color: rgb(255, 80, 89);
        background-image: initial;
        background-color: rgb(118, 0, 4);
    }
    .ant-ribbon-color-volcano {
        color: rgb(255, 122, 75);
        background-image: initial;
        background-color: rgb(122, 26, 0);
    }
    .ant-ribbon-color-orange {
        color: rgb(255, 171, 72);
        background-image: initial;
        background-color: rgb(124, 62, 0);
    }
    .ant-ribbon-color-yellow {
        color: rgb(255, 236, 71);
        background-image: initial;
        background-color: rgb(125, 107, 0);
    }
    .ant-ribbon-color-gold {
        color: rgb(255, 199, 71);
        background-image: initial;
        background-color: rgb(125, 81, 0);
    }
    .ant-ribbon-color-cyan {
        color: rgb(96, 250, 250);
        background-image: initial;
        background-color: rgb(2, 100, 100);
    }
    .ant-ribbon-color-lime {
        color: rgb(204, 252, 83);
        background-image: initial;
        background-color: rgb(81, 113, 2);
    }
    .ant-ribbon-color-green {
        color: rgb(145, 244, 98);
        background-image: initial;
        background-color: rgb(38, 102, 6);
    }
    .ant-ribbon-color-blue {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-ribbon-color-geekblue {
        color: rgb(90, 180, 250);
        background-image: initial;
        background-color: rgb(3, 25, 111);
    }
    .ant-ribbon-color-purple {
        color: rgb(153, 97, 229);
        background-image: initial;
        background-color: rgb(56, 18, 109);
    }
    .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-breadcrumb {
        list-style-image: initial;
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-breadcrumb a {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-breadcrumb a:hover {
        color: rgb(90, 192, 255);
    }
    .ant-breadcrumb > span:last-child {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-breadcrumb > span:last-child a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-breadcrumb-separator {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-menu-item-danger.ant-menu-item {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-item-danger.ant-menu-item-active {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item:active {
        background-image: initial;
        background-color: rgb(34, 0, 0);
    }
    .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item-selected > a,
    .ant-menu-item-danger.ant-menu-item-selected > a:hover {
        color: rgb(255, 96, 98);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        background-color: rgb(34, 0, 0);
    }
    .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
        border-right-color: rgb(192, 0, 0);
    }
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
        color: rgb(255, 96, 98);
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(224, 224, 224);
        background-color: rgb(102, 0, 0);
    }
    .ant-menu {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-menu ul,
    .ant-menu ol {
        list-style-image: initial;
    }
    .ant-menu-item-group-title {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-menu-submenu-selected {
        color: rgb(71, 183, 255);
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-menu-item a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-menu-item a:hover {
        color: rgb(71, 183, 255);
    }
    .ant-menu-item a::before {
        background-color: transparent;
    }
    .ant-menu-item > .ant-badge a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-menu-item > .ant-badge a:hover {
        color: rgb(71, 183, 255);
    }
    .ant-menu-item-divider {
        background-color: rgb(15, 16, 17);
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: rgb(71, 183, 255);
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
        background-color: transparent;
    }
    .ant-menu-item-selected {
        color: rgb(71, 183, 255);
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        color: rgb(71, 183, 255);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 23, 38);
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-menu-vertical-right {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-menu-vertical.ant-menu-sub,
    .ant-menu-vertical-left.ant-menu-sub,
    .ant-menu-vertical-right.ant-menu-sub {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu > .ant-menu-item-divider {
        background-color: rgb(15, 16, 17);
    }
    .ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-menu-submenu > .ant-menu {
        background-color: rgb(8, 10, 11);
    }
    .ant-menu-submenu-popup > .ant-menu {
        background-color: rgb(8, 10, 11);
    }
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-menu-submenu-expand-icon::after,
    .ant-menu-submenu-expand-icon::before,
    .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-arrow::before {
        background-color: rgba(55, 49, 41, 0.85) !important;
    }
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
        background-color: currentcolor;
    }
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: rgb(71, 183, 255);
    }
    .ant-menu-vertical .ant-menu-submenu-selected,
    .ant-menu-vertical-left .ant-menu-submenu-selected,
    .ant-menu-vertical-right .ant-menu-submenu-selected {
        color: rgb(71, 183, 255);
    }
    .ant-menu-horizontal {
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(96, 96, 96);
        box-shadow: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: rgb(71, 183, 255);
        border-bottom-color: rgb(0, 96, 194);
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border-bottom-color: transparent;
    }
    .ant-menu-horizontal > .ant-menu-item a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-menu-horizontal > .ant-menu-item a:hover {
        color: rgb(71, 183, 255);
    }
    .ant-menu-horizontal > .ant-menu-item-selected a {
        color: rgb(71, 183, 255);
    }
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right-color: rgb(0, 96, 194);
    }
    .ant-menu-inline-collapsed-tooltip a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
        box-shadow: none;
    }
    .ant-menu-sub.ant-menu-inline {
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-color: initial;
        box-shadow: none;
    }
    .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
        background-image: none;
        background-color: initial;
        color: rgba(224, 224, 224, 0.25) !important;
        border-color: transparent !important;
    }
    .ant-menu-item-disabled a,
    .ant-menu-submenu-disabled a {
        color: rgba(224, 224, 224, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(224, 224, 224, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: rgba(224, 224, 224, 0.65);
        background-image: initial;
        background-color: rgb(0, 4, 14);
    }
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-menu-dark.ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background-image: initial;
        background-color: rgb(0, 0, 4);
    }
    .ant-menu-dark.ant-menu-horizontal {
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
        border-top-color: rgb(102, 102, 102);
        border-right-color: rgb(102, 102, 102);
        border-left-color: rgb(102, 102, 102);
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: rgb(0, 60, 124);
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a {
        color: rgba(224, 224, 224, 0.65);
    }
    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
        color: rgb(224, 224, 224);
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-item:hover > a,
    .ant-menu-dark .ant-menu-item-active > a,
    .ant-menu-dark .ant-menu-submenu-active > a,
    .ant-menu-dark .ant-menu-submenu-open > a,
    .ant-menu-dark .ant-menu-submenu-selected > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > a,
    .ant-menu-dark .ant-menu-item:hover > span > a,
    .ant-menu-dark .ant-menu-item-active > span > a,
    .ant-menu-dark .ant-menu-submenu-active > span > a,
    .ant-menu-dark .ant-menu-submenu-open > span > a,
    .ant-menu-dark .ant-menu-submenu-selected > span > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
        color: rgb(224, 224, 224);
    }
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-menu-dark .ant-menu-item:hover {
        background-color: transparent;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 60, 124);
    }
    .ant-menu-dark .ant-menu-item-selected {
        color: rgb(224, 224, 224);
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item-selected > span > a,
    .ant-menu-dark .ant-menu-item-selected > a:hover,
    .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: rgb(224, 224, 224);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
    .ant-menu-dark .ant-menu-item-selected .anticon {
        color: rgb(224, 224, 224);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
    .ant-menu-dark .ant-menu-item-selected .anticon + span {
        color: rgb(224, 224, 224);
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: rgb(0, 60, 124);
    }
    .ant-menu-dark .ant-menu-item-disabled,
    .ant-menu-dark .ant-menu-submenu-disabled,
    .ant-menu-dark .ant-menu-item-disabled > a,
    .ant-menu-dark .ant-menu-submenu-disabled > a,
    .ant-menu-dark .ant-menu-item-disabled > span > a,
    .ant-menu-dark .ant-menu-submenu-disabled > span > a {
        color: rgba(224, 224, 224, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(224, 224, 224, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(8, 10, 11, 0.35) !important;
    }
    .ant-menu-rtl.ant-menu-inline,
    .ant-menu-rtl.ant-menu-vertical {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
    .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
        border-left-color: initial;
    }
    .ant-menu-sub.ant-menu-inline {
        border-color: initial;
    }
    .ant-tooltip {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-tooltip-inner {
        color: rgb(224, 224, 224);
        text-decoration-color: initial;
        background-color: rgba(0, 0, 0, 0.75);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tooltip-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tooltip-arrow-content {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .ant-tooltip-placement-top .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-tooltip-placement-right .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-tooltip-placement-left .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px -3px 7px;
    }
    .ant-tooltip-pink .ant-tooltip-inner {
        background-color: rgb(111, 3, 63);
    }
    .ant-tooltip-pink .ant-tooltip-arrow-content {
        background-color: rgb(111, 3, 63);
    }
    .ant-tooltip-magenta .ant-tooltip-inner {
        background-color: rgb(111, 3, 63);
    }
    .ant-tooltip-magenta .ant-tooltip-arrow-content {
        background-color: rgb(111, 3, 63);
    }
    .ant-tooltip-red .ant-tooltip-inner {
        background-color: rgb(118, 0, 4);
    }
    .ant-tooltip-red .ant-tooltip-arrow-content {
        background-color: rgb(118, 0, 4);
    }
    .ant-tooltip-volcano .ant-tooltip-inner {
        background-color: rgb(122, 26, 0);
    }
    .ant-tooltip-volcano .ant-tooltip-arrow-content {
        background-color: rgb(122, 26, 0);
    }
    .ant-tooltip-orange .ant-tooltip-inner {
        background-color: rgb(124, 62, 0);
    }
    .ant-tooltip-orange .ant-tooltip-arrow-content {
        background-color: rgb(124, 62, 0);
    }
    .ant-tooltip-yellow .ant-tooltip-inner {
        background-color: rgb(125, 107, 0);
    }
    .ant-tooltip-yellow .ant-tooltip-arrow-content {
        background-color: rgb(125, 107, 0);
    }
    .ant-tooltip-gold .ant-tooltip-inner {
        background-color: rgb(125, 81, 0);
    }
    .ant-tooltip-gold .ant-tooltip-arrow-content {
        background-color: rgb(125, 81, 0);
    }
    .ant-tooltip-cyan .ant-tooltip-inner {
        background-color: rgb(2, 100, 100);
    }
    .ant-tooltip-cyan .ant-tooltip-arrow-content {
        background-color: rgb(2, 100, 100);
    }
    .ant-tooltip-lime .ant-tooltip-inner {
        background-color: rgb(81, 113, 2);
    }
    .ant-tooltip-lime .ant-tooltip-arrow-content {
        background-color: rgb(81, 113, 2);
    }
    .ant-tooltip-green .ant-tooltip-inner {
        background-color: rgb(38, 102, 6);
    }
    .ant-tooltip-green .ant-tooltip-arrow-content {
        background-color: rgb(38, 102, 6);
    }
    .ant-tooltip-blue .ant-tooltip-inner {
        background-color: rgb(0, 60, 124);
    }
    .ant-tooltip-blue .ant-tooltip-arrow-content {
        background-color: rgb(0, 60, 124);
    }
    .ant-tooltip-geekblue .ant-tooltip-inner {
        background-color: rgb(3, 25, 111);
    }
    .ant-tooltip-geekblue .ant-tooltip-arrow-content {
        background-color: rgb(3, 25, 111);
    }
    .ant-tooltip-purple .ant-tooltip-inner {
        background-color: rgb(56, 18, 109);
    }
    .ant-tooltip-purple .ant-tooltip-arrow-content {
        background-color: rgb(56, 18, 109);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
        color: rgb(255, 96, 98);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
        color: rgb(224, 224, 224);
        background-color: rgb(102, 0, 0);
    }
    .ant-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-dropdown-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
        border-color: transparent rgb(95, 95, 95) rgb(95, 95, 95) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
        border-color: rgb(95, 95, 95) transparent transparent rgb(95, 95, 95);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-dropdown-menu {
        background-color: rgb(8, 10, 11);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-dropdown-menu-item-group-title {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-dropdown-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-dropdown-menu-submenu-popup ul,
    .ant-dropdown-menu-submenu-popup li {
        list-style-image: initial;
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-submenu-title > a:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a,
    .ant-dropdown-menu-submenu-title > .anticon + span > a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a:hover,
    .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
        color: rgb(71, 183, 255);
        background-color: rgb(0, 23, 38);
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: rgb(13, 14, 15);
    }
    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-dropdown-menu-item-disabled:hover,
    .ant-dropdown-menu-submenu-title-disabled:hover {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(8, 10, 11);
    }
    .ant-dropdown-menu-item-disabled > .anticon + span > a,
    .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
    .ant-dropdown-menu-item-disabled > a,
    .ant-dropdown-menu-submenu-title-disabled > a {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-dropdown-menu-item-divider,
    .ant-dropdown-menu-submenu-title-divider {
        background-color: rgb(15, 16, 17);
    }
    .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
    .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-dropdown-menu-item-group-list {
        list-style-image: initial;
    }
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(8, 10, 11);
    }
    .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: rgb(71, 183, 255);
    }
    .ant-dropdown-menu-dark,
    .ant-dropdown-menu-dark .ant-dropdown-menu {
        background-image: initial;
        background-color: rgb(0, 4, 14);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
        color: rgba(224, 224, 224, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
        color: rgba(224, 224, 224, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-btn {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-btn,
    .ant-btn:active,
    .ant-btn:focus {
        outline-color: initial;
    }
    .ant-btn:not([disabled]):hover {
        text-decoration-color: initial;
    }
    .ant-btn:not([disabled]):active {
        outline-color: initial;
        box-shadow: none;
    }
    .ant-btn > a:only-child {
        color: currentcolor;
    }
    .ant-btn > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 103, 193);
    }
    .ant-btn:hover > a:only-child,
    .ant-btn:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn:hover > a:only-child::after,
    .ant-btn:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 94, 198);
    }
    .ant-btn:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn[disabled] > a:only-child,
    .ant-btn[disabled]:hover > a:only-child,
    .ant-btn[disabled]:focus > a:only-child,
    .ant-btn[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn[disabled] > a:only-child::after,
    .ant-btn[disabled]:hover > a:only-child::after,
    .ant-btn[disabled]:focus > a:only-child::after,
    .ant-btn[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        text-decoration-color: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-btn-primary {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 56, 107);
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-primary:hover > a:only-child,
    .ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:hover > a:only-child::after,
    .ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:active {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 53, 113);
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-primary[disabled] > a:only-child,
    .ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
        border-right-color: rgb(0, 103, 193);
        border-left-color: rgb(0, 103, 193);
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
        border-color: rgb(96, 96, 96);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
        border-right-color: rgb(0, 103, 193);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: rgb(0, 103, 193);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-btn-ghost {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(96, 96, 96);
    }
    .ant-btn-ghost > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:hover,
    .ant-btn-ghost:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-ghost:hover > a:only-child,
    .ant-btn-ghost:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:hover > a:only-child::after,
    .ant-btn-ghost:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-ghost:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost[disabled],
    .ant-btn-ghost[disabled]:hover,
    .ant-btn-ghost[disabled]:focus,
    .ant-btn-ghost[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-ghost[disabled] > a:only-child,
    .ant-btn-ghost[disabled]:hover > a:only-child,
    .ant-btn-ghost[disabled]:focus > a:only-child,
    .ant-btn-ghost[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost[disabled] > a:only-child::after,
    .ant-btn-ghost[disabled]:hover > a:only-child::after,
    .ant-btn-ghost[disabled]:focus > a:only-child::after,
    .ant-btn-ghost[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-btn-dashed > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-dashed:hover > a:only-child,
    .ant-btn-dashed:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:hover > a:only-child::after,
    .ant-btn-dashed:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-dashed:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed[disabled],
    .ant-btn-dashed[disabled]:hover,
    .ant-btn-dashed[disabled]:focus,
    .ant-btn-dashed[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dashed[disabled] > a:only-child,
    .ant-btn-dashed[disabled]:hover > a:only-child,
    .ant-btn-dashed[disabled]:focus > a:only-child,
    .ant-btn-dashed[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed[disabled] > a:only-child::after,
    .ant-btn-dashed[disabled]:hover > a:only-child::after,
    .ant-btn-dashed[disabled]:focus > a:only-child::after,
    .ant-btn-dashed[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(102, 0, 0);
        border-color: rgb(192, 0, 0);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(85, 0, 0);
        border-color: rgb(191, 0, 0);
    }
    .ant-btn-danger:hover > a:only-child,
    .ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:hover > a:only-child::after,
    .ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:active {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(105, 14, 18);
        border-color: rgb(173, 25, 31);
    }
    .ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger[disabled],
    .ant-btn-danger[disabled]:hover,
    .ant-btn-danger[disabled]:focus,
    .ant-btn-danger[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-danger[disabled] > a:only-child,
    .ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-link:hover > a:only-child,
    .ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:hover > a:only-child::after,
    .ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus,
    .ant-btn-link:active {
        border-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-text:hover > a:only-child,
    .ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:hover > a:only-child::after,
    .ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-text:active {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:hover,
    .ant-btn-dangerous:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(191, 0, 0);
    }
    .ant-btn-dangerous:hover > a:only-child,
    .ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(173, 25, 31);
    }
    .ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous[disabled],
    .ant-btn-dangerous[disabled]:hover,
    .ant-btn-dangerous[disabled]:focus,
    .ant-btn-dangerous[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(102, 0, 0);
        border-color: rgb(192, 0, 0);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:hover,
    .ant-btn-dangerous.ant-btn-primary:focus {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(85, 0, 0);
        border-color: rgb(191, 0, 0);
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:active {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(105, 14, 18);
        border-color: rgb(173, 25, 31);
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled],
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn::before {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
        border-left-color: transparent;
    }
    .ant-btn-background-ghost {
        color: rgb(224, 224, 224);
        border-color: rgb(95, 95, 95);
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-btn-background-ghost.ant-btn-primary {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 96, 194);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover,
    .ant-btn-background-ghost.ant-btn-primary:focus {
        color: rgb(90, 192, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 103, 193);
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:active {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 94, 198);
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled],
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(192, 0, 0);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover,
    .ant-btn-background-ghost.ant-btn-danger:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(191, 0, 0);
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(173, 25, 31);
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled],
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(192, 0, 0);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover,
    .ant-btn-background-ghost.ant-btn-dangerous:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(191, 0, 0);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(173, 25, 31);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 119, 115);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
        color: rgb(235, 98, 106);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-right-color: rgb(0, 103, 193);
        border-left-color: rgb(96, 96, 96);
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-right-color: rgb(96, 96, 96);
        border-left-color: rgb(0, 103, 193);
    }
    .ant-picker-calendar {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-picker-calendar .ant-picker-panel {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(96, 96, 96);
    }
    .ant-picker-calendar-full .ant-picker-panel {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: initial;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgb(71, 183, 255);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(96, 96, 96);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
        border-color: rgb(0, 96, 194);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-radio-group {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
        border-left-color: initial;
    }
    .ant-radio-wrapper {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-radio {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: rgba(0, 60, 124, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-checked::after {
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-radio-inner::after {
        background-color: rgb(0, 60, 124);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-disabled .ant-radio-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96) !important;
    }
    .ant-radio-disabled .ant-radio-inner::after {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .ant-radio-disabled + span {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-radio-button-wrapper {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(24, 26, 27);
    }
    .ant-radio-button-wrapper:first-child {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper:hover {
        color: rgb(71, 183, 255);
    }
    .ant-radio-button-wrapper:focus-within {
        box-shadow: rgba(0, 60, 124, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: rgb(0, 60, 124);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(90, 192, 255);
        border-color: rgb(0, 103, 193);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: rgb(0, 56, 107);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(96, 190, 255);
        border-color: rgb(0, 94, 198);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
        background-color: rgb(0, 53, 113);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 60, 124, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 56, 107);
        border-color: rgb(0, 103, 193);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 53, 113);
        border-color: rgb(0, 94, 198);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 60, 124, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper-disabled:first-child,
    .ant-radio-button-wrapper-disabled:hover {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper-disabled:first-child {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(18, 20, 21);
        border-color: rgb(96, 96, 96);
        box-shadow: none;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-right-color: rgb(0, 103, 193);
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-picker {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border-color: rgb(0, 103, 193);
    }
    .ant-picker-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker.ant-picker-disabled {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-picker.ant-picker-borderless {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-picker-input > input {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-input > input::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-picker-input > input:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-picker-input > input:focus,
    .ant-picker-input > input-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker-input > input-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-input > input-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-picker-input > input[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-input > input[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-picker-input > input-borderless,
    .ant-picker-input > input-borderless:hover,
    .ant-picker-input > input-borderless:focus,
    .ant-picker-input > input-borderless-focused,
    .ant-picker-input > input-borderless-disabled,
    .ant-picker-input > input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-picker-input > input:focus {
        box-shadow: none;
    }
    .ant-picker-input > input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-input-placeholder > input {
        color: rgb(194, 194, 194);
    }
    .ant-picker-suffix {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-picker-clear {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-picker-clear:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-picker-separator {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-picker-focused .ant-picker-separator {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-picker-range .ant-picker-active-bar {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-picker-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-picker-ranges {
        list-style-image: initial;
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: rgb(0, 23, 38);
        border-color: rgb(0, 113, 190);
    }
    .ant-picker-range-arrow {
        box-shadow: rgba(0, 0, 0, 0.06) 2px -2px 6px;
    }
    .ant-picker-range-arrow::after {
        border-color: rgb(95, 95, 95) rgb(95, 95, 95) transparent transparent;
    }
    .ant-picker-panel-container {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-picker-panel-container .ant-picker-panel {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-container .ant-picker-panel-focused {
        border-color: rgb(96, 96, 96);
    }
    .ant-picker-panel {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-picker-panel-focused {
        border-color: rgb(0, 96, 194);
    }
    .ant-picker-header {
        color: rgba(224, 224, 224, 0.85);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-picker-header button {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-header > button:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-picker-header-view button {
        color: inherit;
    }
    .ant-picker-header-view button:hover {
        color: rgb(71, 183, 255);
    }
    .ant-picker-prev-icon::before,
    .ant-picker-next-icon::before,
    .ant-picker-super-prev-icon::before,
    .ant-picker-super-next-icon::before {
        border-color: currentcolor;
    }
    .ant-picker-super-prev-icon::after,
    .ant-picker-super-next-icon::after {
        border-color: currentcolor;
    }
    .ant-picker-content th {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-picker-cell {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-picker-cell-in-view {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(0, 96, 194);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
        border-top-color: rgb(0, 95, 191);
        border-bottom-color: rgb(0, 95, 191);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 95, 191);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 95, 191);
    }
    .ant-picker-cell-disabled .ant-picker-cell-inner {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-cell-disabled::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-footer {
        border-bottom-color: transparent;
    }
    .ant-picker-panel .ant-picker-footer {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-picker-footer-extra:not(:last-child) {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-picker-today-btn {
        color: rgb(71, 183, 255);
    }
    .ant-picker-today-btn:hover {
        color: rgb(90, 192, 255);
    }
    .ant-picker-today-btn:active {
        color: rgb(96, 190, 255);
    }
    .ant-picker-today-btn.ant-picker-today-btn-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 95, 191);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 95, 191);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 95, 191);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-left-color: rgb(0, 95, 191);
    }
    .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-picker-week-panel-row:hover td {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row-selected:hover td {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
        color: rgba(224, 224, 224, 0.5);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(95, 95, 95);
    }
    .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
    .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
        color: rgb(224, 224, 224);
    }
    .ant-picker-datetime-panel .ant-picker-time-panel {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-picker-time-panel-column {
        list-style-image: initial;
    }
    .ant-picker-time-panel-column:not(:first-child) {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-picker-time-panel-column-active {
        background-image: initial;
        background-color: rgba(0, 23, 38, 0.2);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 95, 191);
        border-left-color: initial;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: initial;
        border-left-color: rgb(0, 95, 191);
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
        border-right-color: rgb(0, 95, 191);
        border-left-color: rgb(0, 95, 191);
    }
    .ant-tag {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-tag-close-icon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-tag-close-icon:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-tag-has-color {
        border-color: transparent;
    }
    .ant-tag-has-color,
    .ant-tag-has-color a,
    .ant-tag-has-color a:hover,
    .ant-tag-has-color .anticon-close,
    .ant-tag-has-color .anticon-close:hover {
        color: rgb(224, 224, 224);
    }
    .ant-tag-checkable {
        background-color: transparent;
        border-color: transparent;
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: rgb(71, 183, 255);
    }
    .ant-tag-checkable:active,
    .ant-tag-checkable-checked {
        color: rgb(224, 224, 224);
    }
    .ant-tag-checkable-checked {
        background-color: rgb(0, 60, 124);
    }
    .ant-tag-checkable:active {
        background-color: rgb(0, 53, 113);
    }
    .ant-tag-pink {
        color: rgb(242, 98, 182);
        background-image: initial;
        background-color: rgb(34, 0, 8);
        border-color: rgb(190, 0, 81);
    }
    .ant-tag-pink-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(111, 3, 63);
        border-color: rgb(188, 9, 107);
    }
    .ant-tag-magenta {
        color: rgb(242, 98, 182);
        background-image: initial;
        background-color: rgb(34, 0, 8);
        border-color: rgb(190, 0, 81);
    }
    .ant-tag-magenta-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(111, 3, 63);
        border-color: rgb(188, 9, 107);
    }
    .ant-tag-red {
        color: rgb(250, 90, 102);
        background-image: initial;
        background-color: rgb(34, 0, 0);
        border-color: rgb(190, 3, 0);
    }
    .ant-tag-red-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(118, 0, 4);
        border-color: rgb(197, 0, 10);
    }
    .ant-tag-volcano {
        color: rgb(255, 121, 85);
        background-image: initial;
        background-color: rgb(37, 11, 0);
        border-color: rgb(190, 62, 0);
    }
    .ant-tag-volcano-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(122, 26, 0);
        border-color: rgb(196, 44, 0);
    }
    .ant-tag-orange {
        color: rgb(255, 169, 82);
        background-image: initial;
        background-color: rgb(38, 23, 0);
        border-color: rgb(190, 113, 0);
    }
    .ant-tag-orange-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(124, 62, 0);
        border-color: rgb(196, 99, 0);
    }
    .ant-tag-yellow {
        color: rgb(255, 231, 82);
        background-image: initial;
        background-color: rgb(36, 38, 0);
        border-color: rgb(191, 183, 0);
    }
    .ant-tag-yellow-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(125, 107, 0);
        border-color: rgb(196, 169, 0);
    }
    .ant-tag-gold {
        color: rgb(255, 195, 82);
        background-image: initial;
        background-color: rgb(38, 30, 0);
        border-color: rgb(191, 144, 0);
    }
    .ant-tag-gold-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(125, 81, 0);
        border-color: rgb(196, 129, 0);
    }
    .ant-tag-cyan {
        color: rgb(110, 254, 255);
        background-image: initial;
        background-color: rgb(0, 38, 31);
        border-color: rgb(25, 169, 154);
    }
    .ant-tag-cyan-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(2, 100, 100);
        border-color: rgb(9, 190, 190);
    }
    .ant-tag-lime {
        color: rgb(210, 255, 98);
        background-image: initial;
        background-color: rgb(34, 38, 0);
        border-color: rgb(153, 191, 0);
    }
    .ant-tag-lime-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(81, 113, 2);
        border-color: rgb(139, 193, 5);
    }
    .ant-tag-green {
        color: rgb(154, 254, 112);
        background-image: initial;
        background-color: rgb(18, 35, 0);
        border-color: rgb(88, 172, 22);
    }
    .ant-tag-green-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(38, 102, 6);
        border-color: rgb(72, 184, 15);
    }
    .ant-tag-blue {
        color: rgb(96, 190, 255);
        background-image: initial;
        background-color: rgb(0, 23, 38);
        border-color: rgb(0, 113, 190);
    }
    .ant-tag-blue-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
    }
    .ant-tag-geekblue {
        color: rgb(112, 186, 244);
        background-image: initial;
        background-color: rgb(12, 13, 14);
        border-color: rgb(0, 53, 190);
    }
    .ant-tag-geekblue-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(3, 25, 111);
        border-color: rgb(9, 45, 188);
    }
    .ant-tag-purple {
        color: rgb(162, 112, 240);
        background-image: initial;
        background-color: rgb(12, 13, 14);
        border-color: rgb(100, 8, 185);
    }
    .ant-tag-purple-inverse {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(56, 18, 109);
        border-color: rgb(89, 29, 169);
    }
    .ant-tag-success {
        color: rgb(145, 244, 98);
        background-image: initial;
        background-color: rgb(18, 35, 0);
        border-color: rgb(88, 172, 22);
    }
    .ant-tag-processing {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: rgb(0, 23, 38);
        border-color: rgb(0, 113, 190);
    }
    .ant-tag-error {
        color: rgb(255, 80, 89);
        background-image: initial;
        background-color: rgb(34, 0, 0);
        border-color: rgb(190, 3, 0);
    }
    .ant-tag-warning {
        color: rgb(255, 171, 72);
        background-image: initial;
        background-color: rgb(38, 23, 0);
        border-color: rgb(190, 113, 0);
    }
    .ant-card {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-card-hoverable:hover {
        border-color: transparent;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-bordered {
        border-color: rgb(96, 96, 96);
    }
    .ant-card-head {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: transparent;
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-card-head .ant-tabs {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-card-head .ant-tabs-bar {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-card-extra {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-card-grid {
        border-color: initial;
        box-shadow: rgb(15, 16, 17) 1px 0px 0px 0px,
        rgb(15, 16, 17) 0px 1px 0px 0px,
        rgb(15, 16, 17) 1px 1px 0px 0px,
        rgb(15, 16, 17) 1px 0px 0px 0px inset,
        rgb(15, 16, 17) 0px 1px 0px 0px inset;
    }
    .ant-card-grid-hoverable:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-actions {
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-top-color: rgb(96, 96, 96);
    }
    .ant-card-actions > li {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-card-actions > li > span:hover {
        color: rgb(71, 183, 255);
    }
    .ant-card-actions > li > span a:not(.ant-btn),
    .ant-card-actions > li > span > .anticon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-card-actions > li > span a:not(.ant-btn):hover,
    .ant-card-actions > li > span > .anticon:hover {
        color: rgb(71, 183, 255);
    }
    .ant-card-actions > li:not(:last-child) {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-card-rtl .ant-card-actions > li:not(:last-child) {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-card-type-inner .ant-card-head {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-card-meta-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-card-meta-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-card-loading-block {
        background-image: linear-gradient(90deg,
        rgba(22, 28, 31, 0.2),
        rgba(22, 28, 31, 0.4),
        rgba(22, 28, 31, 0.2));
        background-color: initial;
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 10px 0px 8px -8px inset;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) -10px 0px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-tabs-right > .ant-tabs-content-holder,
    .ant-tabs-right > div > .ant-tabs-content-holder {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-tabs-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-tabs-dropdown-menu {
        background-color: rgb(8, 10, 11);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tabs-dropdown-menu-item {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-tabs-dropdown-menu-item:hover {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tabs-dropdown-menu-item-disabled,
    .ant-tabs-dropdown-menu-item-disabled:hover {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: rgb(71, 183, 255);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom-color: rgb(95, 95, 95);
    }
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-top-color: rgb(95, 95, 95);
    }
    .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-right-color: rgb(95, 95, 95);
    }
    .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-left-color: rgb(95, 95, 95);
    }
    .ant-tabs {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
        color: rgb(90, 192, 255);
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
        color: rgb(96, 190, 255);
    }
    .ant-tabs-ink-bar {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-tabs-tab {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: rgb(96, 190, 255);
    }
    .ant-tabs-tab-btn {
        outline-color: initial;
    }
    .ant-tabs-tab-remove {
        color: rgba(224, 224, 224, 0.45);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-remove:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-tabs-tab:hover {
        color: rgb(90, 192, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: rgb(71, 183, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-tabs-tabpane {
        outline-color: initial;
    }
    .ant-carousel {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-carousel .slick-slider {
        -webkit-tap-highlight-color: transparent;
    }
    .ant-carousel .slick-list:focus {
        outline-color: initial;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        outline-color: initial;
    }
    .ant-carousel .slick-dots {
        list-style-image: initial;
    }
    .ant-carousel .slick-dots li button {
        color: transparent;
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-cascader {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-cascader-input.ant-input {
        background-color: transparent !important;
    }
    .ant-cascader-picker {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
        outline-color: initial;
    }
    .ant-cascader-picker-with-value .ant-cascader-picker-label {
        color: transparent;
    }
    .ant-cascader-picker-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-cascader-picker-borderless .ant-cascader-input {
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-cascader-picker-show-search.ant-cascader-picker-focused {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-cascader-picker-clear {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-cascader-picker-clear:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-cascader-picker-arrow {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-cascader-picker-label:hover + .ant-cascader-input {
        border-color: rgb(0, 103, 193);
    }
    .ant-cascader-menus {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-cascader-menus ul,
    .ant-cascader-menus ol {
        list-style-image: initial;
    }
    .ant-cascader-menu {
        list-style-image: initial;
        border-right-color: rgb(96, 96, 96);
    }
    .ant-cascader-menu:last-child {
        border-right-color: transparent;
    }
    .ant-cascader-menu-item:hover {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-cascader-menu-item-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-cascader-menu-item-disabled:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-cascader-menu-empty .ant-cascader-menu-item {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: rgb(0, 23, 38);
    }
    .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-loading-icon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
        color: rgb(255, 96, 98);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
        border-left-color: transparent;
    }
    .ant-input-affix-wrapper {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        background-image: none;
        border-color: rgb(96, 96, 96);
    }
    .ant-input-affix-wrapper::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-input-affix-wrapper:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-affix-wrapper-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-affix-wrapper-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-affix-wrapper[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-affix-wrapper[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-affix-wrapper-borderless,
    .ant-input-affix-wrapper-borderless:hover,
    .ant-input-affix-wrapper-borderless:focus,
    .ant-input-affix-wrapper-borderless-focused,
    .ant-input-affix-wrapper-borderless-disabled,
    .ant-input-affix-wrapper-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-input-affix-wrapper > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-affix-wrapper > input.ant-input:focus {
        box-shadow: none;
    }
    .ant-input-clear-icon {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-input-clear-icon:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-clear-icon:active {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        border-color: initial !important;
    }
    .ant-input {
        list-style-image: initial;
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        background-image: none;
        border-color: rgb(96, 96, 96);
    }
    .ant-input::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-input:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-group {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-input-group-addon {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
    }
    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: inherit;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-input-group-addon .ant-select-open .ant-select-selector,
    .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: rgb(71, 183, 255);
    }
    .ant-input-group-addon:first-child {
        border-right-color: initial;
    }
    .ant-input-group-addon:last-child {
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:first-child {
        border-right-color: rgb(96, 96, 96);
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:last-child {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-input-password-icon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-password-icon:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-input-textarea-show-count::after {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
        border-color: rgb(0, 103, 193);
    }
    .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-left-color: rgb(0, 103, 193);
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        border-color: initial;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-right-color: rgb(0, 103, 193);
        border-left-color: rgb(96, 96, 96);
    }
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
        border-right-color: rgb(0, 103, 193);
    }
    .ant-checkbox {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: rgb(0, 96, 194);
    }
    .ant-checkbox-checked::after {
        border-color: rgb(0, 96, 194);
    }
    .ant-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96) !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner::after {
        border-color: rgb(96, 96, 96);
    }
    .ant-checkbox-disabled + span {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-checkbox-wrapper {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-group {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: rgb(0, 60, 124);
        border-color: initial;
    }
    .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-collapse {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(10, 12, 13);
        border-top-color: rgb(96, 96, 96);
        border-right-color: rgb(96, 96, 96);
        border-left-color: rgb(96, 96, 96);
        border-bottom-color: initial;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        color: inherit;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
        outline-color: initial;
    }
    .ant-collapse-content {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        border-top-color: rgb(96, 96, 96);
    }
    .ant-collapse-borderless {
        background-color: rgb(10, 12, 13);
        border-color: initial;
    }
    .ant-collapse-borderless > .ant-collapse-item {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse-ghost {
        background-color: transparent;
        border-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-comment {
        background-color: inherit;
    }
    .ant-comment-content-author-name {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-comment-content-author-name > * {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-comment-content-author-name > :hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-comment-content-author-time {
        color: rgb(199, 199, 199);
    }
    .ant-comment-actions > li {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-comment-actions > li > span {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-comment-actions > li > span:hover {
        color: rgb(182, 182, 182);
    }
    .ant-descriptions-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-descriptions-extra {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-item-label {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-descriptions-item-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border-color: rgb(96, 96, 96);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-right-color: initial;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color: rgb(10, 12, 13);
    }
    .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-descriptions-bordered .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-left-color: initial;
    }
    .ant-divider {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        border-top-color: rgba(103, 103, 103, 0.06);
    }
    .ant-divider-vertical {
        border-top-color: initial;
        border-left-color: rgba(103, 103, 103, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text {
        color: rgba(224, 224, 224, 0.85);
        border-top-color: rgba(103, 103, 103, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-top-color: inherit;
        border-bottom-color: initial;
    }
    .ant-divider-dashed {
        background-image: none;
        background-color: initial;
        border-color: rgba(103, 103, 103, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
        border-top-color: initial;
    }
    .ant-divider-plain.ant-divider-with-text {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) 9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) 12px 0px 48px 16px;
    }
    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) -6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) -9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) -12px 0px 48px 16px;
    }
    .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px 12px 48px 16px;
    }
    .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px -9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px -12px 48px 16px;
    }
    .ant-drawer-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-drawer-content {
        background-color: rgb(8, 10, 11);
        border-color: initial;
    }
    .ant-drawer-close {
        color: rgba(224, 224, 224, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-drawer-close:focus,
    .ant-drawer-close:hover {
        color: rgba(224, 224, 224, 0.75);
        text-decoration-color: initial;
    }
    .ant-drawer-header {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-drawer-header-no-title {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-drawer-footer {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-drawer-open-content {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-drawer .ant-picker-clear {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-form-item .ant-upload {
        background-image: initial;
        background-color: transparent;
    }
    .ant-form-item .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-explain.ant-form-item-explain-warning {
        color: rgb(255, 199, 71);
    }
    .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(145, 244, 98);
    }
    .ant-form-item-has-warning .ant-form-item-split {
        color: rgb(255, 199, 71);
    }
    .ant-form-item-has-warning .ant-input,
    .ant-form-item-has-warning .ant-input-affix-wrapper,
    .ant-form-item-has-warning .ant-input:hover,
    .ant-form-item-has-warning .ant-input-affix-wrapper:hover {
        background-color: rgb(8, 10, 11);
        border-color: rgb(196, 129, 0);
    }
    .ant-form-item-has-warning .ant-input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
    .ant-form-item-has-warning .ant-input-focused,
    .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
        border-color: rgb(193, 132, 0);
        outline-color: initial;
        box-shadow: rgba(125, 81, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-disabled,
    .ant-form-item-has-warning .ant-input-disabled:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(193, 132, 0);
        outline-color: initial;
        box-shadow: rgba(125, 81, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-prefix {
        color: rgb(255, 199, 71);
    }
    .ant-form-item-has-warning .ant-input-group-addon {
        color: rgb(255, 199, 71);
        border-color: rgb(196, 129, 0);
    }
    .ant-form-item-has-warning .has-feedback {
        color: rgb(255, 199, 71);
    }
    .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(255, 199, 71);
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(8, 10, 11);
        border-color: rgb(196, 129, 0) !important;
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(193, 132, 0);
        outline-color: initial;
        box-shadow: rgba(125, 81, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number,
    .ant-form-item-has-warning .ant-picker {
        background-color: rgb(8, 10, 11);
        border-color: rgb(196, 129, 0);
    }
    .ant-form-item-has-warning .ant-input-number-focused,
    .ant-form-item-has-warning .ant-picker-focused,
    .ant-form-item-has-warning .ant-input-number:focus,
    .ant-form-item-has-warning .ant-picker:focus {
        border-color: rgb(193, 132, 0);
        outline-color: initial;
        box-shadow: rgba(125, 81, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
        background-color: rgb(8, 10, 11);
        border-color: rgb(196, 129, 0);
    }
    .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(193, 132, 0);
        outline-color: initial;
        box-shadow: rgba(125, 81, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-form-item-split {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper:focus,
    .ant-form-item-has-error .ant-input-focused,
    .ant-form-item-has-error .ant-input-affix-wrapper-focused {
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-disabled,
    .ant-form-item-has-error .ant-input-disabled:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-prefix {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-input-group-addon {
        color: rgb(255, 96, 98);
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .has-feedback {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0) !important;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: initial;
    }
    .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus {
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
        background-color: rgb(8, 10, 11);
        border-color: rgb(191, 0, 0);
        outline-color: initial;
        box-shadow: rgba(102, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-transfer-list {
        border-color: rgb(192, 0, 0);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
        border-color: rgb(96, 96, 96);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper {
        border-color: rgb(192, 0, 0) !important;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(102, 0, 0);
    }
    .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(71, 183, 255);
    }
    .ant-form {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-form legend {
        color: rgba(224, 224, 224, 0.45);
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(96, 96, 96); 
    }
    .ant-form input[type="file"]:focus, .ant-form input[type="radio"]:focus, .ant-form input[type="checkbox"]:focus {
        outline-color: rgb(196, 126, 0);
    }
    .ant-form output {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-form-item {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-form-item-label > label {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-label > label .ant-form-item-optional {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-form-item-label > label .ant-form-item-tooltip {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-image-img-placeholder {
        background-color: rgb(13, 14, 15);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
    }
    .ant-image-mask {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-image-preview-wrap {
        outline-color: initial;
    }
    .ant-image-preview-operations {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-operations-operation-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-switch-left-disabled,
    .ant-image-preview-switch-right-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-input-number {
        list-style-image: initial;
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        background-image: none;
        border-color: rgb(96, 96, 96);
    }
    .ant-input-number::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-input-number:focus,
    .ant-input-number-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-number[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-number[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-number-borderless,
    .ant-input-number-borderless:hover,
    .ant-input-number-borderless:focus,
    .ant-input-number-borderless-focused,
    .ant-input-number-borderless-disabled,
    .ant-input-number-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-number-handler {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-number-handler:active {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: rgb(90, 192, 255);
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-input-number-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-input-number-input {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-number-input::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-input-number-handler-wrap {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-left-color: rgb(96, 96, 96);
    }
    .ant-input-number-handler-down {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-input-number-borderless {
        box-shadow: none;
    }
    .ant-input-number-out-of-range input {
        color: rgb(255, 96, 98);
    }
    .ant-input-number-rtl .ant-input-number-handler-wrap {
        border-right-color: rgb(96, 96, 96);
        border-left-color: initial;
    }
    .ant-layout {
        background-image: initial;
        background-color: rgb(14, 15, 16);
    }
    .ant-layout-header {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(0, 4, 14);
    }
    .ant-layout-footer {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(14, 15, 16);
    }
    .ant-layout-sider {
        background-image: initial;
        background-color: rgb(0, 4, 14);
    }
    .ant-layout-sider-trigger {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 10, 27);
    }
    .ant-layout-sider-zero-width-trigger {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: rgb(0, 4, 14);
    }
    .ant-layout-sider-zero-width-trigger::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-layout-sider-zero-width-trigger:hover::after {
        background-image: initial;
        background-color: rgba(8, 10, 11, 0.1);
    }
    .ant-layout-sider-light {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-layout-sider-light .ant-layout-sider-trigger {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-list {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-list * {
        outline-color: initial;
    }
    .ant-list-empty-text {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-list-items {
        list-style-image: initial;
    }
    .ant-list-item {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-list-item-meta-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-list-item-meta-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-list-item-meta-title > a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-list-item-meta-title > a:hover {
        color: rgb(71, 183, 255);
    }
    .ant-list-item-meta-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-list-item-action {
        list-style-image: initial;
    }
    .ant-list-item-action > li {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-list-item-action-split {
        background-color: rgb(15, 16, 17);
    }
    .ant-list-header {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-footer {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-empty {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-list-split .ant-list-item {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-list-split .ant-list-item:last-child {
        border-bottom-color: initial;
    }
    .ant-list-split .ant-list-header {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-list-split.ant-list-empty .ant-list-footer {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-list-vertical .ant-list-item-meta-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-list-grid .ant-col > .ant-list-item {
        border-bottom-color: initial;
    }
    .ant-list-bordered {
        border-color: rgb(96, 96, 96);
    }
    .ant-spin {
        list-style-image: initial;
        color: rgb(71, 183, 255);
    }
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
        text-shadow: rgb(8, 10, 11) 0px 1px 2px;
    }
    .ant-spin-container::after {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-spin-tip {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-spin-dot-item {
        background-color: rgb(0, 60, 124);
    }
    @media not all,
    not all {
        .ant-spin-blur {
            background-image: initial;
            background-color: rgb(8, 10, 11);
        }
    }
    .ant-pagination {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-pagination ul,
    .ant-pagination ol {
        list-style-image: initial;
    }
    .ant-pagination-item {
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-pagination-item a {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-pagination-item a:hover {
        text-decoration-color: initial;
    }
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: rgb(0, 96, 194);
    }
    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
        color: rgb(71, 183, 255);
    }
    .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 96, 194);
    }
    .ant-pagination-item-active a {
        color: rgb(71, 183, 255);
    }
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-pagination-item-active:focus-visible a,
    .ant-pagination-item-active:hover a {
        color: rgb(90, 192, 255);
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        outline-color: initial;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: rgb(71, 183, 255);
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        outline-color: initial;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-pagination-prev:hover button,
    .ant-pagination-next:hover button {
        border-color: rgb(0, 103, 193);
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: rgb(71, 183, 255);
        border-color: rgb(0, 96, 194);
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: rgba(224, 224, 224, 0.25);
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination-options-quick-jumper input {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        background-image: none;
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination-options-quick-jumper input::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-pagination-options-quick-jumper input:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-pagination-options-quick-jumper input:focus,
    .ant-pagination-options-quick-jumper input-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-pagination-options-quick-jumper input-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-pagination-options-quick-jumper input-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination-options-quick-jumper input[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-pagination-options-quick-jumper input[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination-options-quick-jumper input-borderless,
    .ant-pagination-options-quick-jumper input-borderless:hover,
    .ant-pagination-options-quick-jumper input-borderless:focus,
    .ant-pagination-options-quick-jumper input-borderless-focused,
    .ant-pagination-options-quick-jumper input-borderless-disabled,
    .ant-pagination-options-quick-jumper input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input:hover {
        border-color: rgb(0, 96, 194);
    }
    .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(23, 25, 26);
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
        color: rgb(224, 224, 224);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        color: rgba(224, 224, 224, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96);
    }
    .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-mentions {
        list-style-image: initial;
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(8, 10, 11);
        background-image: none;
        border-color: rgb(96, 96, 96);
    }
    .ant-mentions::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-mentions:hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-mentions:focus,
    .ant-mentions-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions-disabled {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-mentions-disabled:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-mentions[disabled] {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-mentions[disabled]:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-mentions-borderless,
    .ant-mentions-borderless:hover,
    .ant-mentions-borderless:focus,
    .ant-mentions-borderless-focused,
    .ant-mentions-borderless-disabled,
    .ant-mentions-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-mentions-disabled > textarea {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-mentions-disabled > textarea:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-mentions-focused {
        border-color: rgb(0, 103, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions > textarea {
        border-color: initial;
        outline-color: initial;
    }
    .ant-mentions > textarea::placeholder {
        color: rgb(194, 194, 194);
    }
    .ant-mentions-measure {
        color: transparent;
    }
    .ant-mentions-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-mentions-dropdown-menu {
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-mentions-dropdown-menu-item {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-mentions-dropdown-menu-item:hover {
        background-color: rgb(13, 14, 15);
    }
    .ant-mentions-dropdown-menu-item-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-mentions-dropdown-menu-item-disabled:hover {
        color: rgba(224, 224, 224, 0.25);
        background-color: rgb(8, 10, 11);
    }
    .ant-mentions-dropdown-menu-item-selected {
        color: rgba(224, 224, 224, 0.85);
        background-color: rgb(10, 12, 13);
    }
    .ant-mentions-dropdown-menu-item-active {
        background-color: rgb(13, 14, 15);
    }
    .ant-message {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-message-notice-content {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-message-success .anticon {
        color: rgb(145, 244, 98);
    }
    .ant-message-error .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-message-warning .anticon {
        color: rgb(255, 199, 71);
    }
    .ant-message-info .anticon,
    .ant-message-loading .anticon {
        color: rgb(71, 183, 255);
    }
    .ant-modal {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-modal-wrap {
        outline-color: initial;
    }
    .ant-modal-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-modal-content {
        background-color: rgb(8, 10, 11);
        border-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-modal-close {
        color: rgba(224, 224, 224, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
        color: rgba(224, 224, 224, 0.75);
        text-decoration-color: initial;
    }
    .ant-modal-header {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-modal-footer {
        background-image: initial;
        background-color: transparent;
        border-top-color: rgb(96, 96, 96);
    }
    .ant-modal-confirm-body .ant-modal-confirm-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
    .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
        color: rgb(255, 199, 71);
    }
    .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: rgb(71, 183, 255);
    }
    .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
        color: rgb(145, 244, 98);
    }
    .ant-notification {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-notification-hook-holder > .ant-notification-notice {
        box-shadow: none;
    }
    .ant-notification-notice-message {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-notification-notice-message-single-line-auto-margin {
        background-color: transparent;
    }
    .anticon.ant-notification-notice-icon-success {
        color: rgb(145, 244, 98);
    }
    .anticon.ant-notification-notice-icon-info {
        color: rgb(71, 183, 255);
    }
    .anticon.ant-notification-notice-icon-warning {
        color: rgb(255, 199, 71);
    }
    .anticon.ant-notification-notice-icon-error {
        color: rgb(255, 96, 98);
    }
    .ant-notification-notice-close {
        color: rgba(224, 224, 224, 0.45);
        outline-color: initial;
    }
    .ant-notification-notice-close:hover {
        color: rgba(224, 224, 224, 0.67);
    }
    .ant-page-header {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-page-header-ghost {
        background-color: inherit;
    }
    .ant-page-header-back-button {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(224, 224, 224);
    }
    .ant-page-header-back-button:focus,
    .ant-page-header-back-button:hover {
        color: rgb(90, 192, 255);
    }
    .ant-page-header-back-button:active {
        color: rgb(96, 190, 255);
    }
    .ant-page-header-heading-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-page-header-heading-sub-title {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
        border-color: initial;
    }
    .ant-progress {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-progress-steps-item {
        background-image: initial;
        background-color: rgb(14, 15, 16);
    }
    .ant-progress-steps-item-active {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-progress-inner {
        background-color: rgb(13, 14, 15);
    }
    .ant-progress-circle-trail {
        stroke: rgb(219, 219, 219);
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(71, 183, 255);
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
        background-color: rgb(0, 60, 124);
    }
    .ant-progress-success-bg {
        background-color: rgb(38, 102, 6);
    }
    .ant-progress-text {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-progress-status-active .ant-progress-bg::before {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-progress-status-exception .ant-progress-bg {
        background-color: rgb(102, 0, 0);
    }
    .ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 96, 98);
    }
    .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(255, 96, 98);
    }
    .ant-progress-status-success .ant-progress-bg {
        background-color: rgb(38, 102, 6);
    }
    .ant-progress-status-success .ant-progress-text {
        color: rgb(145, 244, 98);
    }
    .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(145, 244, 98);
    }
    .ant-progress-circle .ant-progress-inner {
        background-color: transparent;
    }
    .ant-progress-circle .ant-progress-text {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 96, 98);
    }
    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
        color: rgb(145, 244, 98);
    }
    .ant-rate {
        color: rgb(255, 236, 71);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-rate-star {
        color: inherit;
    }
    .ant-rate-star > div:focus {
        outline-color: initial;
    }
    .ant-rate-star-first,
    .ant-rate-star-second {
        color: rgb(217, 217, 217);
    }
    .ant-rate-star-half .ant-rate-star-first,
    .ant-rate-star-full .ant-rate-star-second {
        color: inherit;
    }
    .ant-result-success .ant-result-icon > .anticon {
        color: rgb(145, 244, 98);
    }
    .ant-result-error .ant-result-icon > .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-result-info .ant-result-icon > .anticon {
        color: rgb(71, 183, 255);
    }
    .ant-result-warning .ant-result-icon > .anticon {
        color: rgb(255, 199, 71);
    }
    .ant-result-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-result-subtitle {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-result-content {
        background-color: rgb(10, 12, 13);
    }
    .ant-skeleton-header .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-title {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
        background-image: linear-gradient(90deg,
        rgba(35, 38, 39, 0.2) 25%,
        rgba(59, 64, 67, 0.24) 37%,
        rgba(35, 38, 39, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        background-image: linear-gradient(90deg,
        rgba(35, 38, 39, 0.2) 25%,
        rgba(59, 64, 67, 0.24) 37%,
        rgba(35, 38, 39, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
        background-image: linear-gradient(90deg,
        rgba(35, 38, 39, 0.2) 25%,
        rgba(59, 64, 67, 0.24) 37%,
        rgba(35, 38, 39, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
        background-image: linear-gradient(90deg,
        rgba(35, 38, 39, 0.2) 25%,
        rgba(59, 64, 67, 0.24) 37%,
        rgba(35, 38, 39, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
        background-image: linear-gradient(90deg,
        rgba(35, 38, 39, 0.2) 25%,
        rgba(59, 64, 67, 0.24) 37%,
        rgba(35, 38, 39, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton-element .ant-skeleton-button {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-input {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image {
        background-image: initial;
        background-color: rgba(35, 38, 39, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image-path {
        fill: rgb(194, 194, 194);
    }
    .ant-slider {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-slider-rail {
        background-color: rgb(13, 14, 15);
    }
    .ant-slider-track {
        background-color: rgb(0, 42, 74);
    }
    .ant-slider-handle {
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 113, 190);
    }
    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
        border-color: rgb(0, 96, 193);
        box-shadow: rgba(0, 60, 124, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle:focus {
        border-color: rgb(0, 96, 193);
        outline-color: initial;
        box-shadow: rgba(0, 60, 124, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: rgb(0, 96, 194);
    }
    .ant-slider:hover .ant-slider-rail {
        background-color: rgb(21, 22, 23);
    }
    .ant-slider:hover .ant-slider-track {
        background-color: rgb(0, 49, 91);
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: rgb(0, 107, 192);
    }
    .ant-slider-mark-text {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-slider-mark-text-active {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-slider-step {
        background-image: initial;
        background-color: transparent;
    }
    .ant-slider-dot {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-slider-dot-active {
        border-color: rgb(0, 95, 191);
    }
    .ant-slider-disabled .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-slider-disabled .ant-slider-handle,
    .ant-slider-disabled .ant-slider-dot {
        background-color: rgb(8, 10, 11);
        box-shadow: none;
        border-color: rgba(103, 103, 103, 0.25) !important;
    }
    .ant-statistic {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-statistic-title {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-statistic-content {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-steps {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-steps-item-container {
        outline-color: initial;
    }
    .ant-steps-item-icon {
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-steps-item-icon .ant-steps-icon {
        color: rgb(71, 183, 255);
    }
    .ant-steps-item-tail::after {
        background-image: initial;
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-subtitle {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-item-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: rgb(8, 10, 11);
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-process .ant-steps-item-icon {
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 96, 194);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(71, 183, 255);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
        color: rgb(224, 224, 224);
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: rgb(8, 10, 11);
        border-color: rgb(0, 96, 194);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(71, 183, 255);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(0, 60, 124);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(0, 60, 124);
    }
    .ant-steps-item-error .ant-steps-item-icon {
        background-color: rgb(8, 10, 11);
        border-color: rgb(192, 0, 0);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(102, 0, 0);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(15, 16, 17);
    }
    .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(102, 0, 0);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
        color: rgb(71, 183, 255);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
        border-color: rgb(0, 96, 194);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
        color: rgb(71, 183, 255);
    }
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(71, 183, 255);
    }
    .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-small .ant-steps-item-description {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0);
    }
    .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::after {
        border-top-color: rgba(103, 103, 103, 0.25);
        border-right-color: rgba(103, 103, 103, 0.25);
        border-bottom-color: initial;
        border-left-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::before {
        background-color: rgb(0, 60, 124);
    }
    .ant-switch {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
        border-color: initial;
    }
    .ant-switch:focus {
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
    }
    .ant-switch-checked:focus {
        box-shadow: rgba(0, 60, 124, 0.2) 0px 0px 0px 2px;
    }
    .ant-switch:focus:hover {
        box-shadow: none;
    }
    .ant-switch-checked {
        background-color: rgb(0, 60, 124);
    }
    .ant-switch-loading *,
    .ant-switch-disabled * {
        box-shadow: none;
    }
    .ant-switch-inner {
        color: rgb(224, 224, 224);
    }
    .ant-switch-handle::before {
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 11, 0, 0.2) 0px 2px 4px 0px;
    }
    .ant-switch-loading-icon {
        color: rgba(224, 224, 224, 0.65);
    }
    .ant-switch-checked .ant-switch-loading-icon {
        color: rgb(71, 183, 255);
    }
    .ant-table-small .ant-table-thead > tr > th {
        background-color: rgb(10, 12, 13);
    }
    .ant-table.ant-table-bordered > .ant-table-title {
        border-top-color: rgb(96, 96, 96);
        border-right-color: rgb(96, 96, 96);
        border-left-color: rgb(96, 96, 96);
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
        border-top-color: rgb(96, 96, 96);
        border-left-color: rgb(96, 96, 96);
        border-right-color: initial;
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
        border-right-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-footer {
        border-right-color: rgb(96, 96, 96);
        border-bottom-color: rgb(96, 96, 96);
        border-left-color: rgb(96, 96, 96);
        border-top-color: initial;
    }
    .ant-table-cell .ant-table-container:first-child {
        border-top-color: initial;
    }
    .ant-table-cell-scrollbar {
        box-shadow: rgb(10, 12, 13) 0px 1px 0px 1px;
    }
    .ant-table {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-table-footer {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-table-thead > tr > th {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-table-thead > tr:not(:last-child) > th[colspan] {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr > td {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background-image: initial;
        background-color: rgb(0, 23, 38);
        border-color: rgba(103, 103, 103, 0.03);
    }
    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background-image: initial;
        background-color: rgb(0, 26, 42);
    }
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-image: initial;
        background-color: rgb(14, 15, 16);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
        background-image: initial;
        background-color: rgb(12, 13, 14);
    }
    .ant-table-thead th.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    td.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-table-column-sorter {
        color: rgb(194, 194, 194);
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: rgb(71, 183, 255);
    }
    .ant-table-filter-trigger-container-open,
    .ant-table-filter-trigger-container:hover,
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-table-filter-trigger {
        color: rgb(194, 194, 194);
    }
    .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
    .ant-table-filter-trigger:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-table-filter-trigger.active {
        color: rgb(71, 183, 255);
    }
    .ant-table-filter-dropdown {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-color: rgb(8, 10, 11);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-table-filter-dropdown .ant-dropdown-menu {
        border-color: initial;
        box-shadow: none;
    }
    .ant-table-filter-dropdown-btns {
        background-color: inherit;
        border-top-color: rgb(96, 96, 96);
    }
    .ant-table-selection-extra .anticon {
        color: rgb(194, 194, 194);
    }
    .ant-table-selection-extra .anticon:hover {
        color: rgb(182, 182, 182);
    }
    .ant-table-row-expand-icon {
        text-decoration-color: initial;
        color: inherit;
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
        outline-color: initial;
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover {
        color: rgb(90, 192, 255);
    }
    .ant-table-row-expand-icon:active {
        color: rgb(96, 190, 255);
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover,
    .ant-table-row-expand-icon:active {
        border-color: currentcolor;
    }
    .ant-table-row-expand-icon::before,
    .ant-table-row-expand-icon::after {
        background-image: initial;
        background-color: currentcolor;
    }
    .ant-table-row-expand-icon-spaced {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-left .ant-table-cell-fix-left-first::after,
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-ping-right .ant-table-cell-fix-right-first::after,
    .ant-table-ping-right .ant-table-cell-fix-right-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-sticky-scroll {
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-top-color: rgb(96, 96, 96);
    }
    .ant-table-sticky-scroll-bar {
        background-color: rgba(0, 0, 0, 0.35);
    }
    .ant-table-sticky-scroll-bar:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-table-sticky-scroll-bar-active {
        background-color: rgba(0, 0, 0, 0.8);
    }
    @media not all {
        .ant-table-ping-left .ant-table-cell-fix-left-last::after {
            box-shadow: none !important;
        }
        .ant-table-ping-right .ant-table-cell-fix-right-first::after {
            box-shadow: none !important;
        }
        .ant-table-ping-left .ant-table-cell-fix-left-last::after {
            box-shadow: none !important;
        }
        .ant-table-ping-right .ant-table-cell-fix-right-first::after {
            box-shadow: none !important;
        }
    }
    .ant-timeline {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-timeline-item {
        list-style-image: initial;
    }
    .ant-timeline-item-tail {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-timeline-item-pending .ant-timeline-item-head {
        background-color: transparent;
    }
    .ant-timeline-item-head {
        background-color: rgb(8, 10, 11);
        border-color: transparent;
    }
    .ant-timeline-item-head-blue {
        color: rgb(71, 183, 255);
        border-color: rgb(0, 96, 194);
    }
    .ant-timeline-item-head-red {
        color: rgb(255, 96, 98);
        border-color: rgb(192, 0, 0);
    }
    .ant-timeline-item-head-green {
        color: rgb(145, 244, 98);
        border-color: rgb(72, 184, 15);
    }
    .ant-timeline-item-head-gray {
        color: rgba(224, 224, 224, 0.25);
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-timeline-item-head-custom {
        border-color: initial;
    }
    .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-timeline-rtl .ant-timeline-item-tail {
        border-right-color: rgb(96, 96, 96);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-right-color: rgb(96, 96, 96);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-right-color: rgb(96, 96, 96);
        border-left-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
        border-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
        background-image: initial;
        background-color: rgb(10, 12, 13);
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-transfer-customize-list .ant-input[disabled] {
        background-color: transparent;
    }
    .ant-transfer {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-transfer-disabled .ant-transfer-list {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-transfer-list {
        border-color: rgb(96, 96, 96);
    }
    .ant-transfer-list-search-action {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-transfer-list-search-action .anticon {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-transfer-list-search-action .anticon:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-transfer-list-header {
        color: rgba(224, 224, 224, 0.85);
        background-image: initial;
        background-color: rgb(8, 10, 11);
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-transfer-list-content {
        list-style-image: initial;
    }
    .ant-transfer-list-content-item-remove {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(206, 206, 206);
    }
    .ant-transfer-list-content-item-remove:focus,
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(90, 192, 255);
    }
    .ant-transfer-list-content-item-remove:active {
        color: rgb(96, 190, 255);
    }
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(90, 192, 255);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-color: rgb(13, 14, 15);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
        background-color: rgb(0, 26, 42);
    }
    .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-transfer-list-content-item-checked {
        background-color: rgb(0, 23, 38);
    }
    .ant-transfer-list-content-item-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-transfer-list-pagination {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-transfer-list-body-not-found {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-transfer-list-footer {
        border-top-color: rgb(96, 96, 96);
    }
    .ant-select-tree-checkbox {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
        border-color: rgb(0, 96, 194);
    }
    .ant-select-tree-checkbox-checked::after {
        border-color: rgb(0, 96, 194);
    }
    .ant-select-tree-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
    }
    .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96) !important;
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        border-color: rgb(96, 96, 96);
    }
    .ant-select-tree-checkbox-disabled + span {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-tree-checkbox-wrapper {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-group {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
        background-color: rgb(0, 60, 124);
        border-color: initial;
    }
    .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-select-tree {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-select-tree .ant-select-tree-treenode {
        outline-color: initial;
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select-tree .ant-select-tree-treenode.filter-node .ant-select-tree-title {
        color: inherit;
    }
    .ant-select-tree-switcher-loading-icon {
        color: rgb(71, 183, 255);
    }
    .ant-select-tree-switcher-leaf-line::before {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-select-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: rgb(13, 14, 15);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
        background-color: rgb(23, 24, 25); 
    }
    .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 60, 124); 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 96, 194);
    }
    .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 60, 124) 0px 0px 0px 2px;
    }
    .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-select-tree-show-line .ant-select-tree-switcher {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background-image: initial;
        background-color: rgb(0, 60, 124);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
        color: rgb(224, 224, 224);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
        color: rgb(224, 224, 224);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree-checkbox {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: rgb(0, 96, 194);
    }
    .ant-tree-checkbox-checked::after {
        border-color: rgb(0, 96, 194);
    }
    .ant-tree-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-tree-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-right-color: rgb(95, 95, 95);
        border-bottom-color: rgb(95, 95, 95);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: rgb(0, 60, 124);
        border-color: rgb(0, 96, 194);
    }
    .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(96, 96, 96) !important;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        border-color: rgb(96, 96, 96);
    }
    .ant-tree-checkbox-disabled + span {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-tree-checkbox-wrapper {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-group {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
        background-color: rgb(8, 10, 11);
        border-color: rgb(96, 96, 96);
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: rgb(0, 60, 124);
        border-color: initial;
    }
    .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(103, 103, 103, 0.25);
    }
    .ant-tree {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 23, 38);
    }
    .ant-tree .ant-tree-treenode {
        outline-color: initial;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tree .ant-tree-treenode.filter-node .ant-tree-title {
        color: inherit;
    }
    .ant-tree-switcher-loading-icon {
        color: rgb(71, 183, 255);
    }
    .ant-tree-switcher-leaf-line::before {
        border-left-color: rgb(96, 96, 96);
    }
    .ant-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(96, 96, 96);
    }
    .ant-tree .ant-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: rgb(13, 14, 15);
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgb(23, 24, 25); 
    }
    .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 60, 124); 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 96, 194);
    }
    .ant-tree .ant-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 60, 124) 0px 0px 0px 2px;
    }
    .ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: rgb(96, 96, 96);
    }
    .ant-tree-show-line .ant-tree-switcher {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: initial;
        border-left-color: rgb(96, 96, 96);
    }
    .ant-typography {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-typography.ant-typography-secondary {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-typography.ant-typography-success {
        color: rgb(145, 244, 98);
    }
    .ant-typography.ant-typography-warning {
        color: rgb(255, 199, 71);
    }
    .ant-typography.ant-typography-danger {
        color: rgb(255, 96, 98);
    }
    a.ant-typography.ant-typography-danger:active,
    a.ant-typography.ant-typography-danger:focus,
    a.ant-typography.ant-typography-danger:hover {
        color: rgb(255, 119, 115);
    }
    .ant-typography.ant-typography-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    h1.ant-typography,
    .ant-typography h1 {
        color: rgba(224, 224, 224, 0.85);
    }
    h2.ant-typography,
    .ant-typography h2 {
        color: rgba(224, 224, 224, 0.85);
    }
    h3.ant-typography,
    .ant-typography h3 {
        color: rgba(224, 224, 224, 0.85);
    }
    h4.ant-typography,
    .ant-typography h4 {
        color: rgba(224, 224, 224, 0.85);
    }
    h5.ant-typography,
    .ant-typography h5 {
        color: rgba(224, 224, 224, 0.85);
    }
    a.ant-typography,
    .ant-typography a {
        color: rgb(71, 183, 255);
        outline-color: initial;
        text-decoration-color: initial;
    }
    a.ant-typography:focus,
    .ant-typography a:focus,
    a.ant-typography:hover,
    .ant-typography a:hover {
        color: rgb(90, 192, 255);
    }
    a.ant-typography:active,
    .ant-typography a:active {
        color: rgb(96, 190, 255);
    }
    a.ant-typography:active,
    .ant-typography a:active,
    a.ant-typography:hover,
    .ant-typography a:hover {
        text-decoration-color: initial;
    }
    a.ant-typography[disabled],
    .ant-typography a[disabled],
    a.ant-typography.ant-typography-disabled,
    .ant-typography a.ant-typography-disabled {
        color: rgba(224, 224, 224, 0.25);
    }
    a.ant-typography[disabled]:active,
    .ant-typography a[disabled]:active,
    a.ant-typography.ant-typography-disabled:active,
    .ant-typography a.ant-typography-disabled:active,
    a.ant-typography[disabled]:hover,
    .ant-typography a[disabled]:hover,
    a.ant-typography.ant-typography-disabled:hover,
    .ant-typography a.ant-typography-disabled:hover {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-typography code {
        background-image: initial;
        background-color: rgba(51, 55, 57, 0.1);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography kbd {
        background-image: initial;
        background-color: rgba(51, 55, 57, 0.06);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography mark {
        background-color: rgb(74, 56, 0);
    }
    .ant-typography u,
    .ant-typography ins {
        text-decoration-color: initial;
    }
    .ant-typography s,
    .ant-typography del {
        text-decoration-color: initial;
    }
    .ant-typography-expand,
    .ant-typography-edit,
    .ant-typography-copy {
        color: rgb(71, 183, 255);
        text-decoration-color: initial;
        outline-color: initial;
    }
    .ant-typography-expand:focus,
    .ant-typography-edit:focus,
    .ant-typography-copy:focus,
    .ant-typography-expand:hover,
    .ant-typography-edit:hover,
    .ant-typography-copy:hover {
        color: rgb(90, 192, 255);
    }
    .ant-typography-expand:active,
    .ant-typography-edit:active,
    .ant-typography-copy:active {
        color: rgb(96, 190, 255);
    }
    .ant-typography-copy-success,
    .ant-typography-copy-success:hover,
    .ant-typography-copy-success:focus {
        color: rgb(145, 244, 98);
    }
    .ant-typography-edit-content-confirm {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-typography pre {
        background-image: initial;
        background-color: rgba(51, 55, 57, 0.1);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography pre code {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-typography blockquote {
        border-left-color: rgba(100, 100, 100, 0.2);
    }
    .ant-upload {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-upload-btn {
        outline-color: initial;
    }
    .ant-upload.ant-upload-select-picture-card {
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
    }
    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(0, 96, 194);
    }
    .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(96, 96, 96);
    }
    .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(10, 12, 13);
        border-color: rgb(96, 96, 96);
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: rgb(0, 94, 198);
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: rgb(0, 103, 193);
    }
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: rgb(90, 192, 255);
    }
    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload.ant-upload-drag .anticon-plus {
        color: rgba(224, 224, 224, 0.25);
    }
    .ant-upload.ant-upload-drag .anticon-plus:hover {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload.ant-upload-drag:hover .anticon-plus {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload-list {
        color: rgba(224, 224, 224, 0.85);
        list-style-image: initial;
    }
    .ant-upload-list-item-card-actions .anticon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload-list-item-info .anticon-loading .anticon,
    .ant-upload-list-item-info .ant-upload-text-icon .anticon {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload-list-item .anticon-close {
        color: rgba(224, 224, 224, 0.45);
    }
    .ant-upload-list-item .anticon-close:hover {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: rgb(13, 14, 15);
    }
    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
    .ant-upload-list-item-error .ant-upload-list-item-name {
        color: rgb(255, 96, 98);
    }
    .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border-color: rgb(96, 96, 96);
    }
    .ant-upload-list-picture .ant-upload-list-item:hover,
    .ant-upload-list-picture-card .ant-upload-list-item:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-color: rgb(192, 0, 0); 
    }
    .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-image: initial; background-color: transparent; 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
        fill: rgb(255, 188, 175); 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
        fill: rgb(255, 96, 98);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
        color: rgba(224, 224, 224, 0.85);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
        color: rgb(224, 224, 224);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        background-color: rgb(10, 12, 13); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
        border-color: transparent; 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: rgb(98, 98, 98); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-top-color: initial;
        border-bottom-color: rgb(96, 96, 96); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: rgb(98, 98, 98); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: initial;
        border-top-color: rgb(95, 95, 95); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-top-color: rgb(98, 98, 98);
    }
    .react-datepicker-wrapper {
        border-color: initial;
    }
    .react-datepicker {
        background-color: rgb(8, 10, 11);
        color: rgb(224, 224, 224);
        border-color: rgb(98, 98, 98);
    }
    .react-datepicker--time-only .react-datepicker__time-container {
        border-left-color: initial;
    }
    .react-datepicker__header {
        background-color: rgb(15, 16, 17);
        border-bottom-color: rgb(98, 98, 98);
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: rgb(224, 224, 224);
    }
    .react-datepicker__navigation {
        background-image: none;
        background-color: initial;
        border-color: transparent;
    }
    .react-datepicker__navigation--previous {
        border-right-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(96, 96, 96);
    }
    .react-datepicker__navigation--next {
        border-left-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--next:hover {
        border-left-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--next--disabled,
    .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(96, 96, 96);
    }
    .react-datepicker__navigation--years-previous {
        border-top-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--years-previous:hover {
        border-top-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(97, 97, 97);
    }
    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: rgb(97, 97, 97);
    }
    .react-datepicker__time-container {
        border-left-color: rgb(98, 98, 98);
    }
    .react-datepicker__time-container--with-today-button {
        border-color: rgb(98, 98, 98);
    }
    .react-datepicker__time-container .react-datepicker__time {
        background-image: initial;
        background-color: rgb(8, 10, 11);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style-image: initial;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        background-color: rgb(15, 16, 17);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: rgb(10, 52, 84);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
        background-color: rgb(10, 52, 84);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        color: rgb(199, 199, 199);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__week-number {
        color: rgb(199, 199, 199);
    }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
        background-color: rgb(15, 16, 17);
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: rgb(224, 224, 224);
    }
    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
        background-color: rgb(10, 52, 84);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__month--selected:hover,
    .react-datepicker__month--in-selecting-range:hover,
    .react-datepicker__month--in-range:hover,
    .react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter--in-selecting-range:hover,
    .react-datepicker__quarter--in-range:hover {
        background-color: rgb(7, 43, 72);
    }
    .react-datepicker__month--disabled,
    .react-datepicker__quarter--disabled {
        color: rgb(199, 199, 199);
    }
    .react-datepicker__month--disabled:hover,
    .react-datepicker__quarter--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background-color: rgb(15, 16, 17);
    }
    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        background-color: rgb(20, 102, 46);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
        background-color: rgb(19, 99, 26);
    }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
        color: rgb(255, 60, 255);
    }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
        color: rgb(119, 255, 119);
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: rgb(10, 52, 84);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: rgb(7, 43, 72);
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: rgb(15, 67, 107);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: rgb(7, 43, 72);
    }
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        background-color: rgba(10, 52, 84, 0.5);
    }
    .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
        background-color: rgb(15, 16, 17);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        color: rgb(199, 199, 199);
    }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month-text.react-datepicker__month--in-range:hover,
    .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
        background-color: rgb(10, 52, 84);
    }
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
        background-color: rgb(15, 16, 17);
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
        border-color: transparent;
    }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
        border-top-color: rgb(97, 97, 97);
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-top-color: rgb(97, 97, 97);
    }
    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: rgb(15, 16, 17);
        border-color: rgb(98, 98, 98);
    }
    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: rgb(29, 31, 32);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(97, 97, 97);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
        border-top-color: rgb(97, 97, 97);
    }
    .react-datepicker__close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .react-datepicker__close-icon::after {
        background-color: rgb(10, 52, 84);
        color: rgb(224, 224, 224);
    }
    .react-datepicker__today-button {
        background-image: initial;
        background-color: rgb(15, 16, 17);
        border-top-color: rgb(98, 98, 98);
    }
    .react-datepicker__portal {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .react-datepicker__portal .react-datepicker__navigation {
        border-color: transparent;
    }
    .react-datepicker__portal .react-datepicker__navigation--previous {
        border-right-color: rgb(97, 97, 97);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(97, 97, 97);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(96, 96, 96);
    }
    .react-datepicker__portal .react-datepicker__navigation--next {
        border-left-color: rgb(97, 97, 97);
    }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
        border-left-color: rgb(97, 97, 97);
    }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled,
    .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(96, 96, 96);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-track,
    .ReactQueryDevtoolsPanel scrollbar-track {
        background-image: initial;
        background-color: rgb(2, 11, 22);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-thumb,
    .ReactQueryDevtoolsPanel scrollbar-thumb {
        background-image: initial;
        background-color: rgb(26, 35, 46);
        border-color: rgb(102, 102, 102);
    }
    .vimvixen-hint {
        background-color: rgb(78, 49, 0) !important;
        border-color: rgb(197, 158, 7) !important;
        color: rgb(249, 233, 189) !important;
    }
    #edge-translate-panel-body {
        color: var(--darkreader-text--darkreader-text--darkreader-neutral-text) !important; 
    }
    .clear-focus:focus {
        outline-color: initial !important; box-shadow: none !important; 
    }
    [tabindex="-1"]:focus {
        outline-color: initial !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: rgba(241, 241, 241, 0.85);
    }
    abbr[title],
    abbr[data-original-title] {
        text-decoration-color: initial;
        border-bottom-color: initial;
    }
    a {
        color: rgb(58, 175, 255);
        text-decoration-color: initial;
        background-color: transparent;
        outline-color: initial;
    }
    a:hover {
        color: rgb(85, 190, 255);
    }
    a:active {
        color: rgb(91, 186, 255);
    }
    a:active,
    a:hover {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a:focus {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a[disabled] {
        color: rgba(241, 241, 241, 0.25);
    }
    caption {
        color: rgba(241, 241, 241, 0.45);
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        color: inherit;
    }
    fieldset {
        border-color: initial;
    }
    legend {
        color: inherit;
    }
    mark {
        background-color: rgb(47, 49, 0);
    }
    ::selection {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158); 
    }
    .anticon {
        color: inherit; 
    }
    html {
        --darkreader-bg--darkreader-bg--antd-wave-shadow-color: #004e9e; --scroll-bar: 0; 
    }
    [ant-click-animating-without-extra-node="true"]::after,
    .ant-click-animating-node {
        box-shadow: 0 0 0 0 var(--darkreader-bg--darkreader-bg--antd-wave-shadow-color);
    }
    .ant-alert {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-alert-success {
        background-color: rgb(26, 46, 0);
        border-color: rgb(98, 198, 20);
    }
    .ant-alert-success .ant-alert-icon {
        color: rgb(139, 243, 89);
    }
    .ant-alert-info {
        background-color: rgb(0, 30, 49);
        border-color: rgb(0, 125, 216);
    }
    .ant-alert-info .ant-alert-icon {
        color: rgb(58, 175, 255);
    }
    .ant-alert-warning {
        background-color: rgb(49, 39, 0);
        border-color: rgb(216, 161, 0);
    }
    .ant-alert-warning .ant-alert-icon {
        color: rgb(255, 193, 57);
    }
    .ant-alert-error {
        background-color: rgb(44, 0, 0);
        border-color: rgb(222, 4, 0);
    }
    .ant-alert-error .ant-alert-icon {
        color: rgb(255, 94, 96);
    }
    .ant-alert-close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-alert-close-icon .anticon-close {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-alert-close-icon .anticon-close:hover {
        color: rgba(241, 241, 241, 0.75);
    }
    .ant-alert-close-text {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-alert-close-text:hover {
        color: rgba(241, 241, 241, 0.75);
    }
    .ant-alert-with-description .ant-alert-message {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-alert-message {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-alert-banner {
        border-color: initial;
    }
    .ant-anchor {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-anchor-wrapper {
        background-color: rgb(13, 14, 15);
    }
    .ant-anchor-ink::before {
        background-color: rgb(20, 22, 23);
    }
    .ant-anchor-ink-ball {
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 101, 205);
    }
    .ant-anchor-link-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
        color: rgb(58, 175, 255);
    }
    .ant-select-auto-complete {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: rgb(198, 198, 198);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-select-multiple .ant-select-selection-item {
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(114, 114, 114);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: rgb(198, 198, 198);
        border-color: rgb(111, 111, 111);
    }
    .ant-select-multiple .ant-select-selection-item-remove {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-select-multiple .ant-select-selection-item-remove:hover {
        color: rgba(241, 241, 241, 0.75);
    }
    .ant-select {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: rgb(0, 109, 208);
    }
    .ant-select-selection-placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-select-arrow {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-clear {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select-clear:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-select-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-select-dropdown-empty {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-item-empty {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-item {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-select-item-group {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgb(18, 19, 20);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(0, 30, 49);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
        color: rgb(58, 175, 255);
    }
    .ant-select-item-option-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-borderless .ant-select-selector {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-empty-normal {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-empty-small {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-empty-img-default-ellipse {
        fill: rgb(234, 234, 234);
    }
    .ant-empty-img-default-path-1 {
        fill: rgb(193, 193, 193);
    }
    .ant-empty-img-default-path-3 {
        fill: rgb(235, 235, 235);
    }
    .ant-empty-img-default-path-4 {
        fill: rgb(221, 221, 221);
    }
    .ant-empty-img-default-path-5 {
        fill: rgb(221, 221, 221);
    }
    .ant-empty-img-default-g {
        fill: rgb(241, 241, 241);
    }
    .ant-empty-img-simple-ellipse {
        fill: rgb(234, 234, 234);
    }
    .ant-empty-img-simple-g {
        stroke: rgb(216, 216, 216);
    }
    .ant-empty-img-simple-path {
        fill: rgb(238, 238, 238);
    }
    .ant-avatar {
        list-style-image: initial;
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(38, 41, 43);
    }
    .ant-avatar-image {
        background-image: initial;
        background-color: transparent;
    }
    .ant-avatar-group .ant-avatar {
        border-color: rgb(115, 115, 115);
    }
    .ant-popover {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-popover::after {
        background-image: initial;
        background-color: rgba(13, 14, 15, 0.01);
    }
    .ant-popover-inner {
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-popover-title {
        color: rgba(241, 241, 241, 0.85);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-popover-inner-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-popover-message {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-popover-message > .anticon {
        color: rgb(255, 193, 57);
    }
    .ant-popover-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent rgb(115, 115, 115) rgb(115, 115, 115) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent transparent rgb(115, 115, 115) rgb(115, 115, 115);
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(115, 115, 115) transparent transparent rgb(115, 115, 115);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(115, 115, 115) rgb(115, 115, 115) transparent transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-back-top {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-back-top-content {
        color: rgb(241, 241, 241);
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-back-top-content:hover {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-badge {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-badge-count {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(129, 0, 0);
        box-shadow: rgb(13, 14, 15) 0px 0px 0px 1px;
    }
    .ant-badge-count a,
    .ant-badge-count a:hover {
        color: rgb(241, 241, 241);
    }
    .ant-badge-dot {
        background-image: initial;
        background-color: rgb(129, 0, 0);
        box-shadow: rgb(13, 14, 15) 0px 0px 0px 1px;
    }
    .ant-badge-status-success {
        background-color: rgb(49, 128, 10);
    }
    .ant-badge-status-processing {
        background-color: rgb(0, 78, 158);
    }
    .ant-badge-status-processing::after {
        border-color: rgb(0, 101, 205);
    }
    .ant-badge-status-default {
        background-color: rgb(31, 34, 35);
    }
    .ant-badge-status-error {
        background-color: rgb(129, 0, 0);
    }
    .ant-badge-status-warning {
        background-color: rgb(159, 103, 0);
    }
    .ant-badge-status-pink {
        background-image: initial;
        background-color: rgb(140, 6, 80);
    }
    .ant-badge-status-magenta {
        background-image: initial;
        background-color: rgb(140, 6, 80);
    }
    .ant-badge-status-red {
        background-image: initial;
        background-color: rgb(149, 0, 6);
    }
    .ant-badge-status-volcano {
        background-image: initial;
        background-color: rgb(155, 35, 0);
    }
    .ant-badge-status-orange {
        background-image: initial;
        background-color: rgb(158, 79, 0);
    }
    .ant-badge-status-yellow {
        background-image: initial;
        background-color: rgb(159, 136, 0);
    }
    .ant-badge-status-gold {
        background-image: initial;
        background-color: rgb(159, 103, 0);
    }
    .ant-badge-status-cyan {
        background-image: initial;
        background-color: rgb(5, 127, 127);
    }
    .ant-badge-status-lime {
        background-image: initial;
        background-color: rgb(103, 143, 4);
    }
    .ant-badge-status-green {
        background-image: initial;
        background-color: rgb(49, 128, 10);
    }
    .ant-badge-status-blue {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-badge-status-geekblue {
        background-image: initial;
        background-color: rgb(6, 33, 140);
    }
    .ant-badge-status-purple {
        background-image: initial;
        background-color: rgb(71, 24, 137);
    }
    .ant-badge-status-text {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-ribbon {
        list-style-image: initial;
        color: rgb(241, 241, 241);
        background-color: rgb(0, 78, 158);
    }
    .ant-ribbon-text {
        color: rgb(241, 241, 241);
    }
    .ant-ribbon-corner {
        color: currentcolor;
        border-color: initial;
    }
    .ant-ribbon-corner::after {
        color: rgba(241, 241, 241, 0.25);
        border-color: inherit;
    }
    .ant-ribbon-color-pink {
        color: rgb(249, 79, 172);
        background-image: initial;
        background-color: rgb(140, 6, 80);
    }
    .ant-ribbon-color-magenta {
        color: rgb(249, 79, 172);
        background-image: initial;
        background-color: rgb(140, 6, 80);
    }
    .ant-ribbon-color-red {
        color: rgb(255, 68, 78);
        background-image: initial;
        background-color: rgb(149, 0, 6);
    }
    .ant-ribbon-color-volcano {
        color: rgb(255, 113, 62);
        background-image: initial;
        background-color: rgb(155, 35, 0);
    }
    .ant-ribbon-color-orange {
        color: rgb(255, 164, 58);
        background-image: initial;
        background-color: rgb(158, 79, 0);
    }
    .ant-ribbon-color-yellow {
        color: rgb(255, 235, 57);
        background-image: initial;
        background-color: rgb(159, 136, 0);
    }
    .ant-ribbon-color-gold {
        color: rgb(255, 193, 57);
        background-image: initial;
        background-color: rgb(159, 103, 0);
    }
    .ant-ribbon-color-cyan {
        color: rgb(89, 249, 249);
        background-image: initial;
        background-color: rgb(5, 127, 127);
    }
    .ant-ribbon-color-lime {
        color: rgb(200, 251, 72);
        background-image: initial;
        background-color: rgb(103, 143, 4);
    }
    .ant-ribbon-color-green {
        color: rgb(139, 243, 89);
        background-image: initial;
        background-color: rgb(49, 128, 10);
    }
    .ant-ribbon-color-blue {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-ribbon-color-geekblue {
        color: rgb(79, 169, 249);
        background-image: initial;
        background-color: rgb(6, 33, 140);
    }
    .ant-ribbon-color-purple {
        color: rgb(142, 82, 226);
        background-image: initial;
        background-color: rgb(71, 24, 137);
    }
    .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-breadcrumb {
        list-style-image: initial;
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-breadcrumb a {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-breadcrumb a:hover {
        color: rgb(85, 190, 255);
    }
    .ant-breadcrumb > span:last-child {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-breadcrumb > span:last-child a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-breadcrumb-separator {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-menu-item-danger.ant-menu-item {
        color: rgb(255, 94, 96);
    }
    .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-item-danger.ant-menu-item-active {
        color: rgb(255, 94, 96);
    }
    .ant-menu-item-danger.ant-menu-item:active {
        background-image: initial;
        background-color: rgb(44, 0, 0);
    }
    .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(255, 94, 96);
    }
    .ant-menu-item-danger.ant-menu-item-selected > a,
    .ant-menu-item-danger.ant-menu-item-selected > a:hover {
        color: rgb(255, 94, 96);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        background-color: rgb(44, 0, 0);
    }
    .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
        border-right-color: rgb(210, 0, 0);
    }
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
        color: rgb(255, 94, 96);
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(241, 241, 241);
        background-color: rgb(129, 0, 0);
    }
    .ant-menu {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-menu ul,
    .ant-menu ol {
        list-style-image: initial;
    }
    .ant-menu-item-group-title {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-menu-submenu-selected {
        color: rgb(58, 175, 255);
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-menu-item a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-menu-item a:hover {
        color: rgb(58, 175, 255);
    }
    .ant-menu-item a::before {
        background-color: transparent;
    }
    .ant-menu-item > .ant-badge a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-menu-item > .ant-badge a:hover {
        color: rgb(58, 175, 255);
    }
    .ant-menu-item-divider {
        background-color: rgb(20, 22, 23);
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: rgb(58, 175, 255);
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
        background-color: transparent;
    }
    .ant-menu-item-selected {
        color: rgb(58, 175, 255);
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        color: rgb(58, 175, 255);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 30, 49);
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-menu-vertical-right {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-menu-vertical.ant-menu-sub,
    .ant-menu-vertical-left.ant-menu-sub,
    .ant-menu-vertical-right.ant-menu-sub {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu > .ant-menu-item-divider {
        background-color: rgb(20, 22, 23);
    }
    .ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-menu-submenu > .ant-menu {
        background-color: rgb(13, 14, 15);
    }
    .ant-menu-submenu-popup > .ant-menu {
        background-color: rgb(13, 14, 15);
    }
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
        background-color: currentcolor;
    }
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: rgb(58, 175, 255);
    }
    .ant-menu-vertical .ant-menu-submenu-selected,
    .ant-menu-vertical-left .ant-menu-submenu-selected,
    .ant-menu-vertical-right .ant-menu-submenu-selected {
        color: rgb(58, 175, 255);
    }
    .ant-menu-horizontal {
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(114, 114, 114);
        box-shadow: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: rgb(58, 175, 255);
        border-bottom-color: rgb(0, 101, 205);
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border-bottom-color: transparent;
    }
    .ant-menu-horizontal > .ant-menu-item a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-menu-horizontal > .ant-menu-item a:hover {
        color: rgb(58, 175, 255);
    }
    .ant-menu-horizontal > .ant-menu-item-selected a {
        color: rgb(58, 175, 255);
    }
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right-color: rgb(0, 101, 205);
    }
    .ant-menu-inline-collapsed-tooltip a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
        box-shadow: none;
    }
    .ant-menu-sub.ant-menu-inline {
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-color: initial;
        box-shadow: none;
    }
    .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
        background-image: none;
        background-color: initial;
        color: rgba(241, 241, 241, 0.25) !important;
        border-color: transparent !important;
    }
    .ant-menu-item-disabled a,
    .ant-menu-submenu-disabled a {
        color: rgba(241, 241, 241, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(241, 241, 241, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: rgba(241, 241, 241, 0.65);
        background-image: initial;
        background-color: rgb(0, 6, 20);
    }
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-menu-dark.ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background-image: initial;
        background-color: rgb(0, 1, 7);
    }
    .ant-menu-dark.ant-menu-horizontal {
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
        border-top-color: rgb(92, 92, 92);
        border-right-color: rgb(92, 92, 92);
        border-left-color: rgb(92, 92, 92);
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: rgb(0, 78, 158);
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a {
        color: rgba(241, 241, 241, 0.65);
    }
    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
        color: rgb(241, 241, 241);
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-item:hover > a,
    .ant-menu-dark .ant-menu-item-active > a,
    .ant-menu-dark .ant-menu-submenu-active > a,
    .ant-menu-dark .ant-menu-submenu-open > a,
    .ant-menu-dark .ant-menu-submenu-selected > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > a,
    .ant-menu-dark .ant-menu-item:hover > span > a,
    .ant-menu-dark .ant-menu-item-active > span > a,
    .ant-menu-dark .ant-menu-submenu-active > span > a,
    .ant-menu-dark .ant-menu-submenu-open > span > a,
    .ant-menu-dark .ant-menu-submenu-selected > span > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
        color: rgb(241, 241, 241);
    }
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-menu-dark .ant-menu-item:hover {
        background-color: transparent;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 78, 158);
    }
    .ant-menu-dark .ant-menu-item-selected {
        color: rgb(241, 241, 241);
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item-selected > span > a,
    .ant-menu-dark .ant-menu-item-selected > a:hover,
    .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: rgb(241, 241, 241);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
    .ant-menu-dark .ant-menu-item-selected .anticon {
        color: rgb(241, 241, 241);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
    .ant-menu-dark .ant-menu-item-selected .anticon + span {
        color: rgb(241, 241, 241);
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: rgb(0, 78, 158);
    }
    .ant-menu-dark .ant-menu-item-disabled,
    .ant-menu-dark .ant-menu-submenu-disabled,
    .ant-menu-dark .ant-menu-item-disabled > a,
    .ant-menu-dark .ant-menu-submenu-disabled > a,
    .ant-menu-dark .ant-menu-item-disabled > span > a,
    .ant-menu-dark .ant-menu-submenu-disabled > span > a {
        color: rgba(241, 241, 241, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(241, 241, 241, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(13, 14, 15, 0.35) !important;
    }
    .ant-menu-rtl.ant-menu-inline,
    .ant-menu-rtl.ant-menu-vertical {
        border-right-color: initial;
        border-left-color: rgb(114, 114, 114);
    }
    .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
    .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
        border-left-color: initial;
    }
    .ant-menu-sub.ant-menu-inline {
        border-color: initial;
    }
    .ant-tooltip {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-tooltip-inner {
        color: rgb(241, 241, 241);
        text-decoration-color: initial;
        background-color: rgba(0, 0, 0, 0.75);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tooltip-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tooltip-arrow-content {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .ant-tooltip-placement-top .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-tooltip-placement-right .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-tooltip-placement-left .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px -3px 7px;
    }
    .ant-tooltip-pink .ant-tooltip-inner {
        background-color: rgb(140, 6, 80);
    }
    .ant-tooltip-pink .ant-tooltip-arrow-content {
        background-color: rgb(140, 6, 80);
    }
    .ant-tooltip-magenta .ant-tooltip-inner {
        background-color: rgb(140, 6, 80);
    }
    .ant-tooltip-magenta .ant-tooltip-arrow-content {
        background-color: rgb(140, 6, 80);
    }
    .ant-tooltip-red .ant-tooltip-inner {
        background-color: rgb(149, 0, 6);
    }
    .ant-tooltip-red .ant-tooltip-arrow-content {
        background-color: rgb(149, 0, 6);
    }
    .ant-tooltip-volcano .ant-tooltip-inner {
        background-color: rgb(155, 35, 0);
    }
    .ant-tooltip-volcano .ant-tooltip-arrow-content {
        background-color: rgb(155, 35, 0);
    }
    .ant-tooltip-orange .ant-tooltip-inner {
        background-color: rgb(158, 79, 0);
    }
    .ant-tooltip-orange .ant-tooltip-arrow-content {
        background-color: rgb(158, 79, 0);
    }
    .ant-tooltip-yellow .ant-tooltip-inner {
        background-color: rgb(159, 136, 0);
    }
    .ant-tooltip-yellow .ant-tooltip-arrow-content {
        background-color: rgb(159, 136, 0);
    }
    .ant-tooltip-gold .ant-tooltip-inner {
        background-color: rgb(159, 103, 0);
    }
    .ant-tooltip-gold .ant-tooltip-arrow-content {
        background-color: rgb(159, 103, 0);
    }
    .ant-tooltip-cyan .ant-tooltip-inner {
        background-color: rgb(5, 127, 127);
    }
    .ant-tooltip-cyan .ant-tooltip-arrow-content {
        background-color: rgb(5, 127, 127);
    }
    .ant-tooltip-lime .ant-tooltip-inner {
        background-color: rgb(103, 143, 4);
    }
    .ant-tooltip-lime .ant-tooltip-arrow-content {
        background-color: rgb(103, 143, 4);
    }
    .ant-tooltip-green .ant-tooltip-inner {
        background-color: rgb(49, 128, 10);
    }
    .ant-tooltip-green .ant-tooltip-arrow-content {
        background-color: rgb(49, 128, 10);
    }
    .ant-tooltip-blue .ant-tooltip-inner {
        background-color: rgb(0, 78, 158);
    }
    .ant-tooltip-blue .ant-tooltip-arrow-content {
        background-color: rgb(0, 78, 158);
    }
    .ant-tooltip-geekblue .ant-tooltip-inner {
        background-color: rgb(6, 33, 140);
    }
    .ant-tooltip-geekblue .ant-tooltip-arrow-content {
        background-color: rgb(6, 33, 140);
    }
    .ant-tooltip-purple .ant-tooltip-inner {
        background-color: rgb(71, 24, 137);
    }
    .ant-tooltip-purple .ant-tooltip-arrow-content {
        background-color: rgb(71, 24, 137);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
        color: rgb(255, 94, 96);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
        color: rgb(241, 241, 241);
        background-color: rgb(129, 0, 0);
    }
    .ant-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-dropdown-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
        border-color: transparent rgb(115, 115, 115) rgb(115, 115, 115) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
        border-color: rgb(115, 115, 115) transparent transparent rgb(115, 115, 115);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-dropdown-menu {
        background-color: rgb(13, 14, 15);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-dropdown-menu-item-group-title {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-dropdown-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-dropdown-menu-submenu-popup ul,
    .ant-dropdown-menu-submenu-popup li {
        list-style-image: initial;
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-submenu-title > a:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a,
    .ant-dropdown-menu-submenu-title > .anticon + span > a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a:hover,
    .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
        color: rgb(58, 175, 255);
        background-color: rgb(0, 30, 49);
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: rgb(18, 19, 20);
    }
    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-dropdown-menu-item-disabled:hover,
    .ant-dropdown-menu-submenu-title-disabled:hover {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-dropdown-menu-item-disabled > .anticon + span > a,
    .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
    .ant-dropdown-menu-item-disabled > a,
    .ant-dropdown-menu-submenu-title-disabled > a {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-dropdown-menu-item-divider,
    .ant-dropdown-menu-submenu-title-divider {
        background-color: rgb(20, 22, 23);
    }
    .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
    .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-dropdown-menu-item-group-list {
        list-style-image: initial;
    }
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: rgb(58, 175, 255);
    }
    .ant-dropdown-menu-dark,
    .ant-dropdown-menu-dark .ant-dropdown-menu {
        background-image: initial;
        background-color: rgb(0, 6, 20);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
        color: rgba(241, 241, 241, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
        color: rgba(241, 241, 241, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-btn {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-btn,
    .ant-btn:active,
    .ant-btn:focus {
        outline-color: initial;
    }
    .ant-btn:not([disabled]):hover {
        text-decoration-color: initial;
    }
    .ant-btn:not([disabled]):active {
        outline-color: initial;
        box-shadow: none;
    }
    .ant-btn > a:only-child {
        color: currentcolor;
    }
    .ant-btn > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 109, 208);
    }
    .ant-btn:hover > a:only-child,
    .ant-btn:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn:hover > a:only-child::after,
    .ant-btn:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 95, 202);
    }
    .ant-btn:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn[disabled] > a:only-child,
    .ant-btn[disabled]:hover > a:only-child,
    .ant-btn[disabled]:focus > a:only-child,
    .ant-btn[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn[disabled] > a:only-child::after,
    .ant-btn[disabled]:hover > a:only-child::after,
    .ant-btn[disabled]:focus > a:only-child::after,
    .ant-btn[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        text-decoration-color: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-btn-primary {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 71, 136);
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-primary:hover > a:only-child,
    .ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:hover > a:only-child::after,
    .ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:active {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 68, 143);
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-primary[disabled] > a:only-child,
    .ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
        border-right-color: rgb(0, 109, 208);
        border-left-color: rgb(0, 109, 208);
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
        border-color: rgb(111, 111, 111);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
        border-right-color: rgb(0, 109, 208);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
        border-right-color: rgb(111, 111, 111);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: rgb(0, 109, 208);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-left-color: rgb(111, 111, 111);
    }
    .ant-btn-ghost {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(111, 111, 111);
    }
    .ant-btn-ghost > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:hover,
    .ant-btn-ghost:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-ghost:hover > a:only-child,
    .ant-btn-ghost:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:hover > a:only-child::after,
    .ant-btn-ghost:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-ghost:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost[disabled],
    .ant-btn-ghost[disabled]:hover,
    .ant-btn-ghost[disabled]:focus,
    .ant-btn-ghost[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-ghost[disabled] > a:only-child,
    .ant-btn-ghost[disabled]:hover > a:only-child,
    .ant-btn-ghost[disabled]:focus > a:only-child,
    .ant-btn-ghost[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost[disabled] > a:only-child::after,
    .ant-btn-ghost[disabled]:hover > a:only-child::after,
    .ant-btn-ghost[disabled]:focus > a:only-child::after,
    .ant-btn-ghost[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-btn-dashed > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-dashed:hover > a:only-child,
    .ant-btn-dashed:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:hover > a:only-child::after,
    .ant-btn-dashed:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-dashed:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed[disabled],
    .ant-btn-dashed[disabled]:hover,
    .ant-btn-dashed[disabled]:focus,
    .ant-btn-dashed[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dashed[disabled] > a:only-child,
    .ant-btn-dashed[disabled]:hover > a:only-child,
    .ant-btn-dashed[disabled]:focus > a:only-child,
    .ant-btn-dashed[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed[disabled] > a:only-child::after,
    .ant-btn-dashed[disabled]:hover > a:only-child::after,
    .ant-btn-dashed[disabled]:focus > a:only-child::after,
    .ant-btn-dashed[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(129, 0, 0);
        border-color: rgb(210, 0, 0);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(109, 0, 0);
        border-color: rgb(214, 0, 0);
    }
    .ant-btn-danger:hover > a:only-child,
    .ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:hover > a:only-child::after,
    .ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:active {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(134, 18, 24);
        border-color: rgb(182, 25, 31);
    }
    .ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger[disabled],
    .ant-btn-danger[disabled]:hover,
    .ant-btn-danger[disabled]:focus,
    .ant-btn-danger[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-danger[disabled] > a:only-child,
    .ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-link:hover > a:only-child,
    .ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:hover > a:only-child::after,
    .ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus,
    .ant-btn-link:active {
        border-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-text:hover > a:only-child,
    .ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:hover > a:only-child::after,
    .ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-text:active {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:hover,
    .ant-btn-dangerous:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(214, 0, 0);
    }
    .ant-btn-dangerous:hover > a:only-child,
    .ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(182, 25, 31);
    }
    .ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous[disabled],
    .ant-btn-dangerous[disabled]:hover,
    .ant-btn-dangerous[disabled]:focus,
    .ant-btn-dangerous[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(129, 0, 0);
        border-color: rgb(210, 0, 0);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:hover,
    .ant-btn-dangerous.ant-btn-primary:focus {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(109, 0, 0);
        border-color: rgb(214, 0, 0);
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:active {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(134, 18, 24);
        border-color: rgb(182, 25, 31);
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled],
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
        border-left-color: transparent;
    }
    .ant-btn-background-ghost {
        color: rgb(241, 241, 241);
        border-color: rgb(115, 115, 115);
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-btn-background-ghost.ant-btn-primary {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 101, 205);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover,
    .ant-btn-background-ghost.ant-btn-primary:focus {
        color: rgb(85, 190, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 109, 208);
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:active {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 95, 202);
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled],
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(210, 0, 0);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover,
    .ant-btn-background-ghost.ant-btn-danger:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(214, 0, 0);
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(182, 25, 31);
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled],
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(210, 0, 0);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover,
    .ant-btn-background-ghost.ant-btn-dangerous:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(214, 0, 0);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(182, 25, 31);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 94, 96);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 126, 121);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
        color: rgb(232, 86, 94);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-right-color: rgb(0, 109, 208);
        border-left-color: rgb(111, 111, 111);
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-right-color: rgb(111, 111, 111);
        border-left-color: rgb(0, 109, 208);
    }
    .ant-picker-calendar {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-calendar .ant-picker-panel {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(114, 114, 114);
    }
    .ant-picker-calendar-full .ant-picker-panel {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: initial;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgb(58, 175, 255);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(114, 114, 114);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
        border-color: rgb(0, 101, 205);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-radio-group {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
        border-left-color: initial;
    }
    .ant-radio-wrapper {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-radio {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: rgba(0, 78, 158, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-checked::after {
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-radio-inner::after {
        background-color: rgb(0, 78, 158);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-disabled .ant-radio-inner {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111) !important;
    }
    .ant-radio-disabled .ant-radio-inner::after {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .ant-radio-disabled + span {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-radio-button-wrapper {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(31, 34, 35);
    }
    .ant-radio-button-wrapper:first-child {
        border-left-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper:hover {
        color: rgb(58, 175, 255);
    }
    .ant-radio-button-wrapper:focus-within {
        box-shadow: rgba(0, 78, 158, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: rgb(0, 78, 158);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(85, 190, 255);
        border-color: rgb(0, 109, 208);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: rgb(0, 71, 136);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(91, 186, 255);
        border-color: rgb(0, 95, 202);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
        background-color: rgb(0, 68, 143);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 78, 158, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 71, 136);
        border-color: rgb(0, 109, 208);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 68, 143);
        border-color: rgb(0, 95, 202);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 78, 158, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper-disabled:first-child,
    .ant-radio-button-wrapper-disabled:hover {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper-disabled:first-child {
        border-left-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(25, 27, 28);
        border-color: rgb(111, 111, 111);
        box-shadow: none;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
        border-right-color: rgb(111, 111, 111);
    }
    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-right-color: rgb(0, 109, 208);
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
        border-right-color: rgb(111, 111, 111);
    }
    .ant-picker {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border-color: rgb(0, 109, 208);
    }
    .ant-picker-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker.ant-picker-disabled {
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-picker.ant-picker-borderless {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-picker-input > input {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-input > input::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-picker-input > input:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-picker-input > input:focus,
    .ant-picker-input > input-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker-input > input-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-input > input-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-picker-input > input[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-input > input[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-picker-input > input-borderless,
    .ant-picker-input > input-borderless:hover,
    .ant-picker-input > input-borderless:focus,
    .ant-picker-input > input-borderless-focused,
    .ant-picker-input > input-borderless-disabled,
    .ant-picker-input > input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-picker-input > input:focus {
        box-shadow: none;
    }
    .ant-picker-input > input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-input-placeholder > input {
        color: rgb(198, 198, 198);
    }
    .ant-picker-suffix {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-picker-clear {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-picker-clear:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-picker-separator {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-picker-focused .ant-picker-separator {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-picker-range .ant-picker-active-bar {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-picker-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-picker-ranges {
        list-style-image: initial;
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: rgb(0, 30, 49);
        border-color: rgb(0, 125, 216);
    }
    .ant-picker-range-arrow {
        box-shadow: rgba(0, 0, 0, 0.06) 2px -2px 6px;
    }
    .ant-picker-range-arrow::after {
        border-color: rgb(115, 115, 115) rgb(115, 115, 115) transparent transparent;
    }
    .ant-picker-panel-container {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-picker-panel-container .ant-picker-panel {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-container .ant-picker-panel-focused {
        border-color: rgb(114, 114, 114);
    }
    .ant-picker-panel {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(114, 114, 114);
        outline-color: initial;
    }
    .ant-picker-panel-focused {
        border-color: rgb(0, 101, 205);
    }
    .ant-picker-header {
        color: rgba(241, 241, 241, 0.85);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-picker-header button {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-header > button:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-picker-header-view button {
        color: inherit;
    }
    .ant-picker-header-view button:hover {
        color: rgb(58, 175, 255);
    }
    .ant-picker-prev-icon::before,
    .ant-picker-next-icon::before,
    .ant-picker-super-prev-icon::before,
    .ant-picker-super-next-icon::before {
        border-color: currentcolor;
    }
    .ant-picker-super-prev-icon::after,
    .ant-picker-super-next-icon::after {
        border-color: currentcolor;
    }
    .ant-picker-content th {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-picker-cell {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-picker-cell-in-view {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(0, 101, 205);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
        border-top-color: rgb(0, 104, 214);
        border-bottom-color: rgb(0, 104, 214);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
        background-image: initial;
        background-color: rgb(26, 28, 29);
    }
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background-image: initial;
        background-color: rgb(26, 28, 29);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 104, 214);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 104, 214);
    }
    .ant-picker-cell-disabled .ant-picker-cell-inner {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-cell-disabled::before {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-footer {
        border-bottom-color: transparent;
    }
    .ant-picker-panel .ant-picker-footer {
        border-top-color: rgb(114, 114, 114);
    }
    .ant-picker-footer-extra:not(:last-child) {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-picker-today-btn {
        color: rgb(58, 175, 255);
    }
    .ant-picker-today-btn:hover {
        color: rgb(85, 190, 255);
    }
    .ant-picker-today-btn:active {
        color: rgb(91, 186, 255);
    }
    .ant-picker-today-btn.ant-picker-today-btn-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 104, 214);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 104, 214);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 104, 214);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-left-color: rgb(0, 104, 214);
    }
    .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-picker-week-panel-row:hover td {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row-selected:hover td {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
        color: rgba(241, 241, 241, 0.5);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(115, 115, 115);
    }
    .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
    .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
        color: rgb(241, 241, 241);
    }
    .ant-picker-datetime-panel .ant-picker-time-panel {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-picker-time-panel-column {
        list-style-image: initial;
    }
    .ant-picker-time-panel-column:not(:first-child) {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-picker-time-panel-column-active {
        background-image: initial;
        background-color: rgba(0, 30, 49, 0.2);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 104, 214);
        border-left-color: initial;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: initial;
        border-left-color: rgb(0, 104, 214);
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
        border-right-color: rgb(0, 104, 214);
        border-left-color: rgb(0, 104, 214);
    }
    .ant-tag {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-color: rgb(111, 111, 111);
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-tag-close-icon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-tag-close-icon:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-tag-has-color {
        border-color: transparent;
    }
    .ant-tag-has-color,
    .ant-tag-has-color a,
    .ant-tag-has-color a:hover,
    .ant-tag-has-color .anticon-close,
    .ant-tag-has-color .anticon-close:hover {
        color: rgb(241, 241, 241);
    }
    .ant-tag-checkable {
        background-color: transparent;
        border-color: transparent;
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: rgb(58, 175, 255);
    }
    .ant-tag-checkable:active,
    .ant-tag-checkable-checked {
        color: rgb(241, 241, 241);
    }
    .ant-tag-checkable-checked {
        background-color: rgb(0, 78, 158);
    }
    .ant-tag-checkable:active {
        background-color: rgb(0, 68, 143);
    }
    .ant-tag-pink {
        color: rgb(240, 89, 178);
        background-image: initial;
        background-color: rgb(44, 0, 13);
        border-color: rgb(219, 0, 88);
    }
    .ant-tag-pink-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(140, 6, 80);
        border-color: rgb(201, 8, 114);
    }
    .ant-tag-magenta {
        color: rgb(240, 89, 178);
        background-image: initial;
        background-color: rgb(44, 0, 13);
        border-color: rgb(219, 0, 88);
    }
    .ant-tag-magenta-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(140, 6, 80);
        border-color: rgb(201, 8, 114);
    }
    .ant-tag-red {
        color: rgb(249, 79, 92);
        background-image: initial;
        background-color: rgb(44, 0, 0);
        border-color: rgb(218, 0, 0);
    }
    .ant-tag-red-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(149, 0, 6);
        border-color: rgb(207, 0, 9);
    }
    .ant-tag-volcano {
        color: rgb(255, 113, 75);
        background-image: initial;
        background-color: rgb(48, 16, 0);
        border-color: rgb(217, 67, 0);
    }
    .ant-tag-volcano-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(155, 35, 0);
        border-color: rgb(206, 45, 0);
    }
    .ant-tag-orange {
        color: rgb(255, 164, 72);
        background-image: initial;
        background-color: rgb(49, 30, 0);
        border-color: rgb(216, 125, 0);
    }
    .ant-tag-orange-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(158, 79, 0);
        border-color: rgb(205, 103, 0);
    }
    .ant-tag-yellow {
        color: rgb(255, 229, 72);
        background-image: initial;
        background-color: rgb(47, 49, 0);
        border-color: rgb(216, 207, 0);
    }
    .ant-tag-yellow-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(159, 136, 0);
        border-color: rgb(205, 178, 0);
    }
    .ant-tag-gold {
        color: rgb(255, 191, 72);
        background-image: initial;
        background-color: rgb(49, 39, 0);
        border-color: rgb(216, 161, 0);
    }
    .ant-tag-gold-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(159, 103, 0);
        border-color: rgb(205, 135, 0);
    }
    .ant-tag-cyan {
        color: rgb(112, 254, 255);
        background-image: initial;
        background-color: rgb(0, 49, 42);
        border-color: rgb(23, 192, 174);
    }
    .ant-tag-cyan-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(5, 127, 127);
        border-color: rgb(9, 193, 193);
    }
    .ant-tag-lime {
        color: rgb(208, 255, 95);
        background-image: initial;
        background-color: rgb(44, 49, 0);
        border-color: rgb(172, 216, 0);
    }
    .ant-tag-lime-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(103, 143, 4);
        border-color: rgb(144, 199, 5);
    }
    .ant-tag-green {
        color: rgb(154, 254, 112);
        background-image: initial;
        background-color: rgb(26, 46, 0);
        border-color: rgb(98, 198, 20);
    }
    .ant-tag-green-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(49, 128, 10);
        border-color: rgb(72, 187, 15);
    }
    .ant-tag-blue {
        color: rgb(91, 186, 255);
        background-image: initial;
        background-color: rgb(0, 30, 49);
        border-color: rgb(0, 125, 216);
    }
    .ant-tag-blue-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
    }
    .ant-tag-geekblue {
        color: rgb(108, 179, 243);
        background-image: initial;
        background-color: rgb(17, 18, 19);
        border-color: rgb(0, 56, 219);
    }
    .ant-tag-geekblue-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(6, 33, 140);
        border-color: rgb(8, 46, 201);
    }
    .ant-tag-purple {
        color: rgb(157, 107, 239);
        background-image: initial;
        background-color: rgb(17, 18, 19);
        border-color: rgb(113, 4, 218);
    }
    .ant-tag-purple-inverse {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(71, 24, 137);
        border-color: rgb(92, 29, 177);
    }
    .ant-tag-success {
        color: rgb(139, 243, 89);
        background-image: initial;
        background-color: rgb(26, 46, 0);
        border-color: rgb(98, 198, 20);
    }
    .ant-tag-processing {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: rgb(0, 30, 49);
        border-color: rgb(0, 125, 216);
    }
    .ant-tag-error {
        color: rgb(255, 68, 78);
        background-image: initial;
        background-color: rgb(44, 0, 0);
        border-color: rgb(218, 0, 0);
    }
    .ant-tag-warning {
        color: rgb(255, 164, 58);
        background-image: initial;
        background-color: rgb(49, 30, 0);
        border-color: rgb(216, 125, 0);
    }
    .ant-card {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-card-hoverable:hover {
        border-color: transparent;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-bordered {
        border-color: rgb(114, 114, 114);
    }
    .ant-card-head {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: transparent;
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-card-head .ant-tabs {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-card-head .ant-tabs-bar {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-card-extra {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-card-grid {
        border-color: initial;
        box-shadow: rgb(20, 22, 23) 1px 0px 0px 0px,
        rgb(20, 22, 23) 0px 1px 0px 0px,
        rgb(20, 22, 23) 1px 1px 0px 0px,
        rgb(20, 22, 23) 1px 0px 0px 0px inset,
        rgb(20, 22, 23) 0px 1px 0px 0px inset;
    }
    .ant-card-grid-hoverable:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-actions {
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-top-color: rgb(114, 114, 114);
    }
    .ant-card-actions > li {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-card-actions > li > span:hover {
        color: rgb(58, 175, 255);
    }
    .ant-card-actions > li > span a:not(.ant-btn),
    .ant-card-actions > li > span > .anticon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-card-actions > li > span a:not(.ant-btn):hover,
    .ant-card-actions > li > span > .anticon:hover {
        color: rgb(58, 175, 255);
    }
    .ant-card-actions > li:not(:last-child) {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-card-rtl .ant-card-actions > li:not(:last-child) {
        border-right-color: initial;
        border-left-color: rgb(114, 114, 114);
    }
    .ant-card-type-inner .ant-card-head {
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-card-meta-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-card-meta-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-card-loading-block {
        background-image: linear-gradient(90deg,
        rgba(29, 38, 42, 0.2),
        rgba(29, 38, 42, 0.4),
        rgba(29, 38, 42, 0.2));
        background-color: initial;
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 10px 0px 8px -8px inset;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) -10px 0px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-tabs-right > .ant-tabs-content-holder,
    .ant-tabs-right > div > .ant-tabs-content-holder {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-tabs-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-tabs-dropdown-menu {
        background-color: rgb(13, 14, 15);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tabs-dropdown-menu-item {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-tabs-dropdown-menu-item:hover {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-tabs-dropdown-menu-item-disabled,
    .ant-tabs-dropdown-menu-item-disabled:hover {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-color: rgb(114, 114, 114);
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: rgb(58, 175, 255);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom-color: rgb(115, 115, 115);
    }
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-top-color: rgb(115, 115, 115);
    }
    .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-right-color: rgb(115, 115, 115);
    }
    .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-left-color: rgb(115, 115, 115);
    }
    .ant-tabs {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-color: rgb(114, 114, 114);
        outline-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
        color: rgb(85, 190, 255);
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
        color: rgb(91, 186, 255);
    }
    .ant-tabs-ink-bar {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-tabs-tab {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: rgb(91, 186, 255);
    }
    .ant-tabs-tab-btn {
        outline-color: initial;
    }
    .ant-tabs-tab-remove {
        color: rgba(241, 241, 241, 0.45);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-remove:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-tabs-tab:hover {
        color: rgb(85, 190, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: rgb(58, 175, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-tabs-tabpane {
        outline-color: initial;
    }
    .ant-carousel {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-carousel .slick-slider {
        -webkit-tap-highlight-color: transparent;
    }
    .ant-carousel .slick-list:focus {
        outline-color: initial;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        outline-color: initial;
    }
    .ant-carousel .slick-dots {
        list-style-image: initial;
    }
    .ant-carousel .slick-dots li button {
        color: transparent;
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-cascader {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-cascader-input.ant-input {
        background-color: transparent !important;
    }
    .ant-cascader-picker {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
        outline-color: initial;
    }
    .ant-cascader-picker-with-value .ant-cascader-picker-label {
        color: transparent;
    }
    .ant-cascader-picker-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-cascader-picker-borderless .ant-cascader-input {
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-cascader-picker-show-search.ant-cascader-picker-focused {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-cascader-picker-clear {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-cascader-picker-clear:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-cascader-picker-arrow {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-cascader-picker-label:hover + .ant-cascader-input {
        border-color: rgb(0, 109, 208);
    }
    .ant-cascader-menus {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-cascader-menus ul,
    .ant-cascader-menus ol {
        list-style-image: initial;
    }
    .ant-cascader-menu {
        list-style-image: initial;
        border-right-color: rgb(114, 114, 114);
    }
    .ant-cascader-menu:last-child {
        border-right-color: transparent;
    }
    .ant-cascader-menu-item:hover {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-cascader-menu-item-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-cascader-menu-item-disabled:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-cascader-menu-empty .ant-cascader-menu-item {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: rgb(0, 30, 49);
    }
    .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-loading-icon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
        color: rgb(255, 94, 96);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu {
        border-right-color: initial;
        border-left-color: rgb(114, 114, 114);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
        border-left-color: transparent;
    }
    .ant-input-affix-wrapper {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        background-image: none;
        border-color: rgb(111, 111, 111);
    }
    .ant-input-affix-wrapper::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-input-affix-wrapper:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-affix-wrapper-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-affix-wrapper-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-affix-wrapper[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-affix-wrapper[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-affix-wrapper-borderless,
    .ant-input-affix-wrapper-borderless:hover,
    .ant-input-affix-wrapper-borderless:focus,
    .ant-input-affix-wrapper-borderless-focused,
    .ant-input-affix-wrapper-borderless-disabled,
    .ant-input-affix-wrapper-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-input-affix-wrapper > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-affix-wrapper > input.ant-input:focus {
        box-shadow: none;
    }
    .ant-input-clear-icon {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-input-clear-icon:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-clear-icon:active {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        border-color: initial !important;
    }
    .ant-input {
        list-style-image: initial;
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        background-image: none;
        border-color: rgb(111, 111, 111);
    }
    .ant-input::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-input:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-group {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-input-group-addon {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(15, 17, 17);
        border-color: rgb(111, 111, 111);
    }
    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: inherit;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-input-group-addon .ant-select-open .ant-select-selector,
    .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: rgb(58, 175, 255);
    }
    .ant-input-group-addon:first-child {
        border-right-color: initial;
    }
    .ant-input-group-addon:last-child {
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:first-child {
        border-right-color: rgb(111, 111, 111);
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:last-child {
        border-right-color: initial;
        border-left-color: rgb(111, 111, 111);
    }
    .ant-input-password-icon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-password-icon:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-input-textarea-show-count::after {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
        border-color: rgb(0, 109, 208);
    }
    .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-left-color: rgb(0, 109, 208);
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        border-color: initial;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-right-color: rgb(0, 109, 208);
        border-left-color: rgb(111, 111, 111);
    }
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
        border-right-color: rgb(0, 109, 208);
    }
    .ant-checkbox {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: rgb(0, 101, 205);
    }
    .ant-checkbox-checked::after {
        border-color: rgb(0, 101, 205);
    }
    .ant-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111) !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner::after {
        border-color: rgb(114, 114, 114);
    }
    .ant-checkbox-disabled + span {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-checkbox-wrapper {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-group {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: rgb(0, 78, 158);
        border-color: initial;
    }
    .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-collapse {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(15, 17, 17);
        border-top-color: rgb(111, 111, 111);
        border-right-color: rgb(111, 111, 111);
        border-left-color: rgb(111, 111, 111);
        border-bottom-color: initial;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom-color: rgb(111, 111, 111);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        color: inherit;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
        outline-color: initial;
    }
    .ant-collapse-content {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        border-top-color: rgb(111, 111, 111);
    }
    .ant-collapse-borderless {
        background-color: rgb(15, 17, 17);
        border-color: initial;
    }
    .ant-collapse-borderless > .ant-collapse-item {
        border-bottom-color: rgb(111, 111, 111);
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse-ghost {
        background-color: transparent;
        border-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-comment {
        background-color: inherit;
    }
    .ant-comment-content-author-name {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-comment-content-author-name > * {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-comment-content-author-name > :hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-comment-content-author-time {
        color: rgb(207, 207, 207);
    }
    .ant-comment-actions > li {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-comment-actions > li > span {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-comment-actions > li > span:hover {
        color: rgb(181, 181, 181);
    }
    .ant-descriptions-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-descriptions-extra {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-item-label {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-descriptions-item-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border-color: rgb(114, 114, 114);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-right-color: initial;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color: rgb(15, 17, 17);
    }
    .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-descriptions-bordered .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: initial;
        border-left-color: rgb(114, 114, 114);
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-left-color: initial;
    }
    .ant-divider {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        border-top-color: rgba(85, 92, 96, 0.06);
    }
    .ant-divider-vertical {
        border-top-color: initial;
        border-left-color: rgba(85, 92, 96, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text {
        color: rgba(241, 241, 241, 0.85);
        border-top-color: rgba(85, 92, 96, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-top-color: inherit;
        border-bottom-color: initial;
    }
    .ant-divider-dashed {
        background-image: none;
        background-color: initial;
        border-color: rgba(85, 92, 96, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
        border-top-color: initial;
    }
    .ant-divider-plain.ant-divider-with-text {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) 9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) 12px 0px 48px 16px;
    }
    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) -6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) -9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) -12px 0px 48px 16px;
    }
    .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px 12px 48px 16px;
    }
    .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px -9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px -12px 48px 16px;
    }
    .ant-drawer-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-drawer-content {
        background-color: rgb(13, 14, 15);
        border-color: initial;
    }
    .ant-drawer-close {
        color: rgba(241, 241, 241, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-drawer-close:focus,
    .ant-drawer-close:hover {
        color: rgba(241, 241, 241, 0.75);
        text-decoration-color: initial;
    }
    .ant-drawer-header {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-drawer-header-no-title {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-drawer-footer {
        border-top-color: rgb(114, 114, 114);
    }
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-drawer-open-content {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-drawer .ant-picker-clear {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-form-item .ant-upload {
        background-image: initial;
        background-color: transparent;
    }
    .ant-form-item .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-explain.ant-form-item-explain-warning {
        color: rgb(255, 193, 57);
    }
    .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(139, 243, 89);
    }
    .ant-form-item-has-warning .ant-form-item-split {
        color: rgb(255, 193, 57);
    }
    .ant-form-item-has-warning .ant-input,
    .ant-form-item-has-warning .ant-input-affix-wrapper,
    .ant-form-item-has-warning .ant-input:hover,
    .ant-form-item-has-warning .ant-input-affix-wrapper:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(205, 135, 0);
    }
    .ant-form-item-has-warning .ant-input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
    .ant-form-item-has-warning .ant-input-focused,
    .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
        border-color: rgb(208, 141, 0);
        outline-color: initial;
        box-shadow: rgba(159, 103, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-disabled,
    .ant-form-item-has-warning .ant-input-disabled:hover {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(208, 141, 0);
        outline-color: initial;
        box-shadow: rgba(159, 103, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-prefix {
        color: rgb(255, 193, 57);
    }
    .ant-form-item-has-warning .ant-input-group-addon {
        color: rgb(255, 193, 57);
        border-color: rgb(205, 135, 0);
    }
    .ant-form-item-has-warning .has-feedback {
        color: rgb(255, 193, 57);
    }
    .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(255, 193, 57);
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(13, 14, 15);
        border-color: rgb(205, 135, 0) !important;
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(208, 141, 0);
        outline-color: initial;
        box-shadow: rgba(159, 103, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number,
    .ant-form-item-has-warning .ant-picker {
        background-color: rgb(13, 14, 15);
        border-color: rgb(205, 135, 0);
    }
    .ant-form-item-has-warning .ant-input-number-focused,
    .ant-form-item-has-warning .ant-picker-focused,
    .ant-form-item-has-warning .ant-input-number:focus,
    .ant-form-item-has-warning .ant-picker:focus {
        border-color: rgb(208, 141, 0);
        outline-color: initial;
        box-shadow: rgba(159, 103, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(205, 135, 0);
    }
    .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(208, 141, 0);
        outline-color: initial;
        box-shadow: rgba(159, 103, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-form-item-split {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper:focus,
    .ant-form-item-has-error .ant-input-focused,
    .ant-form-item-has-error .ant-input-affix-wrapper-focused {
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-disabled,
    .ant-form-item-has-error .ant-input-disabled:hover {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-prefix {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-has-error .ant-input-group-addon {
        color: rgb(255, 94, 96);
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .has-feedback {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0) !important;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: initial;
    }
    .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus {
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
        background-color: rgb(13, 14, 15);
        border-color: rgb(214, 0, 0);
        outline-color: initial;
        box-shadow: rgba(129, 0, 0, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-transfer-list {
        border-color: rgb(210, 0, 0);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
        border-color: rgb(111, 111, 111);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper {
        border-color: rgb(210, 0, 0) !important;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(129, 0, 0);
    }
    .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(58, 175, 255);
    }
    .ant-form {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-form legend {
        color: rgba(241, 241, 241, 0.45);
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(111, 111, 111); 
    }
    .ant-form input[type="file"]:focus, .ant-form input[type="radio"]:focus, .ant-form input[type="checkbox"]:focus {
        outline-color: rgb(200, 128, 0);
    }
    .ant-form output {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-form-item {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-form-item-label > label {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: rgb(255, 94, 96);
    }
    .ant-form-item-label > label .ant-form-item-optional {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-form-item-label > label .ant-form-item-tooltip {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-image-img-placeholder {
        background-color: rgb(18, 19, 20);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
    }
    .ant-image-mask {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-image-preview-wrap {
        outline-color: initial;
    }
    .ant-image-preview-operations {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-operations-operation-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-switch-left-disabled,
    .ant-image-preview-switch-right-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-input-number {
        list-style-image: initial;
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        background-image: none;
        border-color: rgb(111, 111, 111);
    }
    .ant-input-number::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-input-number:focus,
    .ant-input-number-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-number[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-number[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-number-borderless,
    .ant-input-number-borderless:hover,
    .ant-input-number-borderless:focus,
    .ant-input-number-borderless-focused,
    .ant-input-number-borderless-disabled,
    .ant-input-number-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-number-handler {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-number-handler:active {
        background-image: initial;
        background-color: rgb(18, 20, 21);
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: rgb(85, 190, 255);
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-input-number-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-input-number-input {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-number-input::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-input-number-handler-wrap {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-left-color: rgb(111, 111, 111);
    }
    .ant-input-number-handler-down {
        border-top-color: rgb(111, 111, 111);
    }
    .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-input-number-borderless {
        box-shadow: none;
    }
    .ant-input-number-out-of-range input {
        color: rgb(255, 94, 96);
    }
    .ant-input-number-rtl .ant-input-number-handler-wrap {
        border-right-color: rgb(111, 111, 111);
        border-left-color: initial;
    }
    .ant-layout {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-layout-header {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(0, 6, 20);
    }
    .ant-layout-footer {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-layout-sider {
        background-image: initial;
        background-color: rgb(0, 6, 20);
    }
    .ant-layout-sider-trigger {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 14, 36);
    }
    .ant-layout-sider-zero-width-trigger {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: rgb(0, 6, 20);
    }
    .ant-layout-sider-zero-width-trigger::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-layout-sider-zero-width-trigger:hover::after {
        background-image: initial;
        background-color: rgba(13, 14, 15, 0.1);
    }
    .ant-layout-sider-light {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-layout-sider-light .ant-layout-sider-trigger {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-list {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-list * {
        outline-color: initial;
    }
    .ant-list-empty-text {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-list-items {
        list-style-image: initial;
    }
    .ant-list-item {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-list-item-meta-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-list-item-meta-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-list-item-meta-title > a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-list-item-meta-title > a:hover {
        color: rgb(58, 175, 255);
    }
    .ant-list-item-meta-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-list-item-action {
        list-style-image: initial;
    }
    .ant-list-item-action > li {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-list-item-action-split {
        background-color: rgb(20, 22, 23);
    }
    .ant-list-header {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-footer {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-empty {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-list-split .ant-list-item {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-list-split .ant-list-item:last-child {
        border-bottom-color: initial;
    }
    .ant-list-split .ant-list-header {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-list-split.ant-list-empty .ant-list-footer {
        border-top-color: rgb(114, 114, 114);
    }
    .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-list-vertical .ant-list-item-meta-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-list-grid .ant-col > .ant-list-item {
        border-bottom-color: initial;
    }
    .ant-list-bordered {
        border-color: rgb(111, 111, 111);
    }
    .ant-spin {
        list-style-image: initial;
        color: rgb(58, 175, 255);
    }
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
        text-shadow: rgb(13, 14, 15) 0px 1px 2px;
    }
    .ant-spin-container::after {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-spin-tip {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-spin-dot-item {
        background-color: rgb(0, 78, 158);
    }
    @media not all,
    not all {
        .ant-spin-blur {
            background-image: initial;
            background-color: rgb(13, 14, 15);
        }
    }
    .ant-pagination {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-pagination ul,
    .ant-pagination ol {
        list-style-image: initial;
    }
    .ant-pagination-item {
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
        outline-color: initial;
    }
    .ant-pagination-item a {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-pagination-item a:hover {
        text-decoration-color: initial;
    }
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: rgb(0, 101, 205);
    }
    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
        color: rgb(58, 175, 255);
    }
    .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 101, 205);
    }
    .ant-pagination-item-active a {
        color: rgb(58, 175, 255);
    }
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-pagination-item-active:focus-visible a,
    .ant-pagination-item-active:hover a {
        color: rgb(85, 190, 255);
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        outline-color: initial;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: rgb(58, 175, 255);
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        outline-color: initial;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-pagination-prev:hover button,
    .ant-pagination-next:hover button {
        border-color: rgb(0, 109, 208);
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
        outline-color: initial;
    }
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: rgb(58, 175, 255);
        border-color: rgb(0, 101, 205);
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: rgba(241, 241, 241, 0.25);
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination-options-quick-jumper input {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        background-image: none;
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination-options-quick-jumper input::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-pagination-options-quick-jumper input:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-pagination-options-quick-jumper input:focus,
    .ant-pagination-options-quick-jumper input-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-pagination-options-quick-jumper input-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-pagination-options-quick-jumper input-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination-options-quick-jumper input[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-pagination-options-quick-jumper input[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination-options-quick-jumper input-borderless,
    .ant-pagination-options-quick-jumper input-borderless:hover,
    .ant-pagination-options-quick-jumper input-borderless:focus,
    .ant-pagination-options-quick-jumper input-borderless-focused,
    .ant-pagination-options-quick-jumper input-borderless-disabled,
    .ant-pagination-options-quick-jumper input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
        outline-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input:hover {
        border-color: rgb(0, 101, 205);
    }
    .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item {
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(30, 33, 34);
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
        color: rgb(241, 241, 241);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        color: rgba(241, 241, 241, 0.25);
        background-image: initial;
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111);
    }
    .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-mentions {
        list-style-image: initial;
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(13, 14, 15);
        background-image: none;
        border-color: rgb(111, 111, 111);
    }
    .ant-mentions::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-mentions:hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-mentions:focus,
    .ant-mentions-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions-disabled {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-mentions-disabled:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-mentions[disabled] {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-mentions[disabled]:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-mentions-borderless,
    .ant-mentions-borderless:hover,
    .ant-mentions-borderless:focus,
    .ant-mentions-borderless-focused,
    .ant-mentions-borderless-disabled,
    .ant-mentions-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-mentions-disabled > textarea {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(18, 19, 20);
    }
    .ant-mentions-disabled > textarea:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-mentions-focused {
        border-color: rgb(0, 109, 208);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions > textarea {
        border-color: initial;
        outline-color: initial;
    }
    .ant-mentions > textarea::placeholder {
        color: rgb(198, 198, 198);
    }
    .ant-mentions-measure {
        color: transparent;
    }
    .ant-mentions-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-mentions-dropdown-menu {
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-mentions-dropdown-menu-item {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-mentions-dropdown-menu-item:hover {
        background-color: rgb(18, 19, 20);
    }
    .ant-mentions-dropdown-menu-item-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-mentions-dropdown-menu-item-disabled:hover {
        color: rgba(241, 241, 241, 0.25);
        background-color: rgb(13, 14, 15);
    }
    .ant-mentions-dropdown-menu-item-selected {
        color: rgba(241, 241, 241, 0.85);
        background-color: rgb(15, 17, 17);
    }
    .ant-mentions-dropdown-menu-item-active {
        background-color: rgb(18, 19, 20);
    }
    .ant-message {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-message-notice-content {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-message-success .anticon {
        color: rgb(139, 243, 89);
    }
    .ant-message-error .anticon {
        color: rgb(255, 94, 96);
    }
    .ant-message-warning .anticon {
        color: rgb(255, 193, 57);
    }
    .ant-message-info .anticon,
    .ant-message-loading .anticon {
        color: rgb(58, 175, 255);
    }
    .ant-modal {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-modal-wrap {
        outline-color: initial;
    }
    .ant-modal-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-modal-content {
        background-color: rgb(13, 14, 15);
        border-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-modal-close {
        color: rgba(241, 241, 241, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
        color: rgba(241, 241, 241, 0.75);
        text-decoration-color: initial;
    }
    .ant-modal-header {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-modal-footer {
        background-image: initial;
        background-color: transparent;
        border-top-color: rgb(114, 114, 114);
    }
    .ant-modal-confirm-body .ant-modal-confirm-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
        color: rgb(255, 94, 96);
    }
    .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
    .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
        color: rgb(255, 193, 57);
    }
    .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: rgb(58, 175, 255);
    }
    .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
        color: rgb(139, 243, 89);
    }
    .ant-notification {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-notification-hook-holder > .ant-notification-notice {
        box-shadow: none;
    }
    .ant-notification-notice-message {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-notification-notice-message-single-line-auto-margin {
        background-color: transparent;
    }
    .anticon.ant-notification-notice-icon-success {
        color: rgb(139, 243, 89);
    }
    .anticon.ant-notification-notice-icon-info {
        color: rgb(58, 175, 255);
    }
    .anticon.ant-notification-notice-icon-warning {
        color: rgb(255, 193, 57);
    }
    .anticon.ant-notification-notice-icon-error {
        color: rgb(255, 94, 96);
    }
    .ant-notification-notice-close {
        color: rgba(241, 241, 241, 0.45);
        outline-color: initial;
    }
    .ant-notification-notice-close:hover {
        color: rgba(241, 241, 241, 0.67);
    }
    .ant-page-header {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-page-header-ghost {
        background-color: inherit;
    }
    .ant-page-header-back-button {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(241, 241, 241);
    }
    .ant-page-header-back-button:focus,
    .ant-page-header-back-button:hover {
        color: rgb(85, 190, 255);
    }
    .ant-page-header-back-button:active {
        color: rgb(91, 186, 255);
    }
    .ant-page-header-heading-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-page-header-heading-sub-title {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
        border-color: initial;
    }
    .ant-progress {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-progress-steps-item {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-progress-steps-item-active {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-progress-inner {
        background-color: rgb(18, 19, 20);
    }
    .ant-progress-circle-trail {
        stroke: rgb(234, 234, 234);
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(58, 175, 255);
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
        background-color: rgb(0, 78, 158);
    }
    .ant-progress-success-bg {
        background-color: rgb(49, 128, 10);
    }
    .ant-progress-text {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-progress-status-active .ant-progress-bg::before {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-progress-status-exception .ant-progress-bg {
        background-color: rgb(129, 0, 0);
    }
    .ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 94, 96);
    }
    .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(255, 94, 96);
    }
    .ant-progress-status-success .ant-progress-bg {
        background-color: rgb(49, 128, 10);
    }
    .ant-progress-status-success .ant-progress-text {
        color: rgb(139, 243, 89);
    }
    .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(139, 243, 89);
    }
    .ant-progress-circle .ant-progress-inner {
        background-color: transparent;
    }
    .ant-progress-circle .ant-progress-text {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 94, 96);
    }
    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
        color: rgb(139, 243, 89);
    }
    .ant-rate {
        color: rgb(255, 235, 57);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-rate-star {
        color: inherit;
    }
    .ant-rate-star > div:focus {
        outline-color: initial;
    }
    .ant-rate-star-first,
    .ant-rate-star-second {
        color: rgb(231, 231, 231);
    }
    .ant-rate-star-half .ant-rate-star-first,
    .ant-rate-star-full .ant-rate-star-second {
        color: inherit;
    }
    .ant-result-success .ant-result-icon > .anticon {
        color: rgb(139, 243, 89);
    }
    .ant-result-error .ant-result-icon > .anticon {
        color: rgb(255, 94, 96);
    }
    .ant-result-info .ant-result-icon > .anticon {
        color: rgb(58, 175, 255);
    }
    .ant-result-warning .ant-result-icon > .anticon {
        color: rgb(255, 193, 57);
    }
    .ant-result-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-result-subtitle {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-result-content {
        background-color: rgb(15, 17, 17);
    }
    .ant-skeleton-header .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-title {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
        background-image: linear-gradient(90deg,
        rgba(45, 49, 51, 0.2) 25%,
        rgba(75, 81, 84, 0.24) 37%,
        rgba(45, 49, 51, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        background-image: linear-gradient(90deg,
        rgba(45, 49, 51, 0.2) 25%,
        rgba(75, 81, 84, 0.24) 37%,
        rgba(45, 49, 51, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
        background-image: linear-gradient(90deg,
        rgba(45, 49, 51, 0.2) 25%,
        rgba(75, 81, 84, 0.24) 37%,
        rgba(45, 49, 51, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
        background-image: linear-gradient(90deg,
        rgba(45, 49, 51, 0.2) 25%,
        rgba(75, 81, 84, 0.24) 37%,
        rgba(45, 49, 51, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
        background-image: linear-gradient(90deg,
        rgba(45, 49, 51, 0.2) 25%,
        rgba(75, 81, 84, 0.24) 37%,
        rgba(45, 49, 51, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton-element .ant-skeleton-button {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-input {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image {
        background-image: initial;
        background-color: rgba(45, 49, 51, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image-path {
        fill: rgb(198, 198, 198);
    }
    .ant-slider {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-slider-rail {
        background-color: rgb(18, 19, 20);
    }
    .ant-slider-track {
        background-color: rgb(0, 55, 94);
    }
    .ant-slider-handle {
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 125, 216);
    }
    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
        border-color: rgb(0, 102, 209);
        box-shadow: rgba(0, 78, 158, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle:focus {
        border-color: rgb(0, 102, 209);
        outline-color: initial;
        box-shadow: rgba(0, 78, 158, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: rgb(0, 101, 205);
    }
    .ant-slider:hover .ant-slider-rail {
        background-color: rgb(27, 30, 31);
    }
    .ant-slider:hover .ant-slider-track {
        background-color: rgb(0, 63, 115);
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: rgb(0, 117, 212);
    }
    .ant-slider-mark-text {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-slider-mark-text-active {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-slider-step {
        background-image: initial;
        background-color: transparent;
    }
    .ant-slider-dot {
        background-color: rgb(13, 14, 15);
        border-color: rgb(114, 114, 114);
    }
    .ant-slider-dot-active {
        border-color: rgb(0, 103, 216);
    }
    .ant-slider-disabled .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-slider-disabled .ant-slider-handle,
    .ant-slider-disabled .ant-slider-dot {
        background-color: rgb(13, 14, 15);
        box-shadow: none;
        border-color: rgba(85, 92, 96, 0.25) !important;
    }
    .ant-statistic {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-statistic-title {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-statistic-content {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-steps {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-steps-item-container {
        outline-color: initial;
    }
    .ant-steps-item-icon {
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-steps-item-icon .ant-steps-icon {
        color: rgb(58, 175, 255);
    }
    .ant-steps-item-tail::after {
        background-image: initial;
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-subtitle {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-item-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: rgb(13, 14, 15);
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-process .ant-steps-item-icon {
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 101, 205);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(58, 175, 255);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
        color: rgb(241, 241, 241);
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: rgb(13, 14, 15);
        border-color: rgb(0, 101, 205);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(58, 175, 255);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(0, 78, 158);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(0, 78, 158);
    }
    .ant-steps-item-error .ant-steps-item-icon {
        background-color: rgb(13, 14, 15);
        border-color: rgb(210, 0, 0);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(255, 94, 96);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(129, 0, 0);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgb(255, 94, 96);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgb(255, 94, 96);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(20, 22, 23);
    }
    .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(129, 0, 0);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
        color: rgb(58, 175, 255);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
        border-color: rgb(0, 101, 205);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
        color: rgb(58, 175, 255);
    }
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(58, 175, 255);
    }
    .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-small .ant-steps-item-description {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0);
    }
    .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::after {
        border-top-color: rgba(85, 92, 96, 0.25);
        border-right-color: rgba(85, 92, 96, 0.25);
        border-bottom-color: initial;
        border-left-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::before {
        background-color: rgb(0, 78, 158);
    }
    .ant-switch {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
        border-color: initial;
    }
    .ant-switch:focus {
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
    }
    .ant-switch-checked:focus {
        box-shadow: rgba(0, 78, 158, 0.2) 0px 0px 0px 2px;
    }
    .ant-switch:focus:hover {
        box-shadow: none;
    }
    .ant-switch-checked {
        background-color: rgb(0, 78, 158);
    }
    .ant-switch-loading *,
    .ant-switch-disabled * {
        box-shadow: none;
    }
    .ant-switch-inner {
        color: rgb(241, 241, 241);
    }
    .ant-switch-handle::before {
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 16, 0, 0.2) 0px 2px 4px 0px;
    }
    .ant-switch-loading-icon {
        color: rgba(241, 241, 241, 0.65);
    }
    .ant-switch-checked .ant-switch-loading-icon {
        color: rgb(58, 175, 255);
    }
    .ant-table-small .ant-table-thead > tr > th {
        background-color: rgb(15, 17, 17);
    }
    .ant-table.ant-table-bordered > .ant-table-title {
        border-top-color: rgb(114, 114, 114);
        border-right-color: rgb(114, 114, 114);
        border-left-color: rgb(114, 114, 114);
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
        border-top-color: rgb(114, 114, 114);
        border-left-color: rgb(114, 114, 114);
        border-right-color: initial;
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
        border-right-color: rgb(114, 114, 114);
    }
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
        border-right-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-footer {
        border-right-color: rgb(114, 114, 114);
        border-bottom-color: rgb(114, 114, 114);
        border-left-color: rgb(114, 114, 114);
        border-top-color: initial;
    }
    .ant-table-cell .ant-table-container:first-child {
        border-top-color: initial;
    }
    .ant-table-cell-scrollbar {
        box-shadow: rgb(15, 17, 17) 0px 1px 0px 1px;
    }
    .ant-table {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-table-footer {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-table-thead > tr > th {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-table-thead > tr:not(:last-child) > th[colspan] {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr > td {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background-image: initial;
        background-color: rgb(0, 30, 49);
        border-color: rgba(85, 92, 96, 0.03);
    }
    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background-image: initial;
        background-color: rgb(0, 34, 55);
    }
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-image: initial;
        background-color: rgb(19, 21, 22);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
        background-image: initial;
        background-color: rgb(17, 18, 19);
    }
    .ant-table-thead th.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    td.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-table-column-sorter {
        color: rgb(198, 198, 198);
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: rgb(58, 175, 255);
    }
    .ant-table-filter-trigger-container-open,
    .ant-table-filter-trigger-container:hover,
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
        background-image: initial;
        background-color: rgb(26, 28, 29);
    }
    .ant-table-filter-trigger {
        color: rgb(198, 198, 198);
    }
    .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
    .ant-table-filter-trigger:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-table-filter-trigger.active {
        color: rgb(58, 175, 255);
    }
    .ant-table-filter-dropdown {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-color: rgb(13, 14, 15);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-table-filter-dropdown .ant-dropdown-menu {
        border-color: initial;
        box-shadow: none;
    }
    .ant-table-filter-dropdown-btns {
        background-color: inherit;
        border-top-color: rgb(114, 114, 114);
    }
    .ant-table-selection-extra .anticon {
        color: rgb(198, 198, 198);
    }
    .ant-table-selection-extra .anticon:hover {
        color: rgb(181, 181, 181);
    }
    .ant-table-row-expand-icon {
        text-decoration-color: initial;
        color: inherit;
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-color: rgb(114, 114, 114);
        outline-color: initial;
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover {
        color: rgb(85, 190, 255);
    }
    .ant-table-row-expand-icon:active {
        color: rgb(91, 186, 255);
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover,
    .ant-table-row-expand-icon:active {
        border-color: currentcolor;
    }
    .ant-table-row-expand-icon::before,
    .ant-table-row-expand-icon::after {
        background-image: initial;
        background-color: currentcolor;
    }
    .ant-table-row-expand-icon-spaced {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background-image: initial;
        background-color: rgb(15, 16, 17);
    }
    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-left .ant-table-cell-fix-left-first::after,
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-ping-right .ant-table-cell-fix-right-first::after,
    .ant-table-ping-right .ant-table-cell-fix-right-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-sticky-scroll {
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-top-color: rgb(114, 114, 114);
    }
    .ant-table-sticky-scroll-bar {
        background-color: rgba(0, 0, 0, 0.35);
    }
    .ant-table-sticky-scroll-bar:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-table-sticky-scroll-bar-active {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-timeline {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-timeline-item {
        list-style-image: initial;
    }
    .ant-timeline-item-tail {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-timeline-item-pending .ant-timeline-item-head {
        background-color: transparent;
    }
    .ant-timeline-item-head {
        background-color: rgb(13, 14, 15);
        border-color: transparent;
    }
    .ant-timeline-item-head-blue {
        color: rgb(58, 175, 255);
        border-color: rgb(0, 101, 205);
    }
    .ant-timeline-item-head-red {
        color: rgb(255, 94, 96);
        border-color: rgb(210, 0, 0);
    }
    .ant-timeline-item-head-green {
        color: rgb(139, 243, 89);
        border-color: rgb(72, 187, 15);
    }
    .ant-timeline-item-head-gray {
        color: rgba(241, 241, 241, 0.25);
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-timeline-item-head-custom {
        border-color: initial;
    }
    .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-left-color: rgb(114, 114, 114);
    }
    .ant-timeline-rtl .ant-timeline-item-tail {
        border-right-color: rgb(114, 114, 114);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-right-color: rgb(114, 114, 114);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-right-color: rgb(114, 114, 114);
        border-left-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
        border-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
        background-image: initial;
        background-color: rgb(15, 17, 17);
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-transfer-customize-list .ant-input[disabled] {
        background-color: transparent;
    }
    .ant-transfer {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-transfer-disabled .ant-transfer-list {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-transfer-list {
        border-color: rgb(111, 111, 111);
    }
    .ant-transfer-list-search-action {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-transfer-list-search-action .anticon {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-transfer-list-search-action .anticon:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-transfer-list-header {
        color: rgba(241, 241, 241, 0.85);
        background-image: initial;
        background-color: rgb(13, 14, 15);
        border-bottom-color: rgb(114, 114, 114);
    }
    .ant-transfer-list-content {
        list-style-image: initial;
    }
    .ant-transfer-list-content-item-remove {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(216, 216, 216);
    }
    .ant-transfer-list-content-item-remove:focus,
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(85, 190, 255);
    }
    .ant-transfer-list-content-item-remove:active {
        color: rgb(91, 186, 255);
    }
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(85, 190, 255);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-color: rgb(18, 19, 20);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
        background-color: rgb(0, 34, 55);
    }
    .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-transfer-list-content-item-checked {
        background-color: rgb(0, 30, 49);
    }
    .ant-transfer-list-content-item-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-transfer-list-pagination {
        border-top-color: rgb(114, 114, 114);
    }
    .ant-transfer-list-body-not-found {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-transfer-list-footer {
        border-top-color: rgb(114, 114, 114);
    }
    .ant-select-tree-checkbox {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
        border-color: rgb(0, 101, 205);
    }
    .ant-select-tree-checkbox-checked::after {
        border-color: rgb(0, 101, 205);
    }
    .ant-select-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
    }
    .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111) !important;
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        border-color: rgb(114, 114, 114);
    }
    .ant-select-tree-checkbox-disabled + span {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-tree-checkbox-wrapper {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-group {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
        background-color: rgb(0, 78, 158);
        border-color: initial;
    }
    .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-select-tree {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-select-tree .ant-select-tree-treenode {
        outline-color: initial;
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-select-tree .ant-select-tree-treenode.filter-node .ant-select-tree-title {
        color: inherit;
    }
    .ant-select-tree-switcher-loading-icon {
        color: rgb(58, 175, 255);
    }
    .ant-select-tree-switcher-leaf-line::before {
        border-left-color: rgb(111, 111, 111);
    }
    .ant-select-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(111, 111, 111);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: rgb(18, 19, 20);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
        background-color: rgb(30, 32, 33); 
    }
    .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 78, 158); 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 101, 205);
    }
    .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 78, 158) 0px 0px 0px 2px;
    }
    .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
        border-right-color: rgb(111, 111, 111);
    }
    .ant-select-tree-show-line .ant-select-tree-switcher {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background-image: initial;
        background-color: rgb(0, 78, 158);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
        color: rgb(241, 241, 241);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
        color: rgb(241, 241, 241);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree-checkbox {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: rgb(0, 101, 205);
    }
    .ant-tree-checkbox-checked::after {
        border-color: rgb(0, 101, 205);
    }
    .ant-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-tree-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-right-color: rgb(115, 115, 115);
        border-bottom-color: rgb(115, 115, 115);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: rgb(0, 78, 158);
        border-color: rgb(0, 101, 205);
    }
    .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
        background-color: rgb(18, 19, 20);
        border-color: rgb(111, 111, 111) !important;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        border-color: rgb(114, 114, 114);
    }
    .ant-tree-checkbox-disabled + span {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-tree-checkbox-wrapper {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-group {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
        background-color: rgb(13, 14, 15);
        border-color: rgb(111, 111, 111);
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: rgb(0, 78, 158);
        border-color: initial;
    }
    .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(85, 92, 96, 0.25);
    }
    .ant-tree {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 30, 49);
    }
    .ant-tree .ant-tree-treenode {
        outline-color: initial;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(18, 19, 20);
    }
    .ant-tree .ant-tree-treenode.filter-node .ant-tree-title {
        color: inherit;
    }
    .ant-tree-switcher-loading-icon {
        color: rgb(58, 175, 255);
    }
    .ant-tree-switcher-leaf-line::before {
        border-left-color: rgb(111, 111, 111);
    }
    .ant-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(111, 111, 111);
    }
    .ant-tree .ant-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: rgb(18, 19, 20);
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgb(30, 32, 33); 
    }
    .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 78, 158); 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 101, 205);
    }
    .ant-tree .ant-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 78, 158) 0px 0px 0px 2px;
    }
    .ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: rgb(111, 111, 111);
    }
    .ant-tree-show-line .ant-tree-switcher {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: initial;
        border-left-color: rgb(111, 111, 111);
    }
    .ant-typography {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-typography.ant-typography-secondary {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-typography.ant-typography-success {
        color: rgb(139, 243, 89);
    }
    .ant-typography.ant-typography-warning {
        color: rgb(255, 193, 57);
    }
    .ant-typography.ant-typography-danger {
        color: rgb(255, 94, 96);
    }
    a.ant-typography.ant-typography-danger:active,
    a.ant-typography.ant-typography-danger:focus,
    a.ant-typography.ant-typography-danger:hover {
        color: rgb(255, 126, 121);
    }
    .ant-typography.ant-typography-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    h1.ant-typography,
    .ant-typography h1 {
        color: rgba(241, 241, 241, 0.85);
    }
    h2.ant-typography,
    .ant-typography h2 {
        color: rgba(241, 241, 241, 0.85);
    }
    h3.ant-typography,
    .ant-typography h3 {
        color: rgba(241, 241, 241, 0.85);
    }
    h4.ant-typography,
    .ant-typography h4 {
        color: rgba(241, 241, 241, 0.85);
    }
    h5.ant-typography,
    .ant-typography h5 {
        color: rgba(241, 241, 241, 0.85);
    }
    a.ant-typography,
    .ant-typography a {
        color: rgb(58, 175, 255);
        outline-color: initial;
        text-decoration-color: initial;
    }
    a.ant-typography:focus,
    .ant-typography a:focus,
    a.ant-typography:hover,
    .ant-typography a:hover {
        color: rgb(85, 190, 255);
    }
    a.ant-typography:active,
    .ant-typography a:active {
        color: rgb(91, 186, 255);
    }
    a.ant-typography:active,
    .ant-typography a:active,
    a.ant-typography:hover,
    .ant-typography a:hover {
        text-decoration-color: initial;
    }
    a.ant-typography[disabled],
    .ant-typography a[disabled],
    a.ant-typography.ant-typography-disabled,
    .ant-typography a.ant-typography-disabled {
        color: rgba(241, 241, 241, 0.25);
    }
    a.ant-typography[disabled]:active,
    .ant-typography a[disabled]:active,
    a.ant-typography.ant-typography-disabled:active,
    .ant-typography a.ant-typography-disabled:active,
    a.ant-typography[disabled]:hover,
    .ant-typography a[disabled]:hover,
    a.ant-typography.ant-typography-disabled:hover,
    .ant-typography a.ant-typography-disabled:hover {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-typography code {
        background-image: initial;
        background-color: rgba(65, 70, 73, 0.1);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography kbd {
        background-image: initial;
        background-color: rgba(65, 70, 73, 0.06);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography mark {
        background-color: rgb(95, 71, 0);
    }
    .ant-typography u,
    .ant-typography ins {
        text-decoration-color: initial;
    }
    .ant-typography s,
    .ant-typography del {
        text-decoration-color: initial;
    }
    .ant-typography-expand,
    .ant-typography-edit,
    .ant-typography-copy {
        color: rgb(58, 175, 255);
        text-decoration-color: initial;
        outline-color: initial;
    }
    .ant-typography-expand:focus,
    .ant-typography-edit:focus,
    .ant-typography-copy:focus,
    .ant-typography-expand:hover,
    .ant-typography-edit:hover,
    .ant-typography-copy:hover {
        color: rgb(85, 190, 255);
    }
    .ant-typography-expand:active,
    .ant-typography-edit:active,
    .ant-typography-copy:active {
        color: rgb(91, 186, 255);
    }
    .ant-typography-copy-success,
    .ant-typography-copy-success:hover,
    .ant-typography-copy-success:focus {
        color: rgb(139, 243, 89);
    }
    .ant-typography-edit-content-confirm {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-typography pre {
        background-image: initial;
        background-color: rgba(65, 70, 73, 0.1);
        border-color: rgba(100, 100, 100, 0.2);
    }
    .ant-typography pre code {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-typography blockquote {
        border-left-color: rgba(100, 100, 100, 0.2);
    }
    .ant-upload {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-upload-btn {
        outline-color: initial;
    }
    .ant-upload.ant-upload-select-picture-card {
        background-color: rgb(15, 17, 17);
        border-color: rgb(111, 111, 111);
    }
    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(0, 101, 205);
    }
    .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(111, 111, 111);
    }
    .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(15, 17, 17);
        border-color: rgb(111, 111, 111);
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: rgb(0, 95, 202);
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: rgb(0, 109, 208);
    }
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: rgb(85, 190, 255);
    }
    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload.ant-upload-drag .anticon-plus {
        color: rgba(241, 241, 241, 0.25);
    }
    .ant-upload.ant-upload-drag .anticon-plus:hover {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload.ant-upload-drag:hover .anticon-plus {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload-list {
        color: rgba(241, 241, 241, 0.85);
        list-style-image: initial;
    }
    .ant-upload-list-item-card-actions .anticon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload-list-item-info .anticon-loading .anticon,
    .ant-upload-list-item-info .ant-upload-text-icon .anticon {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload-list-item .anticon-close {
        color: rgba(241, 241, 241, 0.45);
    }
    .ant-upload-list-item .anticon-close:hover {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: rgb(18, 19, 20);
    }
    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
    .ant-upload-list-item-error .ant-upload-list-item-name {
        color: rgb(255, 94, 96);
    }
    .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
        color: rgb(255, 94, 96);
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border-color: rgb(111, 111, 111);
    }
    .ant-upload-list-picture .ant-upload-list-item:hover,
    .ant-upload-list-picture-card .ant-upload-list-item:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-color: rgb(210, 0, 0); 
    }
    .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-image: initial; background-color: transparent; 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
        fill: rgb(255, 215, 207); 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
        fill: rgb(255, 94, 96);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
        color: rgba(241, 241, 241, 0.85);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
        color: rgb(241, 241, 241);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        background-color: rgb(15, 17, 17); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
        border-color: transparent; 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: rgb(107, 107, 107); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-top-color: initial;
        border-bottom-color: rgb(114, 114, 114); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: rgb(107, 107, 107); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: initial;
        border-top-color: rgb(115, 115, 115); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-top-color: rgb(107, 107, 107);
    }
    .react-datepicker-wrapper {
        border-color: initial;
    }
    .react-datepicker {
        background-color: rgb(13, 14, 15);
        color: rgb(241, 241, 241);
        border-color: rgb(107, 107, 107);
    }
    .react-datepicker--time-only .react-datepicker__time-container {
        border-left-color: initial;
    }
    .react-datepicker__header {
        background-color: rgb(20, 22, 23);
        border-bottom-color: rgb(107, 107, 107);
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: rgb(241, 241, 241);
    }
    .react-datepicker__navigation {
        background-image: none;
        background-color: initial;
        border-color: transparent;
    }
    .react-datepicker__navigation--previous {
        border-right-color: rgb(110, 110, 110);
    }
    .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(108, 108, 108);
    }
    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(113, 113, 113);
    }
    .react-datepicker__navigation--next {
        border-left-color: rgb(110, 110, 110);
    }
    .react-datepicker__navigation--next:hover {
        border-left-color: rgb(108, 108, 108);
    }
    .react-datepicker__navigation--next--disabled,
    .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(113, 113, 113);
    }
    .react-datepicker__navigation--years-previous {
        border-top-color: rgb(110, 110, 110);
    }
    .react-datepicker__navigation--years-previous:hover {
        border-top-color: rgb(108, 108, 108);
    }
    .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(110, 110, 110);
    }
    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: rgb(108, 108, 108);
    }
    .react-datepicker__time-container {
        border-left-color: rgb(107, 107, 107);
    }
    .react-datepicker__time-container--with-today-button {
        border-color: rgb(107, 107, 107);
    }
    .react-datepicker__time-container .react-datepicker__time {
        background-image: initial;
        background-color: rgb(13, 14, 15);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style-image: initial;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        background-color: rgb(20, 22, 23);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: rgb(14, 67, 107);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
        background-color: rgb(14, 67, 107);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        color: rgb(207, 207, 207);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__week-number {
        color: rgb(207, 207, 207);
    }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
        background-color: rgb(20, 22, 23);
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: rgb(241, 241, 241);
    }
    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
        background-color: rgb(14, 67, 107);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__month--selected:hover,
    .react-datepicker__month--in-selecting-range:hover,
    .react-datepicker__month--in-range:hover,
    .react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter--in-selecting-range:hover,
    .react-datepicker__quarter--in-range:hover {
        background-color: rgb(12, 57, 92);
    }
    .react-datepicker__month--disabled,
    .react-datepicker__quarter--disabled {
        color: rgb(207, 207, 207);
    }
    .react-datepicker__month--disabled:hover,
    .react-datepicker__quarter--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background-color: rgb(20, 22, 23);
    }
    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        background-color: rgb(27, 128, 60);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
        background-color: rgb(26, 124, 35);
    }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
        color: rgb(255, 42, 255);
    }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
        color: rgb(128, 255, 128);
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: rgb(14, 67, 107);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: rgb(12, 57, 92);
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: rgb(22, 86, 136);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: rgb(12, 57, 92);
    }
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        background-color: rgba(14, 67, 107, 0.5);
    }
    .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
        background-color: rgb(20, 22, 23);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        color: rgb(207, 207, 207);
    }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month-text.react-datepicker__month--in-range:hover,
    .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
        background-color: rgb(14, 67, 107);
    }
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
        background-color: rgb(20, 22, 23);
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
        border-color: transparent;
    }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
        border-top-color: rgb(108, 108, 108);
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-top-color: rgb(110, 110, 110);
    }
    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: rgb(20, 22, 23);
        border-color: rgb(107, 107, 107);
    }
    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: rgb(38, 41, 43);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(108, 108, 108);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
        border-top-color: rgb(108, 108, 108);
    }
    .react-datepicker__close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .react-datepicker__close-icon::after {
        background-color: rgb(14, 67, 107);
        color: rgb(241, 241, 241);
    }
    .react-datepicker__today-button {
        background-image: initial;
        background-color: rgb(20, 22, 23);
        border-top-color: rgb(107, 107, 107);
    }
    .react-datepicker__portal {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .react-datepicker__portal .react-datepicker__navigation {
        border-color: transparent;
    }
    .react-datepicker__portal .react-datepicker__navigation--previous {
        border-right-color: rgb(110, 110, 110);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(108, 108, 108);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(113, 113, 113);
    }
    .react-datepicker__portal .react-datepicker__navigation--next {
        border-left-color: rgb(110, 110, 110);
    }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
        border-left-color: rgb(108, 108, 108);
    }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled,
    .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(113, 113, 113);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-track,
    .ReactQueryDevtoolsPanel scrollbar-track {
        background-image: initial;
        background-color: rgb(5, 16, 30);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-thumb,
    .ReactQueryDevtoolsPanel scrollbar-thumb {
        background-image: initial;
        background-color: rgb(35, 46, 59);
        border-color: rgb(94, 94, 94); 
    }
    [ant-click-animating-without-extra-node="true"]::after,
    .ant-click-animating-node {
        --darkreader-bg--darkreader-bg--antd-wave-shadow-color: #00336b;
    }
    .vimvixen-hint {
        background-color: rgb(99, 65, 0) !important;
        border-color: rgb(179, 144, 8) !important;
        color: rgb(255, 243, 213) !important;
    }
    #edge-translate-panel-body {
        color: var(--darkreader-text--darkreader-neutral-text) !important;
    }
    .clear-focus:focus {
        outline-color: initial !important;
        box-shadow: none !important;
    }
    html {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    body {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27); 
    }
    [tabindex="-1"]:focus {
        outline-color: initial !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: rgba(255, 255, 255, 0.85);
    }
    abbr[title],
    abbr[data-original-title] {
        text-decoration-color: initial;
        border-bottom-color: initial;
    }
    a {
        color: rgb(47, 165, 255);
        text-decoration-color: initial;
        background-color: transparent;
        outline-color: initial;
    }
    a:hover {
        color: rgb(84, 183, 255);
    }
    a:active {
        color: rgb(82, 174, 250);
    }
    a:active,
    a:hover {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a:focus {
        text-decoration-color: initial;
        outline-color: initial;
    }
    a[disabled] {
        color: rgba(255, 255, 255, 0.25);
    }
    caption {
        color: rgba(255, 255, 255, 0.45);
    }
    input,
    button,
    select,
    optgroup,
    textarea {
        color: inherit;
    }
    fieldset {
        border-color: initial;
    }
    legend {
        color: inherit;
    }
    mark {
        background-color: rgb(65, 67, 0);
    }
    ::selection {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192); 
    }
    .anticon {
        color: inherit; 
    }
    html {
        --darkreader-bg--antd-wave-shadow-color: #0063c0; --scroll-bar: 0; 
    }
    [ant-click-animating-without-extra-node="true"]::after,
    .ant-click-animating-node {
        box-shadow: 0 0 0 0 var(--darkreader-bg--antd-wave-shadow-color);
    }
    .ant-alert {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-alert-success {
        background-color: rgb(39, 63, 0);
        border-color: rgb(65, 121, 21);
    }
    .ant-alert-success .ant-alert-icon {
        color: rgb(130, 234, 80);
    }
    .ant-alert-info {
        background-color: rgb(0, 45, 67);
        border-color: rgb(0, 84, 136);
    }
    .ant-alert-info .ant-alert-icon {
        color: rgb(47, 165, 255);
    }
    .ant-alert-warning {
        background-color: rgb(67, 56, 0);
        border-color: rgb(137, 105, 0);
    }
    .ant-alert-warning .ant-alert-icon {
        color: rgb(254, 183, 44);
    }
    .ant-alert-error {
        background-color: rgb(61, 8, 0);
        border-color: rgb(120, 11, 0);
    }
    .ant-alert-error .ant-alert-icon {
        color: rgb(255, 96, 98);
    }
    .ant-alert-close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-alert-close-icon .anticon-close {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-alert-close-icon .anticon-close:hover {
        color: rgba(255, 255, 255, 0.75);
    }
    .ant-alert-close-text {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-alert-close-text:hover {
        color: rgba(255, 255, 255, 0.75);
    }
    .ant-alert-with-description .ant-alert-message {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-alert-message {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-alert-banner {
        border-color: initial;
    }
    .ant-anchor {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-anchor-wrapper {
        background-color: rgb(24, 26, 27);
    }
    .ant-anchor-ink::before {
        background-color: rgb(32, 35, 37);
    }
    .ant-anchor-ink-ball {
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 90, 173);
    }
    .ant-anchor-link-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
        color: rgb(47, 165, 255);
    }
    .ant-select-auto-complete {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: rgb(199, 199, 199);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selector {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-select-multiple .ant-select-selection-item {
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(53, 58, 60);
    }
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: rgb(199, 199, 199);
        border-color: rgb(60, 65, 67);
    }
    .ant-select-multiple .ant-select-selection-item-remove {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-select-multiple .ant-select-selection-item-remove:hover {
        color: rgba(255, 255, 255, 0.75);
    }
    .ant-select {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: rgb(0, 89, 161);
    }
    .ant-select-selection-placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-select-arrow {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-clear {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-select-clear:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-select-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-select-dropdown-empty {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-item-empty {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-item {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-select-item-group {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgb(30, 32, 33);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(0, 45, 67);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
        color: rgb(47, 165, 255);
    }
    .ant-select-item-option-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-borderless .ant-select-selector {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-empty-normal {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-empty-small {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-empty-img-default-ellipse {
        fill: rgb(248, 248, 248);
    }
    .ant-empty-img-default-path-1 {
        fill: rgb(193, 193, 193);
    }
    .ant-empty-img-default-path-3 {
        fill: rgb(249, 249, 249);
    }
    .ant-empty-img-default-path-4 {
        fill: rgb(230, 230, 230);
    }
    .ant-empty-img-default-path-5 {
        fill: rgb(230, 230, 230);
    }
    .ant-empty-img-default-g {
        fill: rgb(255, 255, 255);
    }
    .ant-empty-img-simple-ellipse {
        fill: rgb(248, 248, 248);
    }
    .ant-empty-img-simple-g {
        stroke: rgb(223, 223, 223);
    }
    .ant-empty-img-simple-path {
        fill: rgb(254, 254, 254);
    }
    .ant-avatar {
        list-style-image: initial;
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(54, 58, 60);
    }
    .ant-avatar-image {
        background-image: initial;
        background-color: transparent;
    }
    .ant-avatar-group .ant-avatar {
        border-color: rgb(48, 53, 55);
    }
    .ant-popover {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-popover::after {
        background-image: initial;
        background-color: rgba(24, 26, 27, 0.01);
    }
    .ant-popover-inner {
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    @media not all,
    not all {
        .ant-popover-inner {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
            rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
            rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
        }
    }
    .ant-popover-title {
        color: rgba(255, 255, 255, 0.85);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-popover-inner-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-popover-message {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-popover-message > .anticon {
        color: rgb(254, 183, 44);
    }
    .ant-popover-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent rgb(48, 53, 55) rgb(48, 53, 55) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: transparent transparent rgb(48, 53, 55) rgb(48, 53, 55);
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(48, 53, 55) transparent transparent rgb(48, 53, 55);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
        border-color: rgb(48, 53, 55) rgb(48, 53, 55) transparent transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-back-top {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-back-top-content {
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-back-top-content:hover {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-badge {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-badge-count {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(160, 0, 2);
        box-shadow: rgb(24, 26, 27) 0px 0px 0px 1px;
    }
    .ant-badge-count a,
    .ant-badge-count a:hover {
        color: rgb(255, 255, 255);
    }
    .ant-badge-dot {
        background-image: initial;
        background-color: rgb(160, 0, 2);
        box-shadow: rgb(24, 26, 27) 0px 0px 0px 1px;
    }
    .ant-badge-status-success {
        background-color: rgb(67, 159, 21);
    }
    .ant-badge-status-processing {
        background-color: rgb(0, 99, 192);
    }
    .ant-badge-status-processing::after {
        border-color: rgb(0, 90, 173);
    }
    .ant-badge-status-default {
        background-color: rgb(45, 49, 52);
    }
    .ant-badge-status-error {
        background-color: rgb(160, 0, 2);
    }
    .ant-badge-status-warning {
        background-color: rgb(193, 129, 4);
    }
    .ant-badge-status-pink {
        background-image: initial;
        background-color: rgb(173, 16, 102);
    }
    .ant-badge-status-magenta {
        background-image: initial;
        background-color: rgb(173, 16, 102);
    }
    .ant-badge-status-red {
        background-image: initial;
        background-color: rgb(183, 8, 17);
    }
    .ant-badge-status-volcano {
        background-image: initial;
        background-color: rgb(188, 51, 4);
    }
    .ant-badge-status-orange {
        background-image: initial;
        background-color: rgb(192, 101, 4);
    }
    .ant-badge-status-yellow {
        background-image: initial;
        background-color: rgb(193, 168, 4);
    }
    .ant-badge-status-gold {
        background-image: initial;
        background-color: rgb(193, 129, 4);
    }
    .ant-badge-status-cyan {
        background-image: initial;
        background-color: rgb(15, 157, 157);
    }
    .ant-badge-status-lime {
        background-image: initial;
        background-color: rgb(129, 176, 14);
    }
    .ant-badge-status-green {
        background-image: initial;
        background-color: rgb(67, 159, 21);
    }
    .ant-badge-status-blue {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-badge-status-geekblue {
        background-image: initial;
        background-color: rgb(16, 47, 173);
    }
    .ant-badge-status-purple {
        background-image: initial;
        background-color: rgb(92, 37, 169);
    }
    .ant-badge-status-text {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-ribbon {
        list-style-image: initial;
        color: rgb(255, 255, 255);
        background-color: rgb(0, 99, 192);
    }
    .ant-ribbon-text {
        color: rgb(255, 255, 255);
    }
    .ant-ribbon-corner {
        color: currentcolor;
        border-color: initial;
    }
    .ant-ribbon-corner::after {
        color: rgba(255, 255, 255, 0.25);
        border-color: inherit;
    }
    .ant-ribbon-color-pink {
        color: rgb(239, 69, 163);
        background-image: initial;
        background-color: rgb(173, 16, 102);
    }
    .ant-ribbon-color-magenta {
        color: rgb(239, 69, 163);
        background-image: initial;
        background-color: rgb(173, 16, 102);
    }
    .ant-ribbon-color-red {
        color: rgb(248, 57, 67);
        background-image: initial;
        background-color: rgb(183, 8, 17);
    }
    .ant-ribbon-color-volcano {
        color: rgb(254, 102, 52);
        background-image: initial;
        background-color: rgb(188, 51, 4);
    }
    .ant-ribbon-color-orange {
        color: rgb(254, 154, 45);
        background-image: initial;
        background-color: rgb(192, 101, 4);
    }
    .ant-ribbon-color-yellow {
        color: rgb(254, 225, 44);
        background-image: initial;
        background-color: rgb(193, 168, 4);
    }
    .ant-ribbon-color-gold {
        color: rgb(254, 183, 44);
        background-image: initial;
        background-color: rgb(193, 129, 4);
    }
    .ant-ribbon-color-cyan {
        color: rgb(81, 240, 240);
        background-image: initial;
        background-color: rgb(15, 157, 157);
    }
    .ant-ribbon-color-lime {
        color: rgb(190, 242, 61);
        background-image: initial;
        background-color: rgb(129, 176, 14);
    }
    .ant-ribbon-color-green {
        color: rgb(130, 234, 80);
        background-image: initial;
        background-color: rgb(67, 159, 21);
    }
    .ant-ribbon-color-blue {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-ribbon-color-geekblue {
        color: rgb(69, 143, 239);
        background-image: initial;
        background-color: rgb(16, 47, 173);
    }
    .ant-ribbon-color-purple {
        color: rgb(129, 68, 216);
        background-image: initial;
        background-color: rgb(92, 37, 169);
    }
    .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
        border-color: currentcolor currentcolor transparent transparent;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
        border-color: currentcolor transparent transparent currentcolor;
    }
    .ant-breadcrumb {
        list-style-image: initial;
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-breadcrumb a {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-breadcrumb a:hover {
        color: rgb(84, 183, 255);
    }
    .ant-breadcrumb > span:last-child {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-breadcrumb > span:last-child a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-breadcrumb-separator {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-menu-item-danger.ant-menu-item {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-item-danger.ant-menu-item-active {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item:active {
        background-image: initial;
        background-color: rgb(61, 4, 0);
    }
    .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(255, 96, 98);
    }
    .ant-menu-item-danger.ant-menu-item-selected > a,
    .ant-menu-item-danger.ant-menu-item-selected > a:hover {
        color: rgb(255, 96, 98);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        background-color: rgb(61, 4, 0);
    }
    .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
        border-right-color: rgb(157, 0, 2);
    }
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
        color: rgb(255, 96, 98);
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
        color: rgb(255, 255, 255);
        background-color: rgb(160, 0, 2);
    }
    .ant-menu {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-menu ul,
    .ant-menu ol {
        list-style-image: initial;
    }
    .ant-menu-item-group-title {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-menu-submenu-selected {
        color: rgb(47, 165, 255);
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-menu-item a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-item a:hover {
        color: rgb(47, 165, 255);
    }
    .ant-menu-item a::before {
        background-color: transparent;
    }
    .ant-menu-item > .ant-badge a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-item > .ant-badge a:hover {
        color: rgb(47, 165, 255);
    }
    .ant-menu-item-divider {
        background-color: rgb(32, 35, 37);
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: rgb(47, 165, 255);
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
        background-color: transparent;
    }
    .ant-menu-item-selected {
        color: rgb(47, 165, 255);
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        color: rgb(47, 165, 255);
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 45, 67);
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-menu-vertical-right {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-menu-vertical.ant-menu-sub,
    .ant-menu-vertical-left.ant-menu-sub,
    .ant-menu-vertical-right.ant-menu-sub {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
    .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu > .ant-menu-item-divider {
        background-color: rgb(32, 35, 37);
    }
    .ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-menu-submenu > .ant-menu {
        background-color: rgb(24, 26, 27);
    }
    .ant-menu-submenu-popup > .ant-menu {
        background-color: rgb(24, 26, 27);
    }
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
        background-color: currentcolor;
    }
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: rgb(47, 165, 255);
    }
    .ant-menu-vertical .ant-menu-submenu-selected,
    .ant-menu-vertical-left .ant-menu-submenu-selected,
    .ant-menu-vertical-right .ant-menu-submenu-selected {
        color: rgb(47, 165, 255);
    }
    .ant-menu-horizontal {
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(53, 58, 60);
        box-shadow: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: rgb(47, 165, 255);
        border-bottom-color: rgb(0, 90, 173);
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border-bottom-color: transparent;
    }
    .ant-menu-horizontal > .ant-menu-item a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-horizontal > .ant-menu-item a:hover {
        color: rgb(47, 165, 255);
    }
    .ant-menu-horizontal > .ant-menu-item-selected a {
        color: rgb(47, 165, 255);
    }
    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right-color: rgb(0, 90, 173);
    }
    .ant-menu-inline-collapsed-tooltip a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-root.ant-menu-vertical,
    .ant-menu-root.ant-menu-vertical-left,
    .ant-menu-root.ant-menu-vertical-right,
    .ant-menu-root.ant-menu-inline {
        box-shadow: none;
    }
    .ant-menu-sub.ant-menu-inline {
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: initial;
        box-shadow: none;
    }
    .ant-menu-item-disabled,
    .ant-menu-submenu-disabled {
        background-image: none;
        background-color: initial;
        color: rgba(255, 255, 255, 0.25) !important;
        border-color: transparent !important;
    }
    .ant-menu-item-disabled a,
    .ant-menu-submenu-disabled a {
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: rgba(255, 255, 255, 0.65);
        background-image: initial;
        background-color: rgb(0, 17, 33);
    }
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
    .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
    .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-menu-dark.ant-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background-image: initial;
        background-color: rgb(0, 10, 18);
    }
    .ant-menu-dark.ant-menu-horizontal {
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
        border-top-color: rgb(122, 122, 122);
        border-right-color: rgb(122, 122, 122);
        border-left-color: rgb(122, 122, 122);
        border-bottom-color: initial;
    }
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: rgb(0, 99, 192);
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a,
    .ant-menu-dark .ant-menu-item > span > a {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
        border-right-color: initial;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
        color: rgb(255, 255, 255);
        background-color: transparent;
    }
    .ant-menu-dark .ant-menu-item:hover > a,
    .ant-menu-dark .ant-menu-item-active > a,
    .ant-menu-dark .ant-menu-submenu-active > a,
    .ant-menu-dark .ant-menu-submenu-open > a,
    .ant-menu-dark .ant-menu-submenu-selected > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > a,
    .ant-menu-dark .ant-menu-item:hover > span > a,
    .ant-menu-dark .ant-menu-item-active > span > a,
    .ant-menu-dark .ant-menu-submenu-active > span > a,
    .ant-menu-dark .ant-menu-submenu-open > span > a,
    .ant-menu-dark .ant-menu-submenu-selected > span > a,
    .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
        color: rgb(255, 255, 255);
    }
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-menu-dark .ant-menu-item:hover {
        background-color: transparent;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0, 99, 192);
    }
    .ant-menu-dark .ant-menu-item-selected {
        color: rgb(255, 255, 255);
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected::after {
        border-right-color: initial;
    }
    .ant-menu-dark .ant-menu-item-selected > a,
    .ant-menu-dark .ant-menu-item-selected > span > a,
    .ant-menu-dark .ant-menu-item-selected > a:hover,
    .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: rgb(255, 255, 255);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
    .ant-menu-dark .ant-menu-item-selected .anticon {
        color: rgb(255, 255, 255);
    }
    .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
    .ant-menu-dark .ant-menu-item-selected .anticon + span {
        color: rgb(255, 255, 255);
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: rgb(0, 99, 192);
    }
    .ant-menu-dark .ant-menu-item-disabled,
    .ant-menu-dark .ant-menu-submenu-disabled,
    .ant-menu-dark .ant-menu-item-disabled > a,
    .ant-menu-dark .ant-menu-submenu-disabled > a,
    .ant-menu-dark .ant-menu-item-disabled > span > a,
    .ant-menu-dark .ant-menu-submenu-disabled > span > a {
        color: rgba(255, 255, 255, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
        color: rgba(255, 255, 255, 0.35) !important;
    }
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
    .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
    .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
        background-image: initial !important;
        background-color: rgba(24, 26, 27, 0.35) !important;
    }
    .ant-menu-rtl.ant-menu-inline,
    .ant-menu-rtl.ant-menu-vertical {
        border-right-color: initial;
        border-left-color: rgb(53, 58, 60);
    }
    .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
    .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
        border-left-color: initial;
    }
    .ant-menu-sub.ant-menu-inline {
        border-color: initial;
    }
    .ant-tooltip {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-tooltip-inner {
        color: rgb(255, 255, 255);
        text-decoration-color: initial;
        background-color: rgba(0, 0, 0, 0.75);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tooltip-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tooltip-arrow-content {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .ant-tooltip-placement-top .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-tooltip-placement-right .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px 3px 7px;
    }
    .ant-tooltip-placement-left .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
    .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) 3px -3px 7px;
    }
    .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
        box-shadow: rgba(0, 0, 0, 0.07) -3px -3px 7px;
    }
    .ant-tooltip-pink .ant-tooltip-inner {
        background-color: rgb(173, 16, 102);
    }
    .ant-tooltip-pink .ant-tooltip-arrow-content {
        background-color: rgb(173, 16, 102);
    }
    .ant-tooltip-magenta .ant-tooltip-inner {
        background-color: rgb(173, 16, 102);
    }
    .ant-tooltip-magenta .ant-tooltip-arrow-content {
        background-color: rgb(173, 16, 102);
    }
    .ant-tooltip-red .ant-tooltip-inner {
        background-color: rgb(183, 8, 17);
    }
    .ant-tooltip-red .ant-tooltip-arrow-content {
        background-color: rgb(183, 8, 17);
    }
    .ant-tooltip-volcano .ant-tooltip-inner {
        background-color: rgb(188, 51, 4);
    }
    .ant-tooltip-volcano .ant-tooltip-arrow-content {
        background-color: rgb(188, 51, 4);
    }
    .ant-tooltip-orange .ant-tooltip-inner {
        background-color: rgb(192, 101, 4);
    }
    .ant-tooltip-orange .ant-tooltip-arrow-content {
        background-color: rgb(192, 101, 4);
    }
    .ant-tooltip-yellow .ant-tooltip-inner {
        background-color: rgb(193, 168, 4);
    }
    .ant-tooltip-yellow .ant-tooltip-arrow-content {
        background-color: rgb(193, 168, 4);
    }
    .ant-tooltip-gold .ant-tooltip-inner {
        background-color: rgb(193, 129, 4);
    }
    .ant-tooltip-gold .ant-tooltip-arrow-content {
        background-color: rgb(193, 129, 4);
    }
    .ant-tooltip-cyan .ant-tooltip-inner {
        background-color: rgb(15, 157, 157);
    }
    .ant-tooltip-cyan .ant-tooltip-arrow-content {
        background-color: rgb(15, 157, 157);
    }
    .ant-tooltip-lime .ant-tooltip-inner {
        background-color: rgb(129, 176, 14);
    }
    .ant-tooltip-lime .ant-tooltip-arrow-content {
        background-color: rgb(129, 176, 14);
    }
    .ant-tooltip-green .ant-tooltip-inner {
        background-color: rgb(67, 159, 21);
    }
    .ant-tooltip-green .ant-tooltip-arrow-content {
        background-color: rgb(67, 159, 21);
    }
    .ant-tooltip-blue .ant-tooltip-inner {
        background-color: rgb(0, 99, 192);
    }
    .ant-tooltip-blue .ant-tooltip-arrow-content {
        background-color: rgb(0, 99, 192);
    }
    .ant-tooltip-geekblue .ant-tooltip-inner {
        background-color: rgb(16, 47, 173);
    }
    .ant-tooltip-geekblue .ant-tooltip-arrow-content {
        background-color: rgb(16, 47, 173);
    }
    .ant-tooltip-purple .ant-tooltip-inner {
        background-color: rgb(92, 37, 169);
    }
    .ant-tooltip-purple .ant-tooltip-arrow-content {
        background-color: rgb(92, 37, 169);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
        color: rgb(255, 96, 98);
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(160, 0, 2);
    }
    .ant-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-dropdown-arrow {
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
        border-color: transparent rgb(48, 53, 55) rgb(48, 53, 55) transparent;
        box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
    }
    .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
    .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
        border-color: rgb(48, 53, 55) transparent transparent rgb(48, 53, 55);
        box-shadow: rgba(0, 0, 0, 0.06) -2px -2px 5px;
    }
    .ant-dropdown-menu {
        background-color: rgb(24, 26, 27);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-dropdown-menu-item-group-title {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-dropdown-menu-submenu-popup {
        background-image: initial;
        background-color: transparent;
        box-shadow: none;
    }
    .ant-dropdown-menu-submenu-popup ul,
    .ant-dropdown-menu-submenu-popup li {
        list-style-image: initial;
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-submenu-title > a:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a,
    .ant-dropdown-menu-submenu-title > .anticon + span > a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-dropdown-menu-item > .anticon + span > a:hover,
    .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected,
    .ant-dropdown-menu-item-selected > a,
    .ant-dropdown-menu-submenu-title-selected > a {
        color: rgb(47, 165, 255);
        background-color: rgb(0, 45, 67);
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: rgb(30, 32, 33);
    }
    .ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-submenu-title-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-dropdown-menu-item-disabled:hover,
    .ant-dropdown-menu-submenu-title-disabled:hover {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(24, 26, 27);
    }
    .ant-dropdown-menu-item-disabled > .anticon + span > a,
    .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
    .ant-dropdown-menu-item-disabled > a,
    .ant-dropdown-menu-submenu-title-disabled > a {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-dropdown-menu-item-divider,
    .ant-dropdown-menu-submenu-title-divider {
        background-color: rgb(32, 35, 37);
    }
    .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
    .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-dropdown-menu-item-group-list {
        list-style-image: initial;
    }
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(24, 26, 27);
    }
    .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: rgb(47, 165, 255);
    }
    .ant-dropdown-menu-dark,
    .ant-dropdown-menu-dark .ant-dropdown-menu {
        background-image: initial;
        background-color: rgb(0, 17, 33);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: transparent;
    }
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
    .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-btn {
        box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-btn,
    .ant-btn:active,
    .ant-btn:focus {
        outline-color: initial;
    }
    .ant-btn:not([disabled]):hover {
        text-decoration-color: initial;
    }
    .ant-btn:not([disabled]):active {
        outline-color: initial;
        box-shadow: none;
    }
    .ant-btn > a:only-child {
        color: currentcolor;
    }
    .ant-btn > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 89, 161);
    }
    .ant-btn:hover > a:only-child,
    .ant-btn:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn:hover > a:only-child::after,
    .ant-btn:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(7, 91, 182);
    }
    .ant-btn:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn[disabled] > a:only-child,
    .ant-btn[disabled]:hover > a:only-child,
    .ant-btn[disabled]:focus > a:only-child,
    .ant-btn[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn[disabled] > a:only-child::after,
    .ant-btn[disabled]:hover > a:only-child::after,
    .ant-btn[disabled]:focus > a:only-child::after,
    .ant-btn[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        text-decoration-color: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-btn-primary {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 92, 168);
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-primary:hover > a:only-child,
    .ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:hover > a:only-child::after,
    .ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary:active {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(7, 88, 176);
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-primary[disabled] > a:only-child,
    .ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
        border-right-color: rgb(0, 89, 161);
        border-left-color: rgb(0, 89, 161);
    }
    .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
        border-color: rgb(60, 65, 67);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
        border-right-color: rgb(0, 89, 161);
    }
    .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
        border-right-color: rgb(60, 65, 67);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: rgb(0, 89, 161);
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-left-color: rgb(60, 65, 67);
    }
    .ant-btn-ghost {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(60, 65, 67);
    }
    .ant-btn-ghost > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:hover,
    .ant-btn-ghost:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-ghost:hover > a:only-child,
    .ant-btn-ghost:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:hover > a:only-child::after,
    .ant-btn-ghost:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-ghost:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-ghost[disabled],
    .ant-btn-ghost[disabled]:hover,
    .ant-btn-ghost[disabled]:focus,
    .ant-btn-ghost[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-ghost[disabled] > a:only-child,
    .ant-btn-ghost[disabled]:hover > a:only-child,
    .ant-btn-ghost[disabled]:focus > a:only-child,
    .ant-btn-ghost[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-ghost[disabled] > a:only-child::after,
    .ant-btn-ghost[disabled]:hover > a:only-child::after,
    .ant-btn-ghost[disabled]:focus > a:only-child::after,
    .ant-btn-ghost[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-btn-dashed > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-dashed:hover > a:only-child,
    .ant-btn-dashed:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:hover > a:only-child::after,
    .ant-btn-dashed:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-dashed:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dashed[disabled],
    .ant-btn-dashed[disabled]:hover,
    .ant-btn-dashed[disabled]:focus,
    .ant-btn-dashed[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dashed[disabled] > a:only-child,
    .ant-btn-dashed[disabled]:hover > a:only-child,
    .ant-btn-dashed[disabled]:focus > a:only-child,
    .ant-btn-dashed[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dashed[disabled] > a:only-child::after,
    .ant-btn-dashed[disabled]:hover > a:only-child::after,
    .ant-btn-dashed[disabled]:focus > a:only-child::after,
    .ant-btn-dashed[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(160, 0, 2);
        border-color: rgb(157, 0, 2);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:hover,
    .ant-btn-danger:focus {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(135, 3, 0);
        border-color: rgb(144, 3, 0);
    }
    .ant-btn-danger:hover > a:only-child,
    .ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:hover > a:only-child::after,
    .ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger:active {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(165, 31, 37);
        border-color: rgb(147, 28, 33);
    }
    .ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-danger[disabled],
    .ant-btn-danger[disabled]:hover,
    .ant-btn-danger[disabled]:focus,
    .ant-btn-danger[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-danger[disabled] > a:only-child,
    .ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-link:hover > a:only-child,
    .ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:hover > a:only-child::after,
    .ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-link:hover,
    .ant-btn-link:focus,
    .ant-btn-link:active {
        border-color: transparent;
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-link[disabled] > a:only-child,
    .ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-text:hover > a:only-child,
    .ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:hover > a:only-child::after,
    .ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-text:hover,
    .ant-btn-text:focus {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-text:active {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-text[disabled],
    .ant-btn-text[disabled]:hover,
    .ant-btn-text[disabled]:focus,
    .ant-btn-text[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-text[disabled] > a:only-child,
    .ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:hover,
    .ant-btn-dangerous:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(144, 3, 0);
    }
    .ant-btn-dangerous:hover > a:only-child,
    .ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(147, 28, 33);
    }
    .ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous[disabled],
    .ant-btn-dangerous[disabled]:hover,
    .ant-btn-dangerous[disabled]:focus,
    .ant-btn-dangerous[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(160, 0, 2);
        border-color: rgb(157, 0, 2);
        text-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 0px;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:hover,
    .ant-btn-dangerous.ant-btn-primary:focus {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(135, 3, 0);
        border-color: rgb(144, 3, 0);
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary:active {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(165, 31, 37);
        border-color: rgb(147, 28, 33);
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled],
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover,
    .ant-btn-dangerous.ant-btn-text:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.02);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.03);
        border-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-dangerous.ant-btn-text[disabled],
    .ant-btn-dangerous.ant-btn-text[disabled]:hover,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus,
    .ant-btn-dangerous.ant-btn-text[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
    .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn::before {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
        border-left-color: transparent;
    }
    .ant-btn-background-ghost {
        color: rgb(255, 255, 255);
        border-color: rgb(48, 53, 55);
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-btn-background-ghost.ant-btn-primary {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 90, 173);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover,
    .ant-btn-background-ghost.ant-btn-primary:focus {
        color: rgb(84, 183, 255);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(0, 89, 161);
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary:active {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(7, 91, 182);
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled],
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(157, 0, 2);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover,
    .ant-btn-background-ghost.ant-btn-danger:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(144, 3, 0);
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(147, 28, 33);
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled],
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(157, 0, 2);
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover,
    .ant-btn-background-ghost.ant-btn-dangerous:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(144, 3, 0);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: transparent;
        border-color: rgb(147, 28, 33);
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
        color: rgb(255, 96, 98);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
        color: rgb(255, 135, 132);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
        color: rgb(223, 75, 82);
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
        color: currentcolor;
    }
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
    .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-right-color: rgb(0, 89, 161);
        border-left-color: rgb(60, 65, 67);
    }
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
    .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
        border-right-color: rgb(60, 65, 67);
        border-left-color: rgb(0, 89, 161);
    }
    .ant-picker-calendar {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-picker-calendar .ant-picker-panel {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(53, 58, 60);
    }
    .ant-picker-calendar-full .ant-picker-panel {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: initial;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgb(47, 165, 255);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
        border-right-color: initial;
        border-bottom-color: initial;
        border-left-color: initial;
        border-top-color: rgb(53, 58, 60);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
        border-color: rgb(0, 90, 173);
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-radio-group {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
        border-left-color: initial;
    }
    .ant-radio-wrapper {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-radio {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: rgba(0, 99, 192, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-checked::after {
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-radio-inner::after {
        background-color: rgb(0, 99, 192);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-disabled .ant-radio-inner {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67) !important;
    }
    .ant-radio-disabled .ant-radio-inner::after {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .ant-radio-disabled + span {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-radio-button-wrapper {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(45, 49, 52);
    }
    .ant-radio-button-wrapper:first-child {
        border-left-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper:hover {
        color: rgb(47, 165, 255);
    }
    .ant-radio-button-wrapper:focus-within {
        box-shadow: rgba(0, 99, 192, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: rgb(0, 99, 192);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(84, 183, 255);
        border-color: rgb(0, 89, 161);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: rgb(0, 92, 168);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(82, 174, 250);
        border-color: rgb(7, 91, 182);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
        background-color: rgb(7, 88, 176);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 99, 192, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 92, 168);
        border-color: rgb(0, 89, 161);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(7, 88, 176);
        border-color: rgb(7, 91, 182);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: rgba(0, 99, 192, 0.08) 0px 0px 0px 3px;
    }
    .ant-radio-button-wrapper-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper-disabled:first-child,
    .ant-radio-button-wrapper-disabled:hover {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper-disabled:first-child {
        border-left-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(38, 41, 43);
        border-color: rgb(60, 65, 67);
        box-shadow: none;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
        border-right-color: rgb(60, 65, 67);
    }
    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-right-color: rgb(0, 89, 161);
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
        border-right-color: rgb(60, 65, 67);
    }
    .ant-picker {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border-color: rgb(0, 89, 161);
    }
    .ant-picker-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker.ant-picker-disabled {
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-picker.ant-picker-borderless {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-picker-input > input {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-input > input::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-picker-input > input:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-picker-input > input:focus,
    .ant-picker-input > input-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-picker-input > input-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-input > input-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-picker-input > input[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-input > input[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-picker-input > input-borderless,
    .ant-picker-input > input-borderless:hover,
    .ant-picker-input > input-borderless:focus,
    .ant-picker-input > input-borderless-focused,
    .ant-picker-input > input-borderless-disabled,
    .ant-picker-input > input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-picker-input > input:focus {
        box-shadow: none;
    }
    .ant-picker-input > input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-input-placeholder > input {
        color: rgb(199, 199, 199);
    }
    .ant-picker-suffix {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-picker-clear {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-picker-clear:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-picker-separator {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-picker-focused .ant-picker-separator {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-picker-range .ant-picker-active-bar {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-picker-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-picker-ranges {
        list-style-image: initial;
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: rgb(0, 45, 67);
        border-color: rgb(0, 84, 136);
    }
    .ant-picker-range-arrow {
        box-shadow: rgba(0, 0, 0, 0.06) 2px -2px 6px;
    }
    .ant-picker-range-arrow::after {
        border-color: rgb(48, 53, 55) rgb(48, 53, 55) transparent transparent;
    }
    .ant-picker-panel-container {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-picker-panel-container .ant-picker-panel {
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-container .ant-picker-panel-focused {
        border-color: rgb(53, 58, 60);
    }
    .ant-picker-panel {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(53, 58, 60);
        outline-color: initial;
    }
    .ant-picker-panel-focused {
        border-color: rgb(0, 90, 173);
    }
    .ant-picker-header {
        color: rgba(255, 255, 255, 0.85);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-picker-header button {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-picker-header > button:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-picker-header-view button {
        color: inherit;
    }
    .ant-picker-header-view button:hover {
        color: rgb(47, 165, 255);
    }
    .ant-picker-prev-icon::before,
    .ant-picker-next-icon::before,
    .ant-picker-super-prev-icon::before,
    .ant-picker-super-next-icon::before {
        border-color: currentcolor;
    }
    .ant-picker-super-prev-icon::after,
    .ant-picker-super-next-icon::after {
        border-color: currentcolor;
    }
    .ant-picker-content th {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-picker-cell {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-picker-cell-in-view {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(0, 90, 173);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
        border-top-color: rgb(0, 74, 142);
        border-bottom-color: rgb(0, 74, 142);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
        background-image: initial;
        background-color: rgb(39, 42, 44);
    }
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background-image: initial;
        background-color: rgb(39, 42, 44);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 74, 142);
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 74, 142);
    }
    .ant-picker-cell-disabled .ant-picker-cell-inner {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-cell-disabled::before {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
    .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-footer {
        border-bottom-color: transparent;
    }
    .ant-picker-panel .ant-picker-footer {
        border-top-color: rgb(53, 58, 60);
    }
    .ant-picker-footer-extra:not(:last-child) {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-picker-today-btn {
        color: rgb(47, 165, 255);
    }
    .ant-picker-today-btn:hover {
        color: rgb(84, 183, 255);
    }
    .ant-picker-today-btn:active {
        color: rgb(82, 174, 250);
    }
    .ant-picker-today-btn.ant-picker-today-btn-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-left-color: rgb(0, 74, 142);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 74, 142);
    }
    .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-right-color: rgb(0, 74, 142);
    }
    .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
    .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
        border-left-color: rgb(0, 74, 142);
    }
    .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
        background-image: initial !important;
        background-color: transparent !important;
    }
    .ant-picker-week-panel-row:hover td {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row-selected:hover td {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
    .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: rgb(48, 53, 55);
    }
    .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
    .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
        color: rgb(255, 255, 255);
    }
    .ant-picker-datetime-panel .ant-picker-time-panel {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-picker-time-panel-column {
        list-style-image: initial;
    }
    .ant-picker-time-panel-column:not(:first-child) {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-picker-time-panel-column-active {
        background-image: initial;
        background-color: rgba(0, 45, 67, 0.2);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-right-color: rgb(0, 74, 142);
        border-left-color: initial;
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-right-color: initial;
        border-left-color: rgb(0, 74, 142);
    }
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
    .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
        border-right-color: rgb(0, 74, 142);
        border-left-color: rgb(0, 74, 142);
    }
    .ant-tag {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: rgb(60, 65, 67);
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-tag-close-icon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-tag-close-icon:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-tag-has-color {
        border-color: transparent;
    }
    .ant-tag-has-color,
    .ant-tag-has-color a,
    .ant-tag-has-color a:hover,
    .ant-tag-has-color .anticon-close,
    .ant-tag-has-color .anticon-close:hover {
        color: rgb(255, 255, 255);
    }
    .ant-tag-checkable {
        background-color: transparent;
        border-color: transparent;
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: rgb(47, 165, 255);
    }
    .ant-tag-checkable:active,
    .ant-tag-checkable-checked {
        color: rgb(255, 255, 255);
    }
    .ant-tag-checkable-checked {
        background-color: rgb(0, 99, 192);
    }
    .ant-tag-checkable:active {
        background-color: rgb(7, 88, 176);
    }
    .ant-tag-pink {
        color: rgb(231, 80, 169);
        background-image: initial;
        background-color: rgb(61, 0, 24);
        border-color: rgb(128, 0, 58);
    }
    .ant-tag-pink-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(173, 16, 102);
        border-color: rgb(157, 15, 93);
    }
    .ant-tag-magenta {
        color: rgb(231, 80, 169);
        background-image: initial;
        background-color: rgb(61, 0, 24);
        border-color: rgb(128, 0, 58);
    }
    .ant-tag-magenta-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(173, 16, 102);
        border-color: rgb(157, 15, 93);
    }
    .ant-tag-red {
        color: rgb(240, 70, 83);
        background-image: initial;
        background-color: rgb(61, 4, 0);
        border-color: rgb(132, 7, 0);
    }
    .ant-tag-red-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(183, 8, 17);
        border-color: rgb(166, 7, 16);
    }
    .ant-tag-volcano {
        color: rgb(245, 104, 65);
        background-image: initial;
        background-color: rgb(66, 28, 0);
        border-color: rgb(134, 47, 0);
    }
    .ant-tag-volcano-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(188, 51, 4);
        border-color: rgb(170, 45, 4);
    }
    .ant-tag-orange {
        color: rgb(250, 155, 65);
        background-image: initial;
        background-color: rgb(67, 45, 0);
        border-color: rgb(136, 84, 0);
    }
    .ant-tag-orange-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(192, 101, 4);
        border-color: rgb(172, 91, 4);
    }
    .ant-tag-yellow {
        color: rgb(252, 220, 65);
        background-image: initial;
        background-color: rgb(65, 67, 0);
        border-color: rgb(137, 132, 0);
    }
    .ant-tag-yellow-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(193, 168, 4);
        border-color: rgb(172, 149, 4);
    }
    .ant-tag-gold {
        color: rgb(252, 183, 65);
        background-image: initial;
        background-color: rgb(67, 56, 0);
        border-color: rgb(137, 105, 0);
    }
    .ant-tag-gold-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(193, 129, 4);
        border-color: rgb(172, 116, 4);
    }
    .ant-tag-cyan {
        color: rgb(116, 245, 250);
        background-image: initial;
        background-color: rgb(0, 67, 59);
        border-color: rgb(23, 123, 112);
    }
    .ant-tag-cyan-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(15, 157, 157);
        border-color: rgb(17, 176, 176);
    }
    .ant-tag-lime {
        color: rgb(203, 254, 95);
        background-image: initial;
        background-color: rgb(61, 67, 0);
        border-color: rgb(111, 137, 0);
    }
    .ant-tag-lime-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(129, 176, 14);
        border-color: rgb(127, 173, 13);
    }
    .ant-tag-green {
        color: rgb(154, 245, 114);
        background-image: initial;
        background-color: rgb(39, 63, 0);
        border-color: rgb(65, 121, 21);
    }
    .ant-tag-green-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(67, 159, 21);
        border-color: rgb(71, 168, 22);
    }
    .ant-tag-blue {
        color: rgb(82, 174, 250);
        background-image: initial;
        background-color: rgb(0, 45, 67);
        border-color: rgb(0, 84, 136);
    }
    .ant-tag-blue-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
    }
    .ant-tag-geekblue {
        color: rgb(99, 157, 234);
        background-image: initial;
        background-color: rgb(28, 31, 32);
        border-color: rgb(0, 39, 128);
    }
    .ant-tag-geekblue-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(16, 47, 173);
        border-color: rgb(15, 43, 157);
    }
    .ant-tag-purple {
        color: rgb(152, 102, 231);
        background-image: initial;
        background-color: rgb(28, 31, 32);
        border-color: rgb(67, 11, 119);
    }
    .ant-tag-purple-inverse {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(92, 37, 169);
        border-color: rgb(81, 32, 147);
    }
    .ant-tag-success {
        color: rgb(130, 234, 80);
        background-image: initial;
        background-color: rgb(39, 63, 0);
        border-color: rgb(65, 121, 21);
    }
    .ant-tag-processing {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: rgb(0, 45, 67);
        border-color: rgb(0, 84, 136);
    }
    .ant-tag-error {
        color: rgb(248, 57, 67);
        background-image: initial;
        background-color: rgb(61, 4, 0);
        border-color: rgb(132, 7, 0);
    }
    .ant-tag-warning {
        color: rgb(254, 154, 45);
        background-image: initial;
        background-color: rgb(67, 45, 0);
        border-color: rgb(136, 84, 0);
    }
    .ant-card {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-card-hoverable:hover {
        border-color: transparent;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-bordered {
        border-color: rgb(53, 58, 60);
    }
    .ant-card-head {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: transparent;
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-card-head .ant-tabs {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-card-head .ant-tabs-bar {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-card-extra {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-card-grid {
        border-color: initial;
        box-shadow: rgb(32, 35, 37) 1px 0px 0px 0px,
        rgb(32, 35, 37) 0px 1px 0px 0px,
        rgb(32, 35, 37) 1px 1px 0px 0px,
        rgb(32, 35, 37) 1px 0px 0px 0px inset,
        rgb(32, 35, 37) 0px 1px 0px 0px inset;
    }
    .ant-card-grid-hoverable:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
        rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
        rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
    }
    .ant-card-actions {
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-top-color: rgb(53, 58, 60);
    }
    .ant-card-actions > li {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-card-actions > li > span:hover {
        color: rgb(47, 165, 255);
    }
    .ant-card-actions > li > span a:not(.ant-btn),
    .ant-card-actions > li > span > .anticon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-card-actions > li > span a:not(.ant-btn):hover,
    .ant-card-actions > li > span > .anticon:hover {
        color: rgb(47, 165, 255);
    }
    .ant-card-actions > li:not(:last-child) {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-card-rtl .ant-card-actions > li:not(:last-child) {
        border-right-color: initial;
        border-left-color: rgb(53, 58, 60);
    }
    .ant-card-type-inner .ant-card-head {
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-card-meta-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-card-meta-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-card-loading-block {
        background-image: linear-gradient(90deg,
        rgba(43, 54, 59, 0.2),
        rgba(43, 54, 59, 0.4),
        rgba(43, 54, 59, 0.2));
        background-color: initial;
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 10px 0px 8px -8px inset;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) -10px 0px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -10px 8px -8px inset;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-tabs-right > .ant-tabs-content-holder,
    .ant-tabs-right > div > .ant-tabs-content-holder {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-tabs-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-tabs-dropdown-menu {
        background-color: rgb(24, 26, 27);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-tabs-dropdown-menu-item {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-tabs-dropdown-menu-item:hover {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-tabs-dropdown-menu-item-disabled,
    .ant-tabs-dropdown-menu-item-disabled:hover {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: rgb(53, 58, 60);
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: rgb(47, 165, 255);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom-color: rgb(48, 53, 55);
    }
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-top-color: rgb(48, 53, 55);
    }
    .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-right-color: rgb(48, 53, 55);
    }
    .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-left-color: rgb(48, 53, 55);
    }
    .ant-tabs {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: rgb(53, 58, 60);
        outline-color: initial;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
        color: rgb(84, 183, 255);
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
        color: rgb(82, 174, 250);
    }
    .ant-tabs-ink-bar {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-tabs-tab {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: rgb(82, 174, 250);
    }
    .ant-tabs-tab-btn {
        outline-color: initial;
    }
    .ant-tabs-tab-remove {
        color: rgba(255, 255, 255, 0.45);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-tabs-tab-remove:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-tabs-tab:hover {
        color: rgb(84, 183, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: rgb(47, 165, 255);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
    .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-tabs-tabpane {
        outline-color: initial;
    }
    .ant-carousel {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-carousel .slick-slider {
        -webkit-tap-highlight-color: transparent;
    }
    .ant-carousel .slick-list:focus {
        outline-color: initial;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
        color: transparent;
        background-image: initial;
        background-color: transparent;
        outline-color: initial;
    }
    .ant-carousel .slick-dots {
        list-style-image: initial;
    }
    .ant-carousel .slick-dots li button {
        color: transparent;
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: initial;
        outline-color: initial;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-cascader {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-cascader-input.ant-input {
        background-color: transparent !important;
    }
    .ant-cascader-picker {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
        outline-color: initial;
    }
    .ant-cascader-picker-with-value .ant-cascader-picker-label {
        color: transparent;
    }
    .ant-cascader-picker-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-cascader-picker-borderless .ant-cascader-input {
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-cascader-picker-show-search.ant-cascader-picker-focused {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-cascader-picker-clear {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-cascader-picker-clear:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-cascader-picker-arrow {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
        border-color: rgb(0, 89, 161);
    }
    .ant-cascader-menus {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-cascader-menus ul,
    .ant-cascader-menus ol {
        list-style-image: initial;
    }
    .ant-cascader-menu {
        list-style-image: initial;
        border-right-color: rgb(53, 58, 60);
    }
    .ant-cascader-menu:last-child {
        border-right-color: transparent;
    }
    .ant-cascader-menu-item:hover {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-cascader-menu-item-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-cascader-menu-item-disabled:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-cascader-menu-empty .ant-cascader-menu-item {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: rgb(0, 45, 67);
    }
    .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-loading-icon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
    .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
        color: rgb(255, 96, 98);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu {
        border-right-color: initial;
        border-left-color: rgb(53, 58, 60);
    }
    .ant-cascader-menu-rtl .ant-cascader-menu:last-child {
        border-left-color: transparent;
    }
    .ant-input-affix-wrapper {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        background-image: none;
        border-color: rgb(60, 65, 67);
    }
    .ant-input-affix-wrapper::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-input-affix-wrapper:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-affix-wrapper-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-affix-wrapper-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-affix-wrapper[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-affix-wrapper[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-affix-wrapper-borderless,
    .ant-input-affix-wrapper-borderless:hover,
    .ant-input-affix-wrapper-borderless:focus,
    .ant-input-affix-wrapper-borderless-focused,
    .ant-input-affix-wrapper-borderless-disabled,
    .ant-input-affix-wrapper-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        background-image: initial;
        background-color: transparent;
    }
    .ant-input-affix-wrapper > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-affix-wrapper > input.ant-input:focus {
        box-shadow: none;
    }
    .ant-input-clear-icon {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-input-clear-icon:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-clear-icon:active {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        border-color: initial !important;
    }
    .ant-input {
        list-style-image: initial;
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        background-image: none;
        border-color: rgb(60, 65, 67);
    }
    .ant-input::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-input:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-borderless,
    .ant-input-borderless:hover,
    .ant-input-borderless:focus,
    .ant-input-borderless-focused,
    .ant-input-borderless-disabled,
    .ant-input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-group {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-input-group-addon {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(27, 29, 30);
        border-color: rgb(60, 65, 67);
    }
    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: inherit;
        border-color: transparent;
        box-shadow: none;
    }
    .ant-input-group-addon .ant-select-open .ant-select-selector,
    .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: rgb(47, 165, 255);
    }
    .ant-input-group-addon:first-child {
        border-right-color: initial;
    }
    .ant-input-group-addon:last-child {
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:first-child {
        border-right-color: rgb(60, 65, 67);
        border-left-color: initial;
    }
    .ant-input-group-rtl .ant-input-group-addon:last-child {
        border-right-color: initial;
        border-left-color: rgb(60, 65, 67);
    }
    .ant-input-password-icon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-password-icon:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-input-textarea-show-count::after {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-search .ant-input:hover,
    .ant-input-search .ant-input:focus {
        border-color: rgb(0, 89, 161);
    }
    .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-left-color: rgb(0, 89, 161);
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        border-color: initial;
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
        border-right-color: rgb(0, 89, 161);
        border-left-color: rgb(60, 65, 67);
    }
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
    .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
        border-right-color: rgb(0, 89, 161);
    }
    .ant-checkbox {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: rgb(0, 90, 173);
    }
    .ant-checkbox-checked::after {
        border-color: rgb(0, 90, 173);
    }
    .ant-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67) !important;
    }
    .ant-checkbox-disabled .ant-checkbox-inner::after {
        border-color: rgb(52, 56, 58);
    }
    .ant-checkbox-disabled + span {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-checkbox-wrapper {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-group {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: rgb(0, 99, 192);
        border-color: initial;
    }
    .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-collapse {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(27, 29, 30);
        border-top-color: rgb(60, 65, 67);
        border-right-color: rgb(60, 65, 67);
        border-left-color: rgb(60, 65, 67);
        border-bottom-color: initial;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom-color: rgb(60, 65, 67);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        color: inherit;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
        outline-color: initial;
    }
    .ant-collapse-content {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        border-top-color: rgb(60, 65, 67);
    }
    .ant-collapse-borderless {
        background-color: rgb(27, 29, 30);
        border-color: initial;
    }
    .ant-collapse-borderless > .ant-collapse-item {
        border-bottom-color: rgb(60, 65, 67);
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse-ghost {
        background-color: transparent;
        border-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom-color: initial;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: transparent;
        border-top-color: initial;
    }
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-comment {
        background-color: inherit;
    }
    .ant-comment-content-author-name {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-comment-content-author-name > * {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-comment-content-author-name > :hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-comment-content-author-time {
        color: rgb(211, 211, 211);
    }
    .ant-comment-actions > li {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-comment-actions > li > span {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-comment-actions > li > span:hover {
        color: rgb(177, 177, 177);
    }
    .ant-descriptions-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-descriptions-extra {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-item-label {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-descriptions-item-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border-color: rgb(53, 58, 60);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-right-color: initial;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color: rgb(27, 29, 30);
    }
    .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-descriptions-bordered .ant-descriptions-row:last-child {
        border-bottom-color: initial;
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: initial;
        border-left-color: rgb(53, 58, 60);
    }
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
    .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
        border-left-color: initial;
    }
    .ant-divider {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        border-top-color: rgba(129, 129, 129, 0.06);
    }
    .ant-divider-vertical {
        border-top-color: initial;
        border-left-color: rgba(129, 129, 129, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text {
        color: rgba(255, 255, 255, 0.85);
        border-top-color: rgba(129, 129, 129, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-top-color: inherit;
        border-bottom-color: initial;
    }
    .ant-divider-dashed {
        background-image: none;
        background-color: initial;
        border-color: rgba(129, 129, 129, 0.06);
    }
    .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
        border-top-color: initial;
    }
    .ant-divider-plain.ant-divider-with-text {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) 9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) 12px 0px 48px 16px;
    }
    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) -6px 0px 16px -8px,
        rgba(0, 0, 0, 0.05) -9px 0px 28px 0px,
        rgba(0, 0, 0, 0.03) -12px 0px 48px 16px;
    }
    .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px 12px 48px 16px;
    }
    .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: rgba(0, 0, 0, 0.08) 0px -6px 16px -8px,
        rgba(0, 0, 0, 0.05) 0px -9px 28px 0px,
        rgba(0, 0, 0, 0.03) 0px -12px 48px 16px;
    }
    .ant-drawer-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-drawer-content {
        background-color: rgb(24, 26, 27);
        border-color: initial;
    }
    .ant-drawer-close {
        color: rgba(255, 255, 255, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-drawer-close:focus,
    .ant-drawer-close:hover {
        color: rgba(255, 255, 255, 0.75);
        text-decoration-color: initial;
    }
    .ant-drawer-header {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-drawer-header-no-title {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-drawer-footer {
        border-top-color: rgb(53, 58, 60);
    }
    .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-drawer-open-content {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-drawer .ant-picker-clear {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-form-item .ant-upload {
        background-image: initial;
        background-color: transparent;
    }
    .ant-form-item .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-explain.ant-form-item-explain-warning {
        color: rgb(254, 183, 44);
    }
    .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(130, 234, 80);
    }
    .ant-form-item-has-warning .ant-form-item-split {
        color: rgb(254, 183, 44);
    }
    .ant-form-item-has-warning .ant-input,
    .ant-form-item-has-warning .ant-input-affix-wrapper,
    .ant-form-item-has-warning .ant-input:hover,
    .ant-form-item-has-warning .ant-input-affix-wrapper:hover {
        background-color: rgb(24, 26, 27);
        border-color: rgb(172, 116, 4);
    }
    .ant-form-item-has-warning .ant-input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
    .ant-form-item-has-warning .ant-input-focused,
    .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
        border-color: rgb(162, 113, 0);
        outline-color: initial;
        box-shadow: rgba(193, 129, 4, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-disabled,
    .ant-form-item-has-warning .ant-input-disabled:hover {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(162, 113, 0);
        outline-color: initial;
        box-shadow: rgba(193, 129, 4, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-prefix {
        color: rgb(254, 183, 44);
    }
    .ant-form-item-has-warning .ant-input-group-addon {
        color: rgb(254, 183, 44);
        border-color: rgb(172, 116, 4);
    }
    .ant-form-item-has-warning .has-feedback {
        color: rgb(254, 183, 44);
    }
    .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(254, 183, 44);
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(24, 26, 27);
        border-color: rgb(172, 116, 4) !important;
    }
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(162, 113, 0);
        outline-color: initial;
        box-shadow: rgba(193, 129, 4, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number,
    .ant-form-item-has-warning .ant-picker {
        background-color: rgb(24, 26, 27);
        border-color: rgb(172, 116, 4);
    }
    .ant-form-item-has-warning .ant-input-number-focused,
    .ant-form-item-has-warning .ant-picker-focused,
    .ant-form-item-has-warning .ant-input-number:focus,
    .ant-form-item-has-warning .ant-picker:focus {
        border-color: rgb(162, 113, 0);
        outline-color: initial;
        box-shadow: rgba(193, 129, 4, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
        background-color: rgb(24, 26, 27);
        border-color: rgb(172, 116, 4);
    }
    .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
        border-color: rgb(162, 113, 0);
        outline-color: initial;
        box-shadow: rgba(193, 129, 4, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-form-item-split {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper:focus,
    .ant-form-item-has-error .ant-input-focused,
    .ant-form-item-has-error .ant-input-affix-wrapper-focused {
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-disabled,
    .ant-form-item-has-error .ant-input-disabled:hover {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover input:focus {
        box-shadow: none !important;
    }
    .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-prefix {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-input-group-addon {
        color: rgb(255, 96, 98);
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .has-feedback {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2) !important;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: initial;
    }
    .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus {
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
    .ant-form-item-has-error .ant-picker:not([disabled]):hover {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
    .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
        background-color: rgb(24, 26, 27);
        border-color: rgb(144, 3, 0);
        outline-color: initial;
        box-shadow: rgba(160, 0, 2, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-transfer-list {
        border-color: rgb(157, 0, 2);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
        border-color: rgb(60, 65, 67);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper {
        border-color: rgb(157, 0, 2) !important;
    }
    .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: rgb(160, 0, 2);
    }
    .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
        color: rgb(47, 165, 255);
    }
    .ant-form {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-form legend {
        color: rgba(255, 255, 255, 0.45);
        border-top-color: initial;
        border-right-color: initial;
        border-left-color: initial;
        border-bottom-color: rgb(60, 65, 67); 
    }
    .ant-form input[type="file"]:focus, .ant-form input[type="radio"]:focus, .ant-form input[type="checkbox"]:focus {
        outline-color: rgb(188, 124, 0);
    }
    .ant-form output {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-form-item {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-form-item-label > label {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: rgb(255, 96, 98);
    }
    .ant-form-item-label > label .ant-form-item-optional {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-form-item-label > label .ant-form-item-tooltip {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-image-img-placeholder {
        background-color: rgb(30, 32, 33);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
    }
    .ant-image-mask {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-image-preview-wrap {
        outline-color: initial;
    }
    .ant-image-preview-operations {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-operations-operation-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-image-preview-switch-left,
    .ant-image-preview-switch-right {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-image-preview-switch-left-disabled,
    .ant-image-preview-switch-right-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-input-number {
        list-style-image: initial;
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        background-image: none;
        border-color: rgb(60, 65, 67);
    }
    .ant-input-number::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-input-number:focus,
    .ant-input-number-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-number[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-number[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-number-borderless,
    .ant-input-number-borderless:hover,
    .ant-input-number-borderless:focus,
    .ant-input-number-borderless-focused,
    .ant-input-number-borderless-disabled,
    .ant-input-number-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-input-number-handler {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-number-handler:active {
        background-image: initial;
        background-color: rgb(30, 33, 34);
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: rgb(84, 183, 255);
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-input-number:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-input-number-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-input-number-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-input-number-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-input-number-input {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-input-number-input::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-input-number-handler-wrap {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-left-color: rgb(60, 65, 67);
    }
    .ant-input-number-handler-down {
        border-top-color: rgb(60, 65, 67);
    }
    .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-input-number-borderless {
        box-shadow: none;
    }
    .ant-input-number-out-of-range input {
        color: rgb(255, 96, 98);
    }
    .ant-input-number-rtl .ant-input-number-handler-wrap {
        border-right-color: rgb(60, 65, 67);
        border-left-color: initial;
    }
    .ant-layout {
        background-image: initial;
        background-color: rgb(31, 34, 35);
    }
    .ant-layout-header {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(0, 17, 33);
    }
    .ant-layout-footer {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(31, 34, 35);
    }
    .ant-layout-sider {
        background-image: initial;
        background-color: rgb(0, 17, 33);
    }
    .ant-layout-sider-trigger {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 26, 52);
    }
    .ant-layout-sider-zero-width-trigger {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: rgb(0, 17, 33);
    }
    .ant-layout-sider-zero-width-trigger::after {
        background-image: initial;
        background-color: transparent;
    }
    .ant-layout-sider-zero-width-trigger:hover::after {
        background-image: initial;
        background-color: rgba(24, 26, 27, 0.1);
    }
    .ant-layout-sider-light {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-layout-sider-light .ant-layout-sider-trigger {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-list {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-list * {
        outline-color: initial;
    }
    .ant-list-empty-text {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-list-items {
        list-style-image: initial;
    }
    .ant-list-item {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-list-item-meta-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-list-item-meta-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-list-item-meta-title > a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-list-item-meta-title > a:hover {
        color: rgb(47, 165, 255);
    }
    .ant-list-item-meta-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-list-item-action {
        list-style-image: initial;
    }
    .ant-list-item-action > li {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-list-item-action-split {
        background-color: rgb(32, 35, 37);
    }
    .ant-list-header {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-footer {
        background-image: initial;
        background-color: transparent;
    }
    .ant-list-empty {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-list-split .ant-list-item {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-list-split .ant-list-item:last-child {
        border-bottom-color: initial;
    }
    .ant-list-split .ant-list-header {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-list-split.ant-list-empty .ant-list-footer {
        border-top-color: rgb(53, 58, 60);
    }
    .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-list-vertical .ant-list-item-meta-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-list-grid .ant-col > .ant-list-item {
        border-bottom-color: initial;
    }
    .ant-list-bordered {
        border-color: rgb(60, 65, 67);
    }
    .ant-spin {
        list-style-image: initial;
        color: rgb(47, 165, 255);
    }
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
        text-shadow: rgb(24, 26, 27) 0px 1px 2px;
    }
    .ant-spin-container::after {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-spin-tip {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-spin-dot-item {
        background-color: rgb(0, 99, 192);
    }
    @media not all,
    not all {
        .ant-spin-blur {
            background-image: initial;
            background-color: rgb(24, 26, 27);
        }
    }
    .ant-pagination {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-pagination ul,
    .ant-pagination ol {
        list-style-image: initial;
    }
    .ant-pagination-item {
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
        outline-color: initial;
    }
    .ant-pagination-item a {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-pagination-item a:hover {
        text-decoration-color: initial;
    }
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: rgb(0, 90, 173);
    }
    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
        color: rgb(47, 165, 255);
    }
    .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 90, 173);
    }
    .ant-pagination-item-active a {
        color: rgb(47, 165, 255);
    }
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-pagination-item-active:focus-visible a,
    .ant-pagination-item-active:hover a {
        color: rgb(84, 183, 255);
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        outline-color: initial;
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: rgb(47, 165, 255);
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        outline-color: initial;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-pagination-prev:hover button,
    .ant-pagination-next:hover button {
        border-color: rgb(0, 89, 161);
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
        outline-color: initial;
    }
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: rgb(47, 165, 255);
        border-color: rgb(0, 90, 173);
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: rgba(255, 255, 255, 0.25);
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination-options-quick-jumper input {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        background-image: none;
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination-options-quick-jumper input::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-pagination-options-quick-jumper input:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-pagination-options-quick-jumper input:focus,
    .ant-pagination-options-quick-jumper input-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-pagination-options-quick-jumper input-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-pagination-options-quick-jumper input-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination-options-quick-jumper input[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-pagination-options-quick-jumper input[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination-options-quick-jumper input-borderless,
    .ant-pagination-options-quick-jumper input-borderless:hover,
    .ant-pagination-options-quick-jumper input-borderless:focus,
    .ant-pagination-options-quick-jumper input-borderless-focused,
    .ant-pagination-options-quick-jumper input-borderless-disabled,
    .ant-pagination-options-quick-jumper input-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
        outline-color: initial;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input:hover {
        border-color: rgb(0, 90, 173);
    }
    .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item {
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
        background-image: initial;
        background-color: rgb(44, 48, 51);
        border-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
        color: rgb(255, 255, 255);
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        color: rgba(255, 255, 255, 0.25);
        background-image: initial;
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67);
    }
    .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
        background-image: initial;
        background-color: transparent;
    }
    .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-mentions {
        list-style-image: initial;
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(24, 26, 27);
        background-image: none;
        border-color: rgb(60, 65, 67);
    }
    .ant-mentions::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-mentions:hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-mentions:focus,
    .ant-mentions-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions-disabled {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-mentions-disabled:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-mentions[disabled] {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-mentions[disabled]:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-mentions-borderless,
    .ant-mentions-borderless:hover,
    .ant-mentions-borderless:focus,
    .ant-mentions-borderless-focused,
    .ant-mentions-borderless-disabled,
    .ant-mentions-borderless[disabled] {
        background-color: transparent;
        border-color: initial;
        box-shadow: none;
    }
    .ant-mentions-disabled > textarea {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(30, 32, 33);
    }
    .ant-mentions-disabled > textarea:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-mentions-focused {
        border-color: rgb(0, 89, 161);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-mentions > textarea {
        border-color: initial;
        outline-color: initial;
    }
    .ant-mentions > textarea::placeholder {
        color: rgb(199, 199, 199);
    }
    .ant-mentions-measure {
        color: transparent;
    }
    .ant-mentions-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-mentions-dropdown-menu {
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-mentions-dropdown-menu-item {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-mentions-dropdown-menu-item:hover {
        background-color: rgb(30, 32, 33);
    }
    .ant-mentions-dropdown-menu-item-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-mentions-dropdown-menu-item-disabled:hover {
        color: rgba(255, 255, 255, 0.25);
        background-color: rgb(24, 26, 27);
    }
    .ant-mentions-dropdown-menu-item-selected {
        color: rgba(255, 255, 255, 0.85);
        background-color: rgb(27, 29, 30);
    }
    .ant-mentions-dropdown-menu-item-active {
        background-color: rgb(30, 32, 33);
    }
    .ant-message {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-message-notice-content {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-message-success .anticon {
        color: rgb(130, 234, 80);
    }
    .ant-message-error .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-message-warning .anticon {
        color: rgb(254, 183, 44);
    }
    .ant-message-info .anticon,
    .ant-message-loading .anticon {
        color: rgb(47, 165, 255);
    }
    .ant-modal {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.45);
    }
    .ant-modal-wrap {
        outline-color: initial;
    }
    .ant-modal-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-content {
        background-color: rgb(24, 26, 27);
        border-color: initial;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-modal-close {
        color: rgba(255, 255, 255, 0.45);
        text-decoration-color: initial;
        background-image: initial;
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .ant-modal-close:focus,
    .ant-modal-close:hover {
        color: rgba(255, 255, 255, 0.75);
        text-decoration-color: initial;
    }
    .ant-modal-header {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-modal-footer {
        background-image: initial;
        background-color: transparent;
        border-top-color: rgb(53, 58, 60);
    }
    .ant-modal-confirm-body .ant-modal-confirm-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-confirm-body .ant-modal-confirm-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
    .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
        color: rgb(254, 183, 44);
    }
    .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
        color: rgb(47, 165, 255);
    }
    .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
        color: rgb(130, 234, 80);
    }
    .ant-notification {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-notification-hook-holder > .ant-notification-notice {
        box-shadow: none;
    }
    .ant-notification-notice-message {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-notification-notice-message-single-line-auto-margin {
        background-color: transparent;
    }
    .anticon.ant-notification-notice-icon-success {
        color: rgb(130, 234, 80);
    }
    .anticon.ant-notification-notice-icon-info {
        color: rgb(47, 165, 255);
    }
    .anticon.ant-notification-notice-icon-warning {
        color: rgb(254, 183, 44);
    }
    .anticon.ant-notification-notice-icon-error {
        color: rgb(255, 96, 98);
    }
    .ant-notification-notice-close {
        color: rgba(255, 255, 255, 0.45);
        outline-color: initial;
    }
    .ant-notification-notice-close:hover {
        color: rgba(255, 255, 255, 0.67);
    }
    .ant-page-header {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-page-header-ghost {
        background-color: inherit;
    }
    .ant-page-header-back-button {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(255, 255, 255);
    }
    .ant-page-header-back-button:focus,
    .ant-page-header-back-button:hover {
        color: rgb(84, 183, 255);
    }
    .ant-page-header-back-button:active {
        color: rgb(82, 174, 250);
    }
    .ant-page-header-heading-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-page-header-heading-sub-title {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
        border-color: initial;
    }
    .ant-progress {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-progress-steps-item {
        background-image: initial;
        background-color: rgb(31, 33, 35);
    }
    .ant-progress-steps-item-active {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-progress-inner {
        background-color: rgb(30, 32, 33);
    }
    .ant-progress-circle-trail {
        stroke: rgb(248, 248, 248);
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(47, 165, 255);
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
        background-color: rgb(0, 99, 192);
    }
    .ant-progress-success-bg {
        background-color: rgb(67, 159, 21);
    }
    .ant-progress-text {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-progress-status-active .ant-progress-bg::before {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-progress-status-exception .ant-progress-bg {
        background-color: rgb(160, 0, 2);
    }
    .ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 96, 98);
    }
    .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(255, 96, 98);
    }
    .ant-progress-status-success .ant-progress-bg {
        background-color: rgb(67, 159, 21);
    }
    .ant-progress-status-success .ant-progress-text {
        color: rgb(130, 234, 80);
    }
    .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
        stroke: rgb(130, 234, 80);
    }
    .ant-progress-circle .ant-progress-inner {
        background-color: transparent;
    }
    .ant-progress-circle .ant-progress-text {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
        color: rgb(255, 96, 98);
    }
    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
        color: rgb(130, 234, 80);
    }
    .ant-rate {
        color: rgb(254, 225, 44);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-rate-star {
        color: inherit;
    }
    .ant-rate-star > div:focus {
        outline-color: initial;
    }
    .ant-rate-star-first,
    .ant-rate-star-second {
        color: rgb(244, 244, 244);
    }
    .ant-rate-star-half .ant-rate-star-first,
    .ant-rate-star-full .ant-rate-star-second {
        color: inherit;
    }
    .ant-result-success .ant-result-icon > .anticon {
        color: rgb(130, 234, 80);
    }
    .ant-result-error .ant-result-icon > .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-result-info .ant-result-icon > .anticon {
        color: rgb(47, 165, 255);
    }
    .ant-result-warning .ant-result-icon > .anticon {
        color: rgb(254, 183, 44);
    }
    .ant-result-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-result-subtitle {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-result-content {
        background-color: rgb(27, 29, 30);
    }
    .ant-skeleton-header .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-title {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li {
        list-style-image: initial;
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
        background-image: linear-gradient(90deg,
        rgba(62, 67, 69, 0.2) 25%,
        rgba(96, 104, 108, 0.24) 37%,
        rgba(62, 67, 69, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        background-image: linear-gradient(90deg,
        rgba(62, 67, 69, 0.2) 25%,
        rgba(96, 104, 108, 0.24) 37%,
        rgba(62, 67, 69, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
        background-image: linear-gradient(90deg,
        rgba(62, 67, 69, 0.2) 25%,
        rgba(96, 104, 108, 0.24) 37%,
        rgba(62, 67, 69, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
        background-image: linear-gradient(90deg,
        rgba(62, 67, 69, 0.2) 25%,
        rgba(96, 104, 108, 0.24) 37%,
        rgba(62, 67, 69, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
        background-image: linear-gradient(90deg,
        rgba(62, 67, 69, 0.2) 25%,
        rgba(96, 104, 108, 0.24) 37%,
        rgba(62, 67, 69, 0.2) 63%);
        background-color: initial;
    }
    .ant-skeleton-element .ant-skeleton-button {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-avatar {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-input {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image {
        background-image: initial;
        background-color: rgba(62, 67, 69, 0.2);
    }
    .ant-skeleton-element .ant-skeleton-image-path {
        fill: rgb(199, 199, 199);
    }
    .ant-slider {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-slider-rail {
        background-color: rgb(30, 32, 33);
    }
    .ant-slider-track {
        background-color: rgb(0, 73, 118);
    }
    .ant-slider-handle {
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 84, 136);
    }
    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
        border-color: rgb(0, 83, 160);
        box-shadow: rgba(0, 99, 192, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle:focus {
        border-color: rgb(0, 83, 160);
        outline-color: initial;
        box-shadow: rgba(0, 99, 192, 0.12) 0px 0px 0px 5px;
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: rgb(0, 90, 173);
    }
    .ant-slider:hover .ant-slider-rail {
        background-color: rgb(41, 44, 46);
    }
    .ant-slider:hover .ant-slider-track {
        background-color: rgb(0, 83, 142);
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: rgb(0, 86, 148);
    }
    .ant-slider-mark-text {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-slider-mark-text-active {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-slider-step {
        background-image: initial;
        background-color: transparent;
    }
    .ant-slider-dot {
        background-color: rgb(24, 26, 27);
        border-color: rgb(53, 58, 60);
    }
    .ant-slider-dot-active {
        border-color: rgb(0, 72, 138);
    }
    .ant-slider-disabled .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-slider-disabled .ant-slider-handle,
    .ant-slider-disabled .ant-slider-dot {
        background-color: rgb(24, 26, 27);
        box-shadow: none;
        border-color: rgba(129, 129, 129, 0.25) !important;
    }
    .ant-statistic {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-statistic-title {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-statistic-content {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-steps {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-steps-item-container {
        outline-color: initial;
    }
    .ant-steps-item-icon {
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-steps-item-icon .ant-steps-icon {
        color: rgb(47, 165, 255);
    }
    .ant-steps-item-tail::after {
        background-image: initial;
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-subtitle {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: rgb(24, 26, 27);
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-process .ant-steps-item-icon {
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 90, 173);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(47, 165, 255);
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
        color: rgb(255, 255, 255);
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: rgb(24, 26, 27);
        border-color: rgb(0, 90, 173);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(47, 165, 255);
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(0, 99, 192);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(0, 99, 192);
    }
    .ant-steps-item-error .ant-steps-item-icon {
        background-color: rgb(24, 26, 27);
        border-color: rgb(157, 0, 2);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-image: initial;
        background-color: rgb(160, 0, 2);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgb(255, 96, 98);
    }
    .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: rgb(32, 35, 37);
    }
    .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
        background-image: initial;
        background-color: rgb(160, 0, 2);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
        color: rgb(47, 165, 255);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
        border-color: rgb(0, 90, 173);
    }
    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
        color: rgb(47, 165, 255);
    }
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
        color: rgb(47, 165, 255);
    }
    .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-small .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
        background-image: initial;
        background-color: rgba(0, 0, 0, 0);
    }
    .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        background-image: none;
        background-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::after {
        border-top-color: rgba(129, 129, 129, 0.25);
        border-right-color: rgba(129, 129, 129, 0.25);
        border-bottom-color: initial;
        border-left-color: initial;
    }
    .ant-steps-navigation .ant-steps-item::before {
        background-color: rgb(0, 99, 192);
    }
    .ant-switch {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgba(0, 0, 0, 0.25);
        border-color: initial;
    }
    .ant-switch:focus {
        outline-color: initial;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
    }
    .ant-switch-checked:focus {
        box-shadow: rgba(0, 99, 192, 0.2) 0px 0px 0px 2px;
    }
    .ant-switch:focus:hover {
        box-shadow: none;
    }
    .ant-switch-checked {
        background-color: rgb(0, 99, 192);
    }
    .ant-switch-loading *,
    .ant-switch-disabled * {
        box-shadow: none;
    }
    .ant-switch-inner {
        color: rgb(255, 255, 255);
    }
    .ant-switch-handle::before {
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 28, 9, 0.2) 0px 2px 4px 0px;
    }
    .ant-switch-loading-icon {
        color: rgba(255, 255, 255, 0.65);
    }
    .ant-switch-checked .ant-switch-loading-icon {
        color: rgb(47, 165, 255);
    }
    .ant-table-small .ant-table-thead > tr > th {
        background-color: rgb(27, 29, 30);
    }
    .ant-table.ant-table-bordered > .ant-table-title {
        border-top-color: rgb(53, 58, 60);
        border-right-color: rgb(53, 58, 60);
        border-left-color: rgb(53, 58, 60);
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
        border-top-color: rgb(53, 58, 60);
        border-left-color: rgb(53, 58, 60);
        border-right-color: initial;
        border-bottom-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
        border-right-color: rgb(53, 58, 60);
    }
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
    .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
        border-right-color: initial;
    }
    .ant-table.ant-table-bordered > .ant-table-footer {
        border-right-color: rgb(53, 58, 60);
        border-bottom-color: rgb(53, 58, 60);
        border-left-color: rgb(53, 58, 60);
        border-top-color: initial;
    }
    .ant-table-cell .ant-table-container:first-child {
        border-top-color: initial;
    }
    .ant-table-cell-scrollbar {
        box-shadow: rgb(27, 29, 30) 0px 1px 0px 1px;
    }
    .ant-table {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-table-footer {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-table-thead > tr > th {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-table-thead > tr:not(:last-child) > th[colspan] {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr > td {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
        border-bottom-color: initial;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background-image: initial;
        background-color: rgb(0, 45, 67);
        border-color: rgba(129, 129, 129, 0.03);
    }
    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background-image: initial;
        background-color: rgb(0, 49, 73);
    }
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-image: initial;
        background-color: rgb(31, 34, 35);
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
        background-image: initial;
        background-color: rgb(29, 31, 32);
    }
    .ant-table-thead th.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    td.ant-table-column-sort {
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-table-column-sorter {
        color: rgb(199, 199, 199);
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: rgb(47, 165, 255);
    }
    .ant-table-filter-trigger-container-open,
    .ant-table-filter-trigger-container:hover,
    .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
        background-image: initial;
        background-color: rgb(39, 42, 44);
    }
    .ant-table-filter-trigger {
        color: rgb(199, 199, 199);
    }
    .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
    .ant-table-filter-trigger:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-table-filter-trigger.active {
        color: rgb(47, 165, 255);
    }
    .ant-table-filter-dropdown {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-color: rgb(24, 26, 27);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
        rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
        rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
    }
    .ant-table-filter-dropdown .ant-dropdown-menu {
        border-color: initial;
        box-shadow: none;
    }
    .ant-table-filter-dropdown-btns {
        background-color: inherit;
        border-top-color: rgb(53, 58, 60);
    }
    .ant-table-selection-extra .anticon {
        color: rgb(199, 199, 199);
    }
    .ant-table-selection-extra .anticon:hover {
        color: rgb(177, 177, 177);
    }
    .ant-table-row-expand-icon {
        text-decoration-color: initial;
        color: inherit;
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-color: rgb(53, 58, 60);
        outline-color: initial;
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover {
        color: rgb(84, 183, 255);
    }
    .ant-table-row-expand-icon:active {
        color: rgb(82, 174, 250);
    }
    .ant-table-row-expand-icon:focus,
    .ant-table-row-expand-icon:hover,
    .ant-table-row-expand-icon:active {
        border-color: currentcolor;
    }
    .ant-table-row-expand-icon::before,
    .ant-table-row-expand-icon::after {
        background-image: initial;
        background-color: currentcolor;
    }
    .ant-table-row-expand-icon-spaced {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background-image: initial;
        background-color: rgb(26, 28, 30);
    }
    .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-left .ant-table-cell-fix-left-first::after,
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) 10px 0px 8px -8px inset;
    }
    .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-ping-right .ant-table-cell-fix-right-first::after,
    .ant-table-ping-right .ant-table-cell-fix-right-last::after {
        box-shadow: rgba(0, 0, 0, 0.15) -10px 0px 8px -8px inset;
    }
    .ant-table-sticky-scroll {
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-top-color: rgb(53, 58, 60);
    }
    .ant-table-sticky-scroll-bar {
        background-color: rgba(0, 0, 0, 0.35);
    }
    .ant-table-sticky-scroll-bar:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ant-table-sticky-scroll-bar-active {
        background-color: rgba(0, 0, 0, 0.8);
    }
    @media not all {
        .ant-table-ping-left .ant-table-cell-fix-left-last::after {
            box-shadow: none !important;
        }
        .ant-table-ping-right .ant-table-cell-fix-right-first::after {
            box-shadow: none !important;
        }
    }
    .ant-timeline {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-timeline-item {
        list-style-image: initial;
    }
    .ant-timeline-item-tail {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-timeline-item-pending .ant-timeline-item-head {
        background-color: transparent;
    }
    .ant-timeline-item-head {
        background-color: rgb(24, 26, 27);
        border-color: transparent;
    }
    .ant-timeline-item-head-blue {
        color: rgb(47, 165, 255);
        border-color: rgb(0, 90, 173);
    }
    .ant-timeline-item-head-red {
        color: rgb(255, 96, 98);
        border-color: rgb(157, 0, 2);
    }
    .ant-timeline-item-head-green {
        color: rgb(130, 234, 80);
        border-color: rgb(71, 168, 22);
    }
    .ant-timeline-item-head-gray {
        color: rgba(255, 255, 255, 0.25);
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-timeline-item-head-custom {
        border-color: initial;
    }
    .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-left-color: rgb(53, 58, 60);
    }
    .ant-timeline-rtl .ant-timeline-item-tail {
        border-right-color: rgb(53, 58, 60);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
        border-right-color: rgb(53, 58, 60);
        border-left-color: initial;
    }
    .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
        border-right-color: rgb(53, 58, 60);
        border-left-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
        border-color: initial;
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
        background-image: initial;
        background-color: rgb(27, 29, 30);
    }
    .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-transfer-customize-list .ant-input[disabled] {
        background-color: transparent;
    }
    .ant-transfer {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-transfer-disabled .ant-transfer-list {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-transfer-list {
        border-color: rgb(60, 65, 67);
    }
    .ant-transfer-list-search-action {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-transfer-list-search-action .anticon {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-transfer-list-search-action .anticon:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-transfer-list-header {
        color: rgba(255, 255, 255, 0.85);
        background-image: initial;
        background-color: rgb(24, 26, 27);
        border-bottom-color: rgb(53, 58, 60);
    }
    .ant-transfer-list-content {
        list-style-image: initial;
    }
    .ant-transfer-list-content-item-remove {
        text-decoration-color: initial;
        outline-color: initial;
        color: rgb(223, 223, 223);
    }
    .ant-transfer-list-content-item-remove:focus,
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(84, 183, 255);
    }
    .ant-transfer-list-content-item-remove:active {
        color: rgb(82, 174, 250);
    }
    .ant-transfer-list-content-item-remove:hover {
        color: rgb(84, 183, 255);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-color: rgb(30, 32, 33);
    }
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
        background-color: rgb(0, 49, 73);
    }
    .ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-transfer-list-content-item-checked {
        background-color: rgb(0, 45, 67);
    }
    .ant-transfer-list-content-item-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-transfer-list-pagination {
        border-top-color: rgb(53, 58, 60);
    }
    .ant-transfer-list-body-not-found {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-transfer-list-footer {
        border-top-color: rgb(53, 58, 60);
    }
    .ant-select-tree-checkbox {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
    .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
        border-color: rgb(0, 90, 173);
    }
    .ant-select-tree-checkbox-checked::after {
        border-color: rgb(0, 90, 173);
    }
    .ant-select-tree-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
    }
    .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67) !important;
    }
    .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        border-color: rgb(52, 56, 58);
    }
    .ant-select-tree-checkbox-disabled + span {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-tree-checkbox-wrapper {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-group {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
        background-color: rgb(0, 99, 192);
        border-color: initial;
    }
    .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-select-tree {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-select-tree .ant-select-tree-treenode {
        outline-color: initial;
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
        color: inherit;
    }
    .ant-select-tree-switcher-loading-icon {
        color: rgb(47, 165, 255);
    }
    .ant-select-tree-switcher-leaf-line::before {
        border-left-color: rgb(60, 65, 67);
    }
    .ant-select-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(60, 65, 67);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: rgb(30, 32, 33);
    }
    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
        background-color: rgb(43, 47, 49); 
    }
    .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 99, 192); 
    }
    .ant-select-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 90, 173);
    }
    .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 99, 192) 0px 0px 0px 2px;
    }
    .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
        border-right-color: rgb(60, 65, 67);
    }
    .ant-select-tree-show-line .ant-select-tree-switcher {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
        background-image: initial;
        background-color: rgb(0, 99, 192);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
        color: rgb(255, 255, 255);
    }
    .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
        color: rgb(255, 255, 255);
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree-checkbox {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
    .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
        border-color: rgb(0, 90, 173);
    }
    .ant-tree-checkbox-checked::after {
        border-color: rgb(0, 90, 173);
    }
    .ant-tree-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-tree-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-right-color: rgb(48, 53, 55);
        border-bottom-color: rgb(48, 53, 55);
        border-top-color: initial;
        border-left-color: initial;
    }
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: rgb(0, 99, 192);
        border-color: rgb(0, 90, 173);
    }
    .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
        background-color: rgb(30, 32, 33);
        border-color: rgb(60, 65, 67) !important;
    }
    .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        border-color: rgb(52, 56, 58);
    }
    .ant-tree-checkbox-disabled + span {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-tree-checkbox-wrapper {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-group {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
        background-color: rgb(24, 26, 27);
        border-color: rgb(60, 65, 67);
    }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: rgb(0, 99, 192);
        border-color: initial;
    }
    .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
        background-color: rgba(0, 0, 0, 0.25);
        border-color: rgba(129, 129, 129, 0.25);
    }
    .ant-tree {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
        background-image: initial;
        background-color: rgb(0, 45, 67);
    }
    .ant-tree .ant-tree-treenode {
        outline-color: initial;
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
        background-image: initial;
        background-color: rgb(30, 32, 33);
    }
    .ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
        color: inherit;
    }
    .ant-tree-switcher-loading-icon {
        color: rgb(47, 165, 255);
    }
    .ant-tree-switcher-leaf-line::before {
        border-left-color: rgb(60, 65, 67);
    }
    .ant-tree-switcher-leaf-line::after {
        border-bottom-color: rgb(60, 65, 67);
    }
    .ant-tree .ant-tree-node-content-wrapper {
        color: inherit;
        background-image: initial;
        background-color: transparent;
    }
    .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: rgb(30, 32, 33);
    }
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgb(43, 47, 49); 
    }
    .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
        background-color: transparent; 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator {
        background-color: rgb(0, 99, 192); 
    }
    .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator::after {
        background-color: transparent;
        border-color: rgb(0, 90, 173);
    }
    .ant-tree .ant-tree-treenode.drop-container > [draggable] {
        box-shadow: rgb(0, 99, 192) 0px 0px 0px 2px;
    }
    .ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: rgb(60, 65, 67);
    }
    .ant-tree-show-line .ant-tree-switcher {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
        border-right-color: initial;
        border-left-color: rgb(60, 65, 67);
    }
    .ant-typography {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-typography.ant-typography-secondary {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-typography.ant-typography-success {
        color: rgb(130, 234, 80);
    }
    .ant-typography.ant-typography-warning {
        color: rgb(254, 183, 44);
    }
    .ant-typography.ant-typography-danger {
        color: rgb(255, 96, 98);
    }
    a.ant-typography.ant-typography-danger:active,
    a.ant-typography.ant-typography-danger:focus,
    a.ant-typography.ant-typography-danger:hover {
        color: rgb(255, 135, 132);
    }
    .ant-typography.ant-typography-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    h1.ant-typography,
    .ant-typography h1 {
        color: rgba(255, 255, 255, 0.85);
    }
    h2.ant-typography,
    .ant-typography h2 {
        color: rgba(255, 255, 255, 0.85);
    }
    h3.ant-typography,
    .ant-typography h3 {
        color: rgba(255, 255, 255, 0.85);
    }
    h4.ant-typography,
    .ant-typography h4 {
        color: rgba(255, 255, 255, 0.85);
    }
    h5.ant-typography,
    .ant-typography h5 {
        color: rgba(255, 255, 255, 0.85);
    }
    a.ant-typography,
    .ant-typography a {
        color: rgb(47, 165, 255);
        outline-color: initial;
        text-decoration-color: initial;
    }
    a.ant-typography:focus,
    .ant-typography a:focus,
    a.ant-typography:hover,
    .ant-typography a:hover {
        color: rgb(84, 183, 255);
    }
    a.ant-typography:active,
    .ant-typography a:active {
        color: rgb(82, 174, 250);
    }
    a.ant-typography:active,
    .ant-typography a:active,
    a.ant-typography:hover,
    .ant-typography a:hover {
        text-decoration-color: initial;
    }
    a.ant-typography[disabled],
    .ant-typography a[disabled],
    a.ant-typography.ant-typography-disabled,
    .ant-typography a.ant-typography-disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    a.ant-typography[disabled]:active,
    .ant-typography a[disabled]:active,
    a.ant-typography.ant-typography-disabled:active,
    .ant-typography a.ant-typography-disabled:active,
    a.ant-typography[disabled]:hover,
    .ant-typography a[disabled]:hover,
    a.ant-typography.ant-typography-disabled:hover,
    .ant-typography a.ant-typography-disabled:hover {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-typography code {
        background-image: initial;
        background-color: rgba(84, 91, 95, 0.1);
        border-color: rgba(99, 99, 99, 0.2);
    }
    .ant-typography kbd {
        background-image: initial;
        background-color: rgba(84, 91, 95, 0.06);
        border-color: rgba(99, 99, 99, 0.2);
    }
    .ant-typography mark {
        background-color: rgb(119, 92, 0);
    }
    .ant-typography u,
    .ant-typography ins {
        text-decoration-color: initial;
    }
    .ant-typography s,
    .ant-typography del {
        text-decoration-color: initial;
    }
    .ant-typography-expand,
    .ant-typography-edit,
    .ant-typography-copy {
        color: rgb(47, 165, 255);
        text-decoration-color: initial;
        outline-color: initial;
    }
    .ant-typography-expand:focus,
    .ant-typography-edit:focus,
    .ant-typography-copy:focus,
    .ant-typography-expand:hover,
    .ant-typography-edit:hover,
    .ant-typography-copy:hover {
        color: rgb(84, 183, 255);
    }
    .ant-typography-expand:active,
    .ant-typography-edit:active,
    .ant-typography-copy:active {
        color: rgb(82, 174, 250);
    }
    .ant-typography-copy-success,
    .ant-typography-copy-success:hover,
    .ant-typography-copy-success:focus {
        color: rgb(130, 234, 80);
    }
    .ant-typography-edit-content-confirm {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-typography pre {
        background-image: initial;
        background-color: rgba(84, 91, 95, 0.1);
        border-color: rgba(99, 99, 99, 0.2);
    }
    .ant-typography pre code {
        background-image: initial;
        background-color: transparent;
        border-color: initial;
    }
    .ant-typography blockquote {
        border-left-color: rgba(99, 99, 99, 0.2);
    }
    .ant-upload {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
        outline-color: initial;
    }
    .ant-upload-btn {
        outline-color: initial;
    }
    .ant-upload.ant-upload-select-picture-card {
        background-color: rgb(27, 29, 30);
        border-color: rgb(60, 65, 67);
    }
    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(0, 90, 173);
    }
    .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
        border-color: rgb(60, 65, 67);
    }
    .ant-upload.ant-upload-drag {
        background-image: initial;
        background-color: rgb(27, 29, 30);
        border-color: rgb(60, 65, 67);
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: rgb(7, 91, 182);
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: rgb(0, 89, 161);
    }
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: rgb(84, 183, 255);
    }
    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload.ant-upload-drag .anticon-plus {
        color: rgba(255, 255, 255, 0.25);
    }
    .ant-upload.ant-upload-drag .anticon-plus:hover {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload.ant-upload-drag:hover .anticon-plus {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload-list {
        color: rgba(255, 255, 255, 0.85);
        list-style-image: initial;
    }
    .ant-upload-list-item-card-actions .anticon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload-list-item-info .anticon-loading .anticon,
    .ant-upload-list-item-info .ant-upload-text-icon .anticon {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload-list-item .anticon-close {
        color: rgba(255, 255, 255, 0.45);
    }
    .ant-upload-list-item .anticon-close:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-color: rgb(30, 32, 33);
    }
    .ant-upload-list-item-error,
    .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
    .ant-upload-list-item-error .ant-upload-list-item-name {
        color: rgb(255, 96, 98);
    }
    .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
        color: rgb(255, 96, 98);
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border-color: rgb(60, 65, 67);
    }
    .ant-upload-list-picture .ant-upload-list-item:hover,
    .ant-upload-list-picture-card .ant-upload-list-item:hover {
        background-image: initial;
        background-color: transparent;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-color: rgb(157, 0, 2); 
    }
    .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
        background-image: initial; background-color: transparent; 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
        fill: rgb(255, 245, 244); 
    }
    .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
        fill: rgb(255, 96, 98);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
        color: rgba(255, 255, 255, 0.85);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
        color: rgb(255, 255, 255);
    }
    .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        background-color: rgb(27, 29, 30); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
        border-color: transparent; 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: rgb(72, 78, 81); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-top-color: initial;
        border-bottom-color: rgb(53, 58, 60); 
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: rgb(72, 78, 81); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-bottom-color: initial;
        border-top-color: rgb(48, 53, 55); 
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
        border-top-color: rgb(72, 78, 81);
    }
    .react-datepicker-wrapper {
        border-color: initial;
    }
    .react-datepicker {
        background-color: rgb(24, 26, 27);
        color: rgb(255, 255, 255);
        border-color: rgb(72, 78, 81);
    }
    .react-datepicker--time-only .react-datepicker__time-container {
        border-left-color: initial;
    }
    .react-datepicker__header {
        background-color: rgb(32, 35, 37);
        border-bottom-color: rgb(72, 78, 81);
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: rgb(255, 255, 255);
    }
    .react-datepicker__navigation {
        background-image: none;
        background-color: initial;
        border-color: transparent;
    }
    .react-datepicker__navigation--previous {
        border-right-color: rgb(63, 69, 71);
    }
    .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(70, 76, 79);
    }
    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(56, 61, 63);
    }
    .react-datepicker__navigation--next {
        border-left-color: rgb(63, 69, 71);
    }
    .react-datepicker__navigation--next:hover {
        border-left-color: rgb(70, 76, 79);
    }
    .react-datepicker__navigation--next--disabled,
    .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(56, 61, 63);
    }
    .react-datepicker__navigation--years-previous {
        border-top-color: rgb(63, 69, 71);
    }
    .react-datepicker__navigation--years-previous:hover {
        border-top-color: rgb(70, 76, 79);
    }
    .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(63, 69, 71);
    }
    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: rgb(70, 76, 79);
    }
    .react-datepicker__time-container {
        border-left-color: rgb(72, 78, 81);
    }
    .react-datepicker__time-container--with-today-button {
        border-color: rgb(72, 78, 81);
    }
    .react-datepicker__time-container .react-datepicker__time {
        background-image: initial;
        background-color: rgb(24, 26, 27);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style-image: initial;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        background-color: rgb(32, 35, 37);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: rgb(26, 87, 133);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
        background-color: rgb(26, 87, 133);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        color: rgb(211, 211, 211);
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__week-number {
        color: rgb(211, 211, 211);
    }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
        background-color: rgb(32, 35, 37);
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: rgb(255, 255, 255);
    }
    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
        background-color: rgb(26, 87, 133);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__month--selected:hover,
    .react-datepicker__month--in-selecting-range:hover,
    .react-datepicker__month--in-range:hover,
    .react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter--in-selecting-range:hover,
    .react-datepicker__quarter--in-range:hover {
        background-color: rgb(23, 75, 116);
    }
    .react-datepicker__month--disabled,
    .react-datepicker__quarter--disabled {
        color: rgb(211, 211, 211);
    }
    .react-datepicker__month--disabled:hover,
    .react-datepicker__quarter--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background-color: rgb(32, 35, 37);
    }
    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        background-color: rgb(41, 159, 79);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
        background-color: rgb(40, 154, 51);
    }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
        color: rgb(255, 26, 255);
    }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
        color: rgb(141, 255, 141);
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: rgb(26, 87, 133);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: rgb(23, 75, 116);
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: rgb(34, 109, 168);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: rgb(23, 75, 116);
    }
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        background-color: rgba(26, 87, 133, 0.5);
    }
    .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range,
    .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
        background-color: rgb(32, 35, 37);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        color: rgb(211, 211, 211);
    }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
        background-color: transparent;
    }
    .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month-text.react-datepicker__month--in-range:hover,
    .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
        background-color: rgb(26, 87, 133);
    }
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
        background-color: rgb(32, 35, 37);
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
        border-color: transparent;
    }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
        border-top-color: rgb(70, 76, 79);
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-top-color: rgb(63, 69, 71);
    }
    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: rgb(32, 35, 37);
        border-color: rgb(72, 78, 81);
    }
    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: rgb(54, 58, 60);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
        border-bottom-color: rgb(70, 76, 79);
    }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
        border-top-color: rgb(70, 76, 79);
    }
    .react-datepicker__close-icon {
        background-color: transparent;
        border-color: initial;
        outline-color: initial;
    }
    .react-datepicker__close-icon::after {
        background-color: rgb(26, 87, 133);
        color: rgb(255, 255, 255);
    }
    .react-datepicker__today-button {
        background-image: initial;
        background-color: rgb(32, 35, 37);
        border-top-color: rgb(72, 78, 81);
    }
    .react-datepicker__portal {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .react-datepicker__portal .react-datepicker__navigation {
        border-color: transparent;
    }
    .react-datepicker__portal .react-datepicker__navigation--previous {
        border-right-color: rgb(63, 69, 71);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
        border-right-color: rgb(70, 76, 79);
    }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: rgb(56, 61, 63);
    }
    .react-datepicker__portal .react-datepicker__navigation--next {
        border-left-color: rgb(63, 69, 71);
    }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
        border-left-color: rgb(70, 76, 79);
    }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled,
    .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
        border-left-color: rgb(56, 61, 63);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-track,
    .ReactQueryDevtoolsPanel scrollbar-track {
        background-image: initial;
        background-color: rgb(15, 28, 44);
    }
    .ReactQueryDevtoolsPanel ::-webkit-scrollbar-thumb,
    .ReactQueryDevtoolsPanel scrollbar-thumb {
        background-image: initial;
        background-color: rgb(51, 63, 78);
        border-color: rgb(117, 117, 117);
    }
    
    /* Override Style */
    .vimvixen-hint {
        background-color: #7c5400 !important;
        border-color: #dab213 !important;
        color: #f5eaca !important;
    }
    ::placeholder {
        opacity: 0.5 !important;
    }
    a[href="https://coinmarketcap.com/"] > svg[width="94"][height="16"] > path {
        fill: var(--darkreader-neutral-text) !important;
    }
    #edge-translate-panel-body {
        color: var(--darkreader-neutral-text) !important;
    }
    
}