@import "@style-vars";

.filed-helper {

    &__label-text {
        display: inline-flex;
        margin: 0 10px 0 0;
        align-items: center;
    }

    &__icon {
        display: inline-block;
        margin: 0 0 0 10px;
        font-size: 1em;
        color: $curios-blue !important;
    }
}