@import "@style-vars";

.uif-upload-img {
    width: 100%;
    position: relative;

    &__input {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0.01;
        z-index: -10000;

        &:focus + .uif-upload-img__upload-btn {
            border-color: $curios-blue;
            color: $curios-blue;
        }
    }

    &__body {
        display: inline-flex;
        width: 220px;
        justify-content: center;
        flex-wrap: wrap;

    }

    &__label {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__load-area {
        display: flex;
        width: 100%;
        height: 0px;
        padding: 0px;
        align-items: center;
        justify-content: center;

        border: 1px solid transparent;
        transition: all 0.3s ease;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }

        .ant-image {
            display: flex;
            height: 100%;
            overflow: hidden;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }
    }

    &__upload-btn {
        display: block;
        margin: 0px 0 0 !important;
        padding: 4px 15px 4px 12px;
        border: 1px solid $heather;
        border-radius: 4px;
        cursor: pointer;
        color: #000;
        transition: all 0.3s ease;

        span {
            display: inline-block;
            margin-left: 5px;
        }
    }

    &__error {
        margin: 5px 0 0;
        text-align: center;
        color: $danger;
    }

    &__loader {
        display: block;

        .anticon {
            font-size: 48px;
        }
    }
}

.uif-upload-img.active {
    .uif-upload-img__load-area {
        height: 220px;
        padding: 5px;
        border: 1px solid $space;
    }

    .uif-upload-img__upload-btn {
        margin: 15px 0 0 !important;
    }
}
