@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-Light.eot');
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Light.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-Medium.eot');
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-Bold.eot');
    src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Bold.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-Thin.eot');
    src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Thin.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans.eot');
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
        url('../fonts/IBMPlexSans/IBMPlexSans.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.eot');
    src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
        url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.eot');
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
        url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff') format('woff'),
        url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}