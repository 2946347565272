@import "@style-vars";

.uif-account-page {

    &__info-title {
        margin: 20px 0 20px;
        font-size: 20px;
        font-weight: bold;
    }

    &__table {
        background-color: $heather-lite;
        padding: 25px 20px;
    }

    &__table-item {
        margin: 40px 0 0;

        &:first-child {
            margin: 0;
        }
    }

    &__table-item-title {
        width: 100%;
        margin: 0 0 15px;
        font-weight: bold;
        color: $space;
    }

    &__table-item-row {
        display: flex;
        border-bottom: 1px solid $chateau;
        justify-content: space-between;
    }

    &__table-item-param {
        margin: 10px 15px 10px 0;
        color: $chateau;
    }

    &__table-item-value {
        margin: 10px 0;
        text-align: end;
        overflow-x: auto;

    }
}