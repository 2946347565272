.uif-page-title {
    display: inline-flex;
    max-width: 100%;
    width: 100%;
    margin: 10px 0 10px;
    font-size: 32px;
    font-weight: 400;
    overflow: hidden;
    line-height: 120%;
    align-items: center;

    .filed-helper__icon {
        font-size: 0.7em;
    }
}