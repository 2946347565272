@import "@style-vars";

.uif-header {
    position: fixed;
    display: flex;
    width: 100vw;
    height: $nav-header-height;
    padding: 10px 30px;
    top: 0;
    left: 0;
    align-items: center;
    background-color: $curios-blue;
    font-family: "IBM Plex Sans";
    box-shadow: 0 0 5px 0 $curios-blue;
    z-index: 100;

    &__content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        margin: 0 10px 0 0;
        font-weight: bold;
        font-size: 24px;
        color: $white-ice;
        transition: all 0.3s ease;

        &:hover {
            color: $space;
            cursor: pointer;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        color: #fff !important;

    }

    &__burger-btn {
        display: none;
        margin: 0 0 0 20px;
        background-color: transparent;
        color: $white-ice;
        font-size: 22px;
        transition: all .2s ease;

        &:active {
            color: $heather;
        }
    }

    &__theme-btn {
        margin: 0 0 0 10px;
        padding: 0;
        font-size: 25px;
        background-color: transparent;
    }

    &__control-user {
        font-size: 25px !important;
    }
}

@media all and (max-width: 767.98px) {
    .uif-header {
        &__burger-btn {
            display: block;
        }
    }
}

@media all and (max-width: 576.98px) {
    .uif-header {
        padding: 5px 10px;

        &__logo {
            margin: 0 5px 0 0;
            font-size: 18px;
        }
    }
}