.uif-status-form {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__body {
        max-width: 400px;
    }

    &__icon-wrapper {
        display: flex;
        justify-content: center;
    }

    &__icon {
        font-size: 36px !important;
    }

    &__text {
        margin: 15px 0 0;
        font-size: 16px;
    }
}