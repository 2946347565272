.table-drop-description {
    &__item {
        margin: 0 0 15px;

        &:last-child {
            margin: 0;
        }
    }

    &__item-title {
        margin: 0;
        font-weight: bold;
    }

    &__item-text, &__item-node-text {
        margin: 3px 0 0 10px;
    }
}
