@import "@style-vars";

.field-helper-content {
    max-width: 400px;

    &__title {
        width: 100%;
        margin: 8px 0 16px;
        font-weight: bold;
        word-wrap: break-word;
    }

    &__text {
        width: 100%;
        margin: 8px 0;
        word-wrap: break-word;
    }

    &__img-block {
        display: flex;
        width: 100%;
        margin: 10px 0;
        padding: 10px 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: $gallery;
        border-radius: 12px;
    }

    &__img {
        display: block;
        max-width: 100%;
        max-height: 400px;
    }

    &__img-description {
        display: block;
        width: 100%;
        margin: 10px 0 0;
        padding: 0 10px;
        font-size: 14px;
        text-align: center;
        word-wrap: break-word;
    }
}

@media all and(max-width: 480px) {
    .field-helper-content {
        max-width: 80vw;
    }
}