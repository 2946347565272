@import "@style-vars";

.radio-image {
    position: relative;
    display: flex;
    max-width: 23%;
    width: 100%;
    margin: 1%;
    cursor: pointer;
    align-items: center;

    &__input {
        position: absolute;
        z-index: -1000;
        width: 1px;
        height: 1px;
        opacity: 0.01;

        &:checked + .radio-image__check-bg {
            opacity: 1;
        }
    }

    &__img {
        width: 100%;
        overflow: hidden;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__check-bg {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        justify-content: center;
        align-items: center;
        opacity: 0;
        background-color: $heather-lite-black;
        transition: all .3s ease;

        .anticon {
            font-size: 42px;
        }
    }
}

@media all and (max-width: 575px) {
    .radio-image {
        max-width: 31.3333%;

        &__check-bg {
            .anticon {
                font-size: 28px;
            }
        }
    }
}