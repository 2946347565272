@import "@style-vars";

.uif-table-image {

    &__img {
        display: flex !important;
        width: 64px !important;
        height: 64px !important;
        align-items: center;
        justify-content: center;
        background-color: $alto;
        overflow: hidden;

        .ant-image-mask-info {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            flex-wrap: wrap !important;
        }
    }

    &__text {
        width: 64px;
        margin: 5px 0 0;
        text-align: center;
    }
}