@import "@style-vars";

.uif-object-table {
    width: 100%;
    margin: 60px 0 200px;

    &__body {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $heather;
        background-color: #fff;
        overflow: hidden;
    }

    &__top {
        display: flex;
        margin: 0;
        justify-content: space-between;
        border-bottom: 1px solid $heather;
    }

    &__table {
        width: 100%;
        .ant-pagination {
            padding: 0 15px;
        }
    }

    &__count {
        display: flex;
        margin: 8px 8px 8px 12px;
        align-items: center;
    }

    &__count-name {
        margin: 0 10px 0 0;
        font-size: 18px;
        font-weight: bold;
    }

    &__count-value {
        margin: 0;
        padding: 0 11px;
        border-radius: 15px;
        border: 1px solid $heather;
        font-weight: bold;
        font-size: 18px;
        color: $space;
    }

    &__top-controls {
        display: flex;
        overflow-x: auto;
    }

    &__top-control, &__top-popover {
        display: flex !important;
        width: 45px;
        padding: 0;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $heather !important;

        &.anticon, .anticon {
            font-size: 20px !important;
        }

        &.ant-popover-disabled-compatible-wrapper {
            button {
                border: none;
                background-color: transparent;
            }
        }
    }

    &__top-popover.disabled {
        cursor: not-allowed;
        color: $alto !important;
    }

    &__loader {
        display: flex;
        min-height: 200px;
        width: 100%;

        align-items: center;
        justify-content: center;
    }

    &__loader-icon {
        font-size: 48px !important;
    }

    &__header-loader {
        display: flex;
        padding: 0 10px;
        align-items: center;
    }

    &__header-loader-icon {
        font-size: 20px !important;
    }
}