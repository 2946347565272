@import "@style-vars";

.uif-auth-page {
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(90deg, #6223c5 0%, #3c75f3 100%);

    .ant-form-item-label label {
        font-size: 18px !important;
    }

    &__form-wrapper {
        display: flex;
        min-height: 100vh;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    &__form {
        display: flex;
        max-width: 500px;
        width: 100%;
        flex-direction: column;
        border-radius: 12px;
        background-color: $white-ice;
        overflow: hidden;
    }

    &__form-inputs {
        position: relative;
        padding: 40px 30px 40px;
    }

    &__title {
        width: 100%;
        margin: 0 0 15px;
        text-align: center;
        text-transform: uppercase;

        font-size: 26px;
        letter-spacing: 1px;
        color: #fff;
    }

    &__submit-btn {
        width: 100%;
        margin: auto 0 0;
        text-align: center;
        padding: 7px 7px 10px;
        font-size: 20px;
        background-color: #000;
        color: #fff;

        &:active {
            background-color: rgb(48, 47, 47);
        }
    }

    &__form-error {
        margin: 0;
        font-size: 16px;
        color: $danger;
        opacity: 0;
        transform: scale(0);
        transition: all .3s ease;

        &.show {
            opacity: 1;
            transform: scale(1);
        }
    }

    &__loader {
        position: absolute !important;
        display: block;
        top: 15px;
        right: 15px;
        transform: scale(0);
        transition: all 0.1s ease;

        &.show {
            transform: scale(1);
        }

        .anticon {
            font-size: 25px !important;
        }
    }
}
