@import './fonts.scss';
@import './variables.scss';
@import './reset.scss';
@import './mixins.scss';
@import './buttons.scss';
@import './ant.scss';
@import './animations.scss';
@import './dark.scss';

.uif-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 60px;
}

body {
    font-family: 'IBM Plex Sans', Arial, Helvetica, sans-serif !important;
    font-style: normal;

    &.lock {
        overflow: hidden;
    }
}

.uif-span-devider {
    display: block;
}

.clear-focus {
    &:focus {
        // if need to save box-shadow from default, use wrappers
        outline: none !important;
        box-shadow: none !important;
    }
}

.auth-app-loader {
    display: flex;
    width: 100Vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

@media all and(max-width: 1399.98px) {
    .uif-container {
        padding: 0 40px;
    }
}

@media all and(max-width: 1199.98px) {
    .uif-container {
        padding: 0 40px;
    }
}

@media all and(max-width: 991.98px) {
    .uif-container {
        padding: 0 30px;
    }
}

@media all and(max-width: 767.98px) {
    .uif-container {
        padding: 0 20px;
    }

    body.lock-sm {
        overflow: hidden;
    }
}

@media all and(max-width: 575.98px) {
    .uif-container {
        padding: 0 15px;
    }
}