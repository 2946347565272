.uif-collapse {
    .ant-collapse-header {
        font-size: 16px;
        font-weight: 400;

    }

    &__add-icon, &__edit-icon {
        font-size: 24px;
    }
}